import {
    StyledBonusButton,
    StyledControlButton,
    StyledGamesButton, StyledLeftSidebarActions, StyledModeratorBonusButton,
    StyledStatsButton, StyledTokenButton
} from "./styledLeftSidebar";
import {LeftSidebarMenu, roles} from "../../../utils/consts";
import games from "../../../assets/images/leftSidebar/menu-games-icon.svg";
import cons from "../../../assets/images/leftSidebar/menu-fair-icon.svg";
import stats from "../../../assets/images/leftSidebar/menu-stats-icon.svg";
import bonus from "../../../assets/images/leftSidebar/menu-bonus-icon.svg";
import tokenBonus from "../../../assets/images/leftSidebar/menu-token-icon.svg";
import moderatorBonus from "../../../assets/images/leftSidebar/menu-faucet-icon.svg";
import React, {useCallback, useContext} from "react";
import {useBetween} from "use-between";
import {VisibleLeftSidebarStates} from "./VisibleLeftSidebarStates";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../App";
import BurgerStates from "../mobileNavigation/BurgerStates";
import {closableNotification} from "../notification/ClosableNotification";

const getClassName = (isActive) => {
    return isActive ? {className: "active"} : undefined;
};

function MobileNavigationMenu() {

    const {t} = useTranslation("leftSidebar");

    const { setVisible, menu, setMenu, setTokenBonusVisible, setGameBonusVisible, setModeratorBonusVisible } = useBetween(VisibleLeftSidebarStates);
    const {setIsOpenBurger} = useBetween(BurgerStates);
    const {authenticated, user} = useContext(AppContext);

    const handleChangeTokenBonusVisible = () => {
        if (!authenticated) {
            closableNotification("Full authentication is required to access this resource.", "error");
        } else {
            setTokenBonusVisible(true);
            setIsOpenBurger(false);
        }
    };

    const showMenu = useCallback((type) => {
        setVisible(true);
        setMenu(type);

        setIsOpenBurger(false)

        if (menu === type) {
            setVisible(false);
            setMenu("");
        }
    }, [menu, setMenu, setVisible]);

    return (
        <StyledLeftSidebarActions isNavigation>
            <ul>
                <StyledGamesButton {...getClassName(menu === LeftSidebarMenu.GAMES)}
                                   onClick={() => showMenu(LeftSidebarMenu.GAMES)} isNav>
                    <img src={games} alt="games" width={38}/>
                    <span>{t("games")}</span>
                </StyledGamesButton>
                <StyledControlButton {...getClassName(menu === LeftSidebarMenu.CONTROL)}
                                     onClick={() => showMenu(LeftSidebarMenu.CONTROL)} isNav>
                    <img src={cons} alt="cons" width={30}/>
                    <span>{t("control")}</span>
                </StyledControlButton>
                {authenticated ? <StyledStatsButton {...getClassName(menu === LeftSidebarMenu.STATISTICS)}
                                                    onClick={() => showMenu(LeftSidebarMenu.STATISTICS)} isNav>
                    <img src={stats} alt="stats" width={30}/>
                    <span>{t("stats")}</span>
                </StyledStatsButton> : null}
                {authenticated ? <>
                    <StyledBonusButton{...getClassName(menu === LeftSidebarMenu.BONUS_MODAL)}
                                      onClick={() => {
                                          setGameBonusVisible(true);
                                          setIsOpenBurger(false);
                                      }}
                                      isNav>
                        <img src={bonus} alt="bonus" width={30}/>
                        <span>{t("bonus")}</span>
                    </StyledBonusButton>
                </> : null}
                {authenticated ? <> <StyledTokenButton {...getClassName(menu === LeftSidebarMenu.TOKEN_BONUS)}
                                                       onClick={handleChangeTokenBonusVisible} isNav>
                    <img src={tokenBonus} alt="tokenBonus" width={29}/>
                    <span>{t("tokenBonus")}</span>
                </StyledTokenButton>
                </> : null}
                {
                    authenticated && (user?.roles[0] === roles.MODERATOR || user?.roles[0] === roles.ADMIN) ? <>
                            <StyledModeratorBonusButton
                                {...getClassName(menu === LeftSidebarMenu.MODERATOR_BONUS)}
                                onClick={() => {
                                    setModeratorBonusVisible(true);
                                    setIsOpenBurger(false);
                                }}
                                isNav>
                                <img
                                    src={moderatorBonus}
                                    alt="faucet"
                                    width={29}
                                />
                                <span>{t("faucet")}</span>
                            </StyledModeratorBonusButton>
                        </> : null}
            </ul>
        </StyledLeftSidebarActions>
    )
}

export default MobileNavigationMenu;
