import Dialog from "rc-dialog";
import AlertMessage from "../../alert/Alert";
import React, {useEffect, useRef, useState} from "react";
import ReCaptcha from "../../reCaptcha/ReCaptcha";
import {
    StyledAlertAmountUser,
    StyledHelpElementAlertAmountUser,
    StyledModeratorBonusWrapper,
    StyledTimer,
    StyledDayItem,
    StyledDayItemAmount,
    StyledDayItemHeader,
    StyledDaysBlock
} from "./styledModeratorFaucet";
import axios from "axios";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {responseStatus} from "../../../../utils/consts";
import {closableNotification} from "../../notification/ClosableNotification";
import {StyledModalLoader} from "../../payment/styledPaymentInvoice";
import loadingGif from "../../../../assets/images/loading.gif";
import {StyledSpan} from "../../../styles/styledTypography";
import Big from "big.js";
import {StyledButton} from "../../../styles/styledButton";
import {useTranslation} from "react-i18next";
import {useBetween} from "use-between";
import BalanceStates from "../../../games/BalanceStates";
import LoadButton from "../../spinner/ButtonSpinner";

function ModeratorFaucet({setVisible, visible, t}) {
    const {t: errorsT} = useTranslation("errors");

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const [bonusInfoData, setBonusInfoData] = useState(null);
    const [remainingTime, setRemainingTime] = useState(0);
    const [isBonusAvailable, setIsBonusAvailable] = useState(false);

    const {setBalance, activeCurrency, setPaymentMethods, paymentMethods} = useBetween(BalanceStates);

    const recaptchaRef = useRef(null);

    useEffect(() => {
        if (!visible) return;
        getModeratorBonusInfo();
    }, [visible])

    useEffect(() => {
        if (bonusInfoData && bonusInfoData.last_claimed_at) {
            const FIFTEEN_MINUTES = 15 * 60;
            const interval = setInterval(() => {
                const now = Math.floor(Date.now() / 1000);
                const elapsed = now - bonusInfoData.last_claimed_at;
                const timeLeft = FIFTEEN_MINUTES - elapsed;
                if (timeLeft <= 0) {
                    setRemainingTime(0);
                    clearInterval(interval);
                } else {
                    setRemainingTime(timeLeft);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [bonusInfoData]);

    const formatSatoshi = (amount) => {
        let value = amount;
        if (amount.includes('.')) {
            value = value.replace(/0+$/, '').replace(/\.$/, '');
        }
        return value;
    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs.toString().padStart(2, "0")}`;
    };

    const getModeratorBonus = () => {
        const currentBonus = bonusInfoData?.bonus_by_days?.find((bonusDay) => bonusDay.day === bonusInfoData?.bonus_day);
        const bonusPercentage = new Big(currentBonus.amount).times(new Big(bonusInfoData?.claim_bonus).div(100)).toString();
        return formatSatoshi(bonusPercentage)
    }

    const getModeratorBonusInfo = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_MODERATOR_LINK}/moderator-bonus/get-bonus-info`, userAuthenticationConfig()).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                setBonusInfoData(response.data);

                const FIFTEEN_MINUTES = 15 * 60;
                const currentTime = Math.floor(Date.now() / 1000);
                const lastClaimTime = response?.data ? response?.data?.last_claimed_at : 0;
                const elapsedTime = currentTime - lastClaimTime;

                setIsBonusAvailable(response?.data ? (elapsedTime >= FIFTEEN_MINUTES) : true)
                setIsLoading(false);
            }
        }).catch(error => {
            closableNotification(error.response.data.message, "error");
            setIsLoading(false);
            setVisible(false);
        });
    };

    const claimModeratorBonus = () => {
        if (!recaptchaRef.current) return;
        if (!recaptchaRef.current.getValue()) return closableNotification(errorsT("Failed authentication, suspicious activity"), "error");
        setIsLoadingBtn(true);
        recaptchaRef.current.reset();
        axios.post(`${process.env.REACT_APP_MODERATOR_LINK}/moderator-bonus/claim-bonus`, {}, userAuthenticationConfig()).then(response => {
            console.log(response)
            if (activeCurrency === bonusInfoData?.bonus_currency?.asset) setBalance(prevState => {
                return (new Big(prevState ?? 0).plus(new Big(response?.data?.claimed_amount))).toFixed(8);
            })
            setPaymentMethods(prevState =>
                prevState?.map(paymentMethod =>
                    paymentMethod?.currency?.asset === bonusInfoData?.bonus_currency?.asset
                        ? {
                            ...paymentMethod,
                            balances: {
                                ...paymentMethod.balances,
                                amount: (new Big(paymentMethod.balances.amount ?? 0).plus(new Big(response?.data?.claimed_amount))).toFixed(8)
                            }
                        }
                        : paymentMethod)
            );
            closableNotification(t("tokenReceived"), "success");
            setIsLoadingBtn(false);
            setVisible(false);
        }).catch(error => {
            closableNotification(error?.response?.data?.message, "error");
            recaptchaRef.current.reset();
            setIsLoadingBtn(false);
        });
    };

    return (
        <Dialog
            visible={visible}
            wrapClassName="default-modal-wrapper"
            onClose={() => setVisible(false)}
            animation="zoom"
            maskAnimation="fade"
            title={t("moderatorBonusModalTitle", {currency: bonusInfoData?.bonus_currency?.name})}
            forceRender={false}
            destroyOnClose={true}
            className="default-modal"
        >
            {isLoading ? <StyledModalLoader>
                    <img src={loadingGif} alt={"loading..."}/>
                </StyledModalLoader> :
                <StyledModeratorBonusWrapper>
                    <StyledDaysBlock>
                        {bonusInfoData?.bonus_by_days?.map((dayData) => {
                            const isDayPassed = dayData.day <= bonusInfoData?.bonus_day;
                            const currentDayStyle = dayData.day === bonusInfoData?.bonus_day;
                            const prevDayStyle = dayData.day < bonusInfoData?.bonus_day;

                            const examinationProgressPercentage = dayData.day === bonusInfoData?.bonus_day ? bonusInfoData?.bonus_progress : 100;

                            return (
                                <StyledDayItem key={dayData.day} hasDay={isDayPassed}>
                                    <StyledDayItemHeader fillPercentage={examinationProgressPercentage}
                                                         prevDayStyle={prevDayStyle} currentDayStyle={currentDayStyle}>
                                        <StyledSpan>
                                            {t("day", {dayNumber: dayData?.day})}
                                        </StyledSpan>
                                    </StyledDayItemHeader>
                                    <StyledDayItemAmount hasDay={isDayPassed}>
                                        <b>
                                            {formatSatoshi(dayData?.amount)}
                                        </b>
                                        {bonusInfoData.bonus_currency.asset}
                                    </StyledDayItemAmount>
                                </StyledDayItem>
                            );
                        })}
                    </StyledDaysBlock>
                    <StyledAlertAmountUser>
                        {t("yourBonus")}: {getModeratorBonus() + " " + bonusInfoData.bonus_currency.asset}
                        <StyledHelpElementAlertAmountUser>
                            ?
                            <div className="help-el__text">
                                {t("bonusLevelInfo")}
                            </div>
                        </StyledHelpElementAlertAmountUser>
                    </StyledAlertAmountUser>
                    <ReCaptcha recaptchaRef={recaptchaRef}/>
                    <AlertMessage mb={15}>
                        {t("moderatorBonusAlertInfo", {
                            currency: bonusInfoData?.bonus_currency?.name,
                            min_amount: formatSatoshi(bonusInfoData?.bonus_by_days?.[0].amount)
                        })}
                    </AlertMessage>
                    {!isBonusAvailable ? (
                        <StyledTimer>
                            {formatTime(remainingTime)}
                        </StyledTimer>
                    ) : (isLoadingBtn ? <LoadButton
                            text={t("getToken")}
                            color="neutral"
                            type="submit"
                            style={{width: '100%'}}
                        /> : <StyledButton
                            onClick={claimModeratorBonus}
                            color="neutral"
                            type="submit"
                            width="100"
                        >
                            {t("getToken")}
                        </StyledButton>
                    )}
                </StyledModeratorBonusWrapper>
            }
        </Dialog>
    );
}

export default ModeratorFaucet;