import InputGroup from "../elements/inputGroup/InputGroup";
import { StyledCheckboxLabel, StyledCheckboxWrapper } from "../styles/styledCheckbox";
import Checkbox from "rc-checkbox";
import AlertTriangle from "../../assets/images/alert-triangle.svg";
import React, { useState } from "react";
import { StyledMemoExceptionBlock } from "./styledPayoutInvoice";
import { StyledSpan } from "../styles/styledTypography";
import { attributeNames, regex } from "../../utils/consts";

const MAX_LENGTH_MEMO = 32;

const TransactionAttributesTagBlock = ({
  updateAttribute,
  transactionAttributes,
  setIsMemo,
  isMemo,
  t,
  attributes
}) => {

  const memoValue = attributes.find(item => item.name === attributeNames.TAG)?.value || "";

  const [error, setError] = useState(null);

  const handleChangeIsMemo = () => {
    setIsMemo(prev => !prev);
    setError(null);
  };

  const handleChangeMemoValue = (event) => {
    const { value } = event.target;

    let newValue = value;

    if (newValue?.length >= MAX_LENGTH_MEMO) {
      newValue = newValue.slice(0, MAX_LENGTH_MEMO);
    }

    if (newValue && !newValue.match(regex.MEMO_TAG)) {
      setError(t("invalidTag"));
      return;
    }

    setError(null);

    updateAttribute(
      transactionAttributes.tag,
      newValue
    );
  };

  return (
    <>
      <StyledMemoExceptionBlock>
        <img src={AlertTriangle} alt="memo" />
        <StyledSpan>
          {t("memoNecessaryTag")}
        </StyledSpan>
      </StyledMemoExceptionBlock>
      <StyledCheckboxWrapper margin="0 0 10px">
        <Checkbox
          id="defaultCheckbox"
          className="default-checkbox"
          name="publish"
          checked={isMemo}
          onChange={handleChangeIsMemo}
        />
        <StyledCheckboxLabel
          position="right"
          htmlFor="defaultCheckbox"
        >
          {t("dontHaveMemoTag")}
        </StyledCheckboxLabel>
      </StyledCheckboxWrapper>
      <InputGroup
        autocomplete="off"
        id="memo"
        type="text"
        value={memoValue}
        label={t("memo")}
        error={error}
        placeholder={t("memo")}
        name="memo"
        disabled={isMemo}
        onChange={handleChangeMemoValue}
      />
    </>
  );
};

export default TransactionAttributesTagBlock;