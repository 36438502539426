import { StyledMenuHeader, StyledMenuHeaderItem } from "../../games/styledPaymentMethod";
import { StyledMobileNav } from "../header/styledHeader";
import React, {useEffect} from "react";
import { useBetween } from "use-between";
import BurgerStates from "./BurgerStates";
import BalanceStates from "../../games/BalanceStates";
import { useTranslation } from "react-i18next";
import { StyledBalanceNavContent } from "./styledMobileNavigation";

export const BalanceNav = () => {
  const {
    isBalanceOpen,
    setIsOpenBalance
  } = useBetween(BurgerStates);

  const {
    selectedPaymentMethod,
    setPaymentDialogVisible,
    setPayoutDialogVisible
  } = useBetween(BalanceStates);

  const { t } = useTranslation('games');

  useEffect(() => {
    if (isBalanceOpen) {
      window.history.pushState({modal: true}, '');

      const handlePopState = () => {
        setIsOpenBalance(false);
      };

      window.addEventListener('popstate', handlePopState);
      return () => window.removeEventListener('popstate', handlePopState);
    }
  }, [isBalanceOpen, setIsOpenBalance]);

  return <StyledMobileNav isOpen={isBalanceOpen} fullscreen={true}>
    <StyledMenuHeader>
      <StyledMenuHeaderItem
        className={'nav-title'}>{selectedPaymentMethod?.currency.name} {t('balance').toLowerCase()}</StyledMenuHeaderItem>
      <StyledMenuHeaderItem>
        <section
          className="close"
          onClick={() => setIsOpenBalance(false)}
        ></section>
      </StyledMenuHeaderItem>
    </StyledMenuHeader>
    <StyledBalanceNavContent>
      <button onClick={() => {
        setPaymentDialogVisible(true);
        setIsOpenBalance(false);
      }}>{t('fillIn')}</button>
      <button onClick={() => {
        setPayoutDialogVisible(true);
        setIsOpenBalance(false);
      }}>{t('bringOut')}</button>
    </StyledBalanceNavContent>
  </StyledMobileNav>
}