import styled from "styled-components";

export const StyledWrapper = styled.div`
    #select-ban-time {
        margin-bottom: 15px;
    }

    & label, .recaptcha-wrapper__title {
        font-size: 16px !important;
    }

    @media screen and (max-width: 480px) {
        & label, .recaptcha-wrapper__title {
            font-size: 14px !important;
        }
    }

    @media screen and (max-width: 480px) {
        & .rc-select-selection-item, .input-ban-cause input {
            font-size: 16px !important;
        }

        & .input-ban-cause, .recaptcha-wrapper__content {
            margin-bottom: 15px !important;
        }

        & .rc-select-selector {
            min-height: 32.5px;
        }
    }
`