import axios from "axios";

import {useBetween} from "use-between";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {generatePath} from "../../../../utils/getLanguage";

import Dialog from "rc-dialog";
import ProfileActionsStates from "../../ProfileActionsStates";
import {closableNotification} from "../../../elements/notification/ClosableNotification";

import {
    StyledModeratorsOnlineText,
    StyledModeratorsTable,
    StyledModeratorsTableBody,
    StyledModeratorsTableHead,
    StyledModeratorsTableItem,
    StyledModeratorsTableRow,
    StyledWrapperTable
} from "./styledModeratorsListModal";
import {StyledModalLoader} from "../../../elements/payment/styledPaymentInvoice";

import {responseStatus} from "../../../../utils/consts";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";

import {AppContext} from "../../../../App";

import loadingGif from "../../../../assets/images/loading.gif";
import userIcon from "../../../../assets/images/user_icon_dark.svg";

function ModeratorsListModal() {
    const {t} = useTranslation("siteOptions");

    const [moderatorsInfo, setModeratorsInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const {
        visibleModeratorsInfoModal,
        setVisibleModeratorsInfoModal,
    } = useBetween(ProfileActionsStates);
    const {authenticated, user} = useContext(AppContext);

    const tableContainerRef = useRef();

    useEffect(() => {
        if (!visibleModeratorsInfoModal) return;
        getModeratorsInfo();
    }, [visibleModeratorsInfoModal]);

    const getModeratorsInfo = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_MODERATOR_LINK}/users/get-moderators-list`, userAuthenticationConfig()).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                setModeratorsInfo(response.data);
                setIsLoading(false);
            }
        }).catch(error => {
            closableNotification(error.response.data.message, "error");
            setVisibleModeratorsInfoModal(false);
        });
    };

    const formatDate = (timestamp) => {
        if (!timestamp) return "";
        const date = new Date(timestamp * 1000);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return (
        <Dialog
            visible={authenticated && visibleModeratorsInfoModal}
            wrapClassName="default-modal-wrapper"
            onClose={() => setVisibleModeratorsInfoModal(false)}
            animation="zoom"
            maskAnimation="fade"
            title={t("titleFormModeratorList")}
            forceRender={false}
            className="default-modal moderators-list-modal"
            style={{margin: "auto"}}
        >
            <StyledWrapperTable>
                {isLoading || !moderatorsInfo ?
                    <StyledModalLoader style={{height: "300px", minHeight: "auto"}}><img src={loadingGif}
                                                                                         alt={"loading..."}/></StyledModalLoader> :
                    <StyledModeratorsTable>
                        <StyledModeratorsTableHead>
                            <StyledModeratorsTableRow>
                                <StyledModeratorsTableItem>{t("moderatorTittleColumnTable")}</StyledModeratorsTableItem>
                                <StyledModeratorsTableItem>{t("roomTittleColumnTable")}</StyledModeratorsTableItem>
                                <StyledModeratorsTableItem>{t("dateOnlineTittleColumnTable")}</StyledModeratorsTableItem>
                                <StyledModeratorsTableItem>{t("isOnlineTittleColumnTable")}</StyledModeratorsTableItem>
                            </StyledModeratorsTableRow>
                        </StyledModeratorsTableHead>
                        <StyledModeratorsTableBody ref={tableContainerRef}>
                            {moderatorsInfo?.map((moderator, key) => (
                                <StyledModeratorsTableRow key={key}>
                                    <StyledModeratorsTableItem>
                                        <NavLink
                                            to={generatePath(`/account/${moderator?.nickname}`)}
                                            className={`wrapper-link ${user.nickname !== moderator?.nickname && "user-tag"}`}
                                            onClick={() => setVisibleModeratorsInfoModal(false)}
                                        >
                                            <img
                                                src={userIcon}
                                                alt="user"
                                            />
                                            {moderator?.nickname}
                                        </NavLink>
                                    </StyledModeratorsTableItem>
                                    <StyledModeratorsTableItem>{moderator?.room.toUpperCase()}</StyledModeratorsTableItem>
                                    <StyledModeratorsTableItem>
                                        <span>
                                            {formatDate(moderator?.lastOnlineTime)}
                                        </span>
                                    </StyledModeratorsTableItem>
                                    <StyledModeratorsTableItem>
                                        <StyledModeratorsOnlineText isOnline={moderator?.isOnline}>
                                            {moderator?.isOnline ? t("onlineValueColumnTable") : t("offlineValueColumnTable")}
                                        </StyledModeratorsOnlineText>
                                    </StyledModeratorsTableItem>
                                </StyledModeratorsTableRow>
                            ))}
                        </StyledModeratorsTableBody>
                    </StyledModeratorsTable>
                }
            </StyledWrapperTable>
        </Dialog>
    )
}

export default ModeratorsListModal;