import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { AppContext } from "../../../App";
import Dialog from "rc-dialog";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import {canceledStatus, responseStatus, roles} from "../../../utils/consts";
import { closableNotification } from "../../elements/notification/ClosableNotification";
import http from "../../../http";

import defaultImg from "../../../assets/images/no-avatar.svg";

import { StyledButton } from "../../styles/styledButton";
import { StyledLabel, StyledWrapperAvatarLoad } from "./styledAccountImage";
import LoadButton from "../../elements/spinner/ButtonSpinner";
import {useBetween} from "use-between";
import {VisibleLeftSidebarStates} from "../../elements/leftSidebar/VisibleLeftSidebarStates";

const MAX_INITIAL_SIZE = 3 * 1024 * 1024;
const MAX_FILE_SIZE = 300 * 1024;

const AccountImageContainer = ({ nickname = null, mediaObject, level, role }) => {
    const { user } = useContext(AppContext);
    const { t } = useTranslation("siteOptions");

    const [changeImg, setChangeImg] = useState(false);
    const [loading, setLoading] = useState(false);
    const [displayedImage, setDisplayedImage] = useState(mediaObject ? mediaObject.base64 : defaultImg);
    const [tempCroppedImage, setTempCroppedImage] = useState(null);
    const [isFileChoose, setIsFileChoose] = useState(false);
    const [imageToCrop, setImageToCrop] = useState(null);
    const [isCropping, setIsCropping] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);

    const {
        setMessages,
        messages,
    } = useBetween(VisibleLeftSidebarStates);

    const [crop, setCrop] = useState({
        unit: '%',
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        aspect: 1
    });

    const imgRef = useRef(null);
    const completedCrop = useRef(null);

    useEffect(() => {
        if (changeImg) {
            window.history.pushState({ modal: true }, '');
            const handlePopState = () => setChangeImg(false);
            window.addEventListener('popstate', handlePopState);
            return () => window.removeEventListener('popstate', handlePopState);
        }
    }, [changeImg]);

    useEffect(() => {
        if (mediaObject || mediaObject === null) return;

        const abortController = new AbortController();
        http.get("/api/media-objects", { ...userAuthenticationConfig(), signal: abortController.signal })
            .then(response => {
                if (response.status === responseStatus.HTTP_OK && response.data.base64 !== "") {
                    setDisplayedImage(response.data.base64 ?? defaultImg);
                } else {
                    setDisplayedImage(defaultImg);
                }
            })
            .catch(error => {
                setDisplayedImage(defaultImg);
                if (error.message === canceledStatus) return;
                if (error.response?.status === responseStatus.HTTP_BAD_REQUEST) {
                    closableNotification(error.response.data.error, "error");
                }
            });

        return () => abortController.abort();
    }, []);

    const compressImageBeforeCrop = async (file) => {
        return new Promise((resolve) => {
            const img = new Image();
            const objectUrl = URL.createObjectURL(file);

            img.onload = () => {
                URL.revokeObjectURL(objectUrl);

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                let { width, height } = img;
                let quality = 1;

                if (file.size > MAX_INITIAL_SIZE) {
                    const scale = Math.min(1, Math.sqrt(MAX_INITIAL_SIZE / file.size));
                    width *= scale;
                    height *= scale;
                    quality = 0.8;
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(
                    (blob) => {
                        resolve(URL.createObjectURL(blob));
                    },
                    'image/jpeg',
                    quality
                );
            };

            img.src = objectUrl;
        });
    };

    const uploadImage = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            setIsFileChoose(true);
            const compressedImageUrl = await compressImageBeforeCrop(file);
            setImageToCrop(compressedImageUrl);
            setIsCropping(true);
            setPreviewImage(null);
            setTempCroppedImage(null);
        } catch (error) {
            console.error('Error processing image:', error);
            closableNotification(t('errorProcessingImage'), "error");
            setIsFileChoose(false);
        }
    };

    const onLoad = (img) => {
        imgRef.current = img;
        const width = img.width;
        const height = img.height;
        const size = Math.min(width, height);
        const x = (width - size) / 2;
        const y = (height - size) / 2;

        const newCrop = {
            unit: 'px',
            width: width > height ? height : width,
            height: width > height ? height : width,
            x: (x / width) * 100,
            y: (y / height) * 100,
            aspect: 1
        };

        setCrop(newCrop);
        completedCrop.current = newCrop;
    };

    const generateCrop = async (crop) => {
        if (!crop || !imgRef.current) return null;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const image = imgRef.current;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const pixelCrop = {
            x: Math.round(crop.x * scaleX),
            y: Math.round(crop.y * scaleY),
            width: Math.round(crop.width * scaleX),
            height: Math.round(crop.height * scaleY)
        };

        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );

        return canvas.toDataURL('image/jpeg', 1.0);
    };

    const compressForUpload = async (base64String) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                let width = img.width;
                let height = img.height;
                let quality = 0.9;
                const minQuality = 0.1;
                const step = 0.05;
                const scaleFactor = 0.9;
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const compress = () => {
                    return new Promise((resolveCompress) => {
                        canvas.width = width;
                        canvas.height = height;
                        ctx.drawImage(img, 0, 0, width, height);

                        canvas.toBlob(
                            (blob) => {
                                if (blob.size <= MAX_FILE_SIZE || quality <= minQuality) {
                                    resolveCompress(blob);
                                } else {
                                    quality -= step;
                                    if (quality <= minQuality) {
                                        width = Math.floor(width * scaleFactor);
                                        height = Math.floor(height * scaleFactor);
                                        quality = 0.9;
                                    }
                                    compress().then(resolveCompress);
                                }
                            },
                            'image/jpeg',
                            quality
                        );
                    });
                };

                compress().then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(blob);
                }).catch(reject);
            };

            img.onerror = reject;
            img.src = base64String;
        });
    };

    const handleCropSave = async () => {
        try {
            if (!completedCrop.current || !imgRef.current) return;

            const croppedDataUrl = await generateCrop(completedCrop.current);
            if (!croppedDataUrl) throw new Error('Failed to generate crop');

            setTempCroppedImage(croppedDataUrl);
            setPreviewImage(croppedDataUrl);
            setIsCropping(false);
            setImageToCrop(null);

        } catch (error) {
            console.error('Cropping error:', error);
            closableNotification(t('errorProcessingImage'), "error");
        }
    };

    const createMediaObject = async () => {
        try {
            setLoading(true);

            if (!tempCroppedImage) {
                throw new Error('No cropped image available');
            }

            const finalImage = await compressForUpload(tempCroppedImage);

           const response = await http.post(
                "/api/media-objects",
                { base64Image: finalImage },
                userAuthenticationConfig(false)
            );

            setDisplayedImage(response.data.base64);
            const regex = /images\/([a-f0-9\-]+)\/([a-zA-Z0-9_-]+)_full\.webp/;

            const match = response.data.base64.match(regex);
            const referenceId = match[1];
            const name = match[2];

            if (messages && messages?.length > 0) {
                const updatedMessages = messages?.map(message => {
                    if (message.type === "user" &&
                        message.user?.nickname === user?.nickname) {
                        return {
                            ...message,
                            user: {
                                ...message.user,
                                mediaObject: {
                                    ...message.user.mediaObject,
                                    data: {
                                        base64: response.data.chatBase64,
                                        reference_id: referenceId,
                                        name: name
                                    },
                                    createdAt: Math.floor(Date.now() / 1000)
                                }
                            }
                        };
                    }
                    return message;
                });

                setMessages(updatedMessages);
            }

            setChangeImg(false);
            setIsCropping(false);
            setImageToCrop(null);
            setTempCroppedImage(null);
            setPreviewImage(null);
            setIsFileChoose(false);
            closableNotification(t('imageChanged'), "success");
        } catch (error) {
            console.error('Upload error:', error);
            if (error.response?.status === responseStatus.HTTP_BAD_REQUEST) {
                closableNotification(error.response.data.error, "error");
            } else {
                closableNotification(t('errorProcessingImage'), "error");
            }
        } finally {
            setLoading(false);
        }
    };

    const resetState = () => {
        if (isDragging) return;
        setChangeImg(false);
        setIsCropping(false);
        setImageToCrop(null);
        setTempCroppedImage(null);
        setPreviewImage(null);
        setIsFileChoose(false);
    };

    if (!user || (nickname && user?.nickname !== nickname)) {
        return (
            <>
                <div className={`user-info__level ${level && level > 9 ? "level" + level : ""}`} title={t('accountRating')}>{level ?? 0}</div>
                <div className="user-info__image">
                    <img src={displayedImage} alt="user-img" />
                </div>
                {(role === roles.MODERATOR || role === roles.ADMIN) && <div
                    className={`user-group ${role === roles.MODERATOR ? "mod" : role === roles.ADMIN ? "admin" : ""}`}>
                    {role === roles.MODERATOR ? "MOD" : role === roles.ADMIN ? "ADMIN" : ""}
                </div>}
            </>
        );
    }

    return (
        <>
            <Dialog
                visible={changeImg}
                wrapClassName="default-modal-wrapper"
                onClose={resetState}
                animation="zoom"
                maskAnimation="fade"
                title={t('changeAvatar')}
                forceRender={false}
                className="default-modal"
            >
                <StyledWrapperAvatarLoad>
                    {!isCropping ? (
                        <>
                            <StyledLabel htmlFor="userPhoto" className={isFileChoose && 'active'} style={{ cursor: isFileChoose ? "default" : "pointer" }}>
                                <input
                                    type="file"
                                    id="userPhoto"
                                    accept="image/png, image/gif, image/jpeg, image/jpg"
                                    onChange={uploadImage}
                                    disabled={isFileChoose}
                                    style={{ cursor: isFileChoose ? "default" : "pointer" }}
                                />
                                <span className="file-photo">
                                    <img src={previewImage} alt="" />
                                </span>
                                <span className="label-file__text">{t('uploadAvatar')}</span>
                            </StyledLabel>
                            <div className="clean-input" onClick={() => {
                                setIsFileChoose(false);
                                setPreviewImage(null);
                                setTempCroppedImage(null);
                            }}></div>
                            {!loading ? (
                                <StyledButton
                                    color="neutral"
                                    width="100"
                                    onClick={createMediaObject}
                                    disabled={!tempCroppedImage}
                                >
                                    {t('save')}
                                </StyledButton>
                            ) : (
                                <LoadButton
                                    text={t('save')}
                                    color="neutral"
                                    style={{ width: "100%" }}
                                />
                            )}
                        </>
                    ) : (
                        <div className="crop-container">
                            <ReactCrop
                                crop={crop}
                                onChange={(c) => setCrop(c)}
                                onComplete={(c) => {
                                    completedCrop.current = c;
                                }}
                                minHeight={20}
                                minWidth={20}
                                style={{marginBottom: "25px"}}
                                aspect={1}
                                circularCrop
                                keepSelection
                                onDragEnd={() => setTimeout(() => setIsDragging(false), 300)}
                                onDragStart={() => setIsDragging(true)}
                            >
                                <img
                                    src={imageToCrop}
                                    alt="Crop"
                                    onLoad={(e) => onLoad(e.currentTarget)}
                                />
                            </ReactCrop>
                            <div className="crop-buttons">
                                <StyledButton
                                    color="neutral"
                                    width="100"
                                    onClick={() => {
                                        if (isDragging) return;
                                        setIsCropping(false);
                                        setImageToCrop(null);
                                        setTempCroppedImage(null);
                                        setPreviewImage(null);
                                        setIsFileChoose(false);
                                    }}
                                >
                                    {t('cancel')}
                                </StyledButton>
                                <StyledButton
                                    color="neutral"
                                    width="100"
                                    onClick={handleCropSave}
                                    disabled={crop?.height <= 0 || crop?.width <= 0}
                                >
                                    {t('applyCrop')}
                                </StyledButton>
                            </div>
                        </div>
                    )}
                </StyledWrapperAvatarLoad>
            </Dialog>
            <button
                className="user-info__image user-info_edit"
                onClick={() => setChangeImg(true)}
                title={t('changeAvatar')}
            >
                {displayedImage && <img src={displayedImage} alt="user-image" />}
                <div className={`user-info__level ${level && level > 9 ? "level" + level : ""}`} title={t('accountRating')}>
                    {level ?? 0}
                </div>
                {!window.location.pathname.includes('/account') && (user.roles[0] === roles.MODERATOR || user.roles[0] === roles.ADMIN) && <div
                    className={`user-group ${user.roles[0] === roles.MODERATOR ? "mod" : user.roles[0] === roles.ADMIN ? "admin" : ""}`}>
                    {user.roles[0] === roles.MODERATOR ? "MOD" : user.roles[0] === roles.ADMIN ? "ADMIN" : ""}
                </div>}
            </button>
        </>
    );
};

export default AccountImageContainer;