import styled, { css } from "styled-components";

export const StyledWrapperTable = styled.div`
    min-width: 610px;
    overflow-x: auto;
`

export const StyledBanListTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`

export const StyledBanListTableHead = styled.thead`
    font-size: 14px;
    color: ${({theme}) => theme.moderatorsListModal.headTextColor};
    text-transform: uppercase;

    tr:last-child {
        padding-right: 14px;
        text-align: right;
    }
`

export const StyledBanListTableBody = styled.tbody`
    & tr:nth-child(2n) {
        background-color: ${({theme}) => theme.moderatorsListModal.backgroundItemTableColor};
    }

    tr:last-child {
        padding-right: 14px;
    }
`

export const StyledBanListTableRow = styled.tr`
    height: 40px;
`

export const StyledBanListTableItem = styled.td`
    text-align: center;
    border-spacing: 0 !important;
    padding: 0;
    font-size: 14px;
    font-weight: 400;

    &:first-child {
        padding-left: 14px;
        text-align: left;
    }

    .wrapper-link {
        cursor: pointer;
        border-bottom: 1px ${({theme}) => theme.mainTextColor} dashed;
        transition: all 0.3s ease-out;
        display: inline;

        &.user-tag {
            color: ${({theme}) => theme.secondTextColor};
        }

        &:hover, &.user-tag:hover {
            color: #ffffff;
            border-bottom: 1px #ffffff dashed;
        }

        img {
            height: 11px;
            margin-right: 5px;
        }
    }

    button {
        margin: 0;
        text-align: center;
        background: none;
        border: none;
        font-size: 20px;
        color: ${({theme}) => theme.notification.iconColor.error};
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    button:hover, .button:focus {
        border: none;
        background: none;
    }
`