import {useTranslation} from "react-i18next";
import {chatRooms} from "../../../../../utils/consts";
import {
    StyledCharRoomsUserInfo,
    StyledChatRooms,
    StyledChatRoomsBody,
    StyledChatRoomsBodyTitle,
    StyledChatRoomsCloseIcon,
    StyledChatRoomsLang,
    StyledChatRoomsList,
    StyledChatRoomsListItem
} from "./styledChatRooms";
import {StyledChatHeader, StyledChatTitle} from "../styledSidebarChat";

export default function ChatRooms({active, closeRooms, setLangIndex, rooms, joinRoom, setRoom}) {
    const {t} = useTranslation("leftSidebar");

    const handleClickRoom = (index) => () => {
        localStorage.setItem("langIndexForChatRoom", index);

        setLangIndex(index);
        setRoom(rooms[index]);
        joinRoom(rooms[index]?.id);

        closeRooms();
    };

    if (!rooms || rooms?.length === 0) {
        return <div/>
    }

    return (
        <StyledChatRooms active={active} className="chat-rooms">
            <StyledChatHeader className="chat-header">
                <StyledChatTitle>{t("rooms")}</StyledChatTitle>
                <StyledChatRoomsCloseIcon>
                    <button onClick={() => closeRooms()}>✕</button>
                </StyledChatRoomsCloseIcon>
            </StyledChatHeader>
            <StyledChatRoomsBody>
                <StyledChatRoomsBodyTitle>{t("publicRooms")}</StyledChatRoomsBodyTitle>
                <StyledChatRoomsList>
                    {rooms && rooms?.map((room, index) => (
                        <StyledChatRoomsListItem key={index} onClick={handleClickRoom(index)}>
                            <StyledChatRoomsLang>
                                <img
                                    src={require(`../../../../../assets/images/flags/${room?.lang}.png`)}
                                    alt={room?.lang}
                                />
                                <span>{chatRooms[room?.lang].title}</span>
                            </StyledChatRoomsLang>
                            <StyledCharRoomsUserInfo>{room?.online ?? 0} {t("users")}</StyledCharRoomsUserInfo>
                        </StyledChatRoomsListItem>
                    ))}
                </StyledChatRoomsList>
            </StyledChatRoomsBody>
        </StyledChatRooms>
    );
}
