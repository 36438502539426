import styled from "styled-components";

export const StyledWrapper = styled.div`
    
`

export const StyledText = styled.p`
    margin-bottom: 20px;
    
    .wrapper-link {
        cursor: pointer;
        border-bottom: 1px ${({ theme }) => theme.mainTextColor} dashed;
        transition: all 0.3s ease-out;
        display: inline;

        &.user-tag {
            color: ${({ theme }) => theme.secondTextColor};
        }

        &:hover,  &.user-tag:hover{
            color: #ffffff;
            border-bottom: 1px #ffffff dashed;
        }

        img {
            height: 11px;
            margin-right: 5px;
        }
    }
`

export const StyledWrapperTable = styled.div`
    margin-bottom: 15px;
`

export const StyledItemTable = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 25px;
    margin: 5px 0;

    &:nth-child(2n-1) {
        background-color: ${({theme}) => theme.userInfoModal.backgroundItemTableColorFirst};
    }

    &:nth-child(2n) {
        background-color: ${({theme}) => theme.userInfoModal.backgroundItemTableColorSecond};
    }

    @media screen and (max-width: 490px) {
        padding: 13.6px 20px;
    }

    @media screen and (max-width: 330px) {
        padding: 12px 16px;
    }
`

export const StyledItemTitle = styled.span`
    font-size: 16px;
    color: ${({ theme }) => theme.userInfoModal.titleTextColor};
    flex-basis: 30%;
    display: flex;
    justify-content: left;
    align-items: center;
    
    @media screen and (max-width: 440px) {
        font-size: 14px;
    }

    @media screen and (max-width: 380px) {
        font-size: 12px;
    }
`

export const StyledItemValue = styled.span`
    font-size: 18px;
    color: ${({ theme }) => theme.userInfoModal.valueTextColor};
    flex-basis: 65%;
    display: flex;
    justify-content: right;
    align-items: center;
    
    @media screen and (max-width: 440px) {
        font-size: 16px;
    }

    @media screen and (max-width: 380px) {
        font-size: 14px;
    }

    @media screen and (max-width: 330px) {
        font-size: 12px;
    }
`