import axios from "axios";

import {useBetween} from "use-between";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {generatePath} from "../../../../utils/getLanguage";

import {
    StyledBanListTable, StyledBanListTableBody,
    StyledBanListTableHead,
    StyledBanListTableItem,
    StyledBanListTableRow, StyledWrapperTable
} from "./styledBanListModal";
import {StyledModalLoader} from "../../../elements/payment/styledPaymentInvoice";
import {StyledButton} from "../../../styles/styledButton";
import {closableNotification} from "../../../elements/notification/ClosableNotification";

import UnbanUserModal from "../unbanUserModal/UnbanUserModal";
import Dialog from "rc-dialog";
import {NavLink} from "react-router-dom";

import ProfileActionsStates from "../../ProfileActionsStates";
import {AppContext} from "../../../../App";

import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {responseStatus} from "../../../../utils/consts";

import loadingGif from "../../../../assets/images/loading.gif";
import userIcon from "../../../../assets/images/user_icon_dark.svg";
import {EmptyTable} from "../userBanListModal/styledUserBanListModal";

function BanListModal() {
    const {t} = useTranslation("siteOptions");

    const [banListData, setBanListData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));

    const {
        setVisibleBanListModal,
        setVisibleUnbanUserModal,
        visibleBanListModal,
        currentBanData,
        setCurrentBanData
    } = useBetween(ProfileActionsStates);

    const {authenticated} = useContext(AppContext);

    useEffect(() => {
        if (!visibleBanListModal) return;
        getBanListData();
    }, [visibleBanListModal]);

    useEffect(() => {
        if (!visibleBanListModal) return;

        const timer = setInterval(() => {
            setCurrentTime(Math.floor(Date.now() / 1000));
        }, 60000);

        return () => clearInterval(timer);
    }, [visibleBanListModal]);

    const getBanListData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_MODERATOR_LINK}/bans/get-ban-list`, userAuthenticationConfig()).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                setBanListData(response.data);
                setIsLoading(false);
            }
        }).catch(error => {
            closableNotification(error.response.data.message, "error");
            setVisibleBanListModal(false);
        });
    };

    function formatMinutes(minutes) {
        const units = [
            {label: t("botMessageWeeks"), value: 60 * 24 * 7},
            {label: t("botMessageDays"), value: 60 * 24},
            {label: t("botMessageHours"), value: 60},
            {label: t("botMessageMinutes"), value: 1}
        ];

        let result = [];

        for (const unit of units) {
            if (minutes >= unit.value) {
                const amount = Math.floor(minutes / unit.value);
                minutes %= unit.value;
                result.push(`${amount}${unit.label}`);
            }
        }

        return result.join(' ') || '0m';
    }

    function calculateRemainingTime(banned_at, ban_time) {
        const currentTime = Math.floor(Date.now() / 1000);
        const banEndTimestamp = banned_at + (ban_time * 60);
        const remainingSeconds = banEndTimestamp - currentTime;

        if (remainingSeconds <= 0) {
            return t("expired");
        }

        return formatMinutes(Math.ceil(remainingSeconds / 60));
    }

    const openUnbanUserModal = (banData) => {
        setCurrentBanData(banData);
        setVisibleUnbanUserModal(true);
        setVisibleBanListModal(false);
    }

    return (
        <>
            <UnbanUserModal currentBanData={currentBanData}/>
            <Dialog
                visible={authenticated && visibleBanListModal}
                wrapClassName="default-modal-wrapper"
                onClose={() => setVisibleBanListModal(false)}
                animation="zoom"
                maskAnimation="fade"
                title={t("blocked")}
                forceRender={false}
                className="default-modal ban-list-modal"
                style={{margin: "auto"}}
            >
                {isLoading || !banListData ?
                    <StyledModalLoader style={{height: "200px", minHeight: "auto", margin: "auto"}}><img
                        src={loadingGif} alt={"loading..."}/></StyledModalLoader> :
                    banListData?.length === 0 ? <EmptyTable>{t("noBans")}</EmptyTable> :
                        <StyledWrapperTable>
                            <StyledBanListTable>
                                <StyledBanListTableHead>
                                    <StyledBanListTableRow>
                                        <StyledBanListTableItem>{t("user")}</StyledBanListTableItem>
                                        <StyledBanListTableItem>{t("botMessageReason")}</StyledBanListTableItem>
                                        <StyledBanListTableItem>{t("message")}</StyledBanListTableItem>
                                        <StyledBanListTableItem>{t("time")}</StyledBanListTableItem>
                                        <StyledBanListTableItem>{t("timeLeft")}</StyledBanListTableItem>
                                        <StyledBanListTableItem>{t("action")}</StyledBanListTableItem>
                                    </StyledBanListTableRow>
                                </StyledBanListTableHead>
                                <StyledBanListTableBody>
                                    {banListData?.map((banData, key) => (
                                        <StyledBanListTableRow key={key}>
                                            <StyledBanListTableItem>
                                                <NavLink
                                                    to={generatePath(`/account/${banData?.user}`)}
                                                    onClick={() => setVisibleBanListModal(false)}
                                                    className="wrapper-link"
                                                >
                                                    <img
                                                        src={userIcon}
                                                        alt="user"
                                                    />
                                                    {banData?.user}
                                                </NavLink>
                                            </StyledBanListTableItem>
                                            <StyledBanListTableItem style={{maxWidth: "140px"}}>{banData?.cause}</StyledBanListTableItem>
                                            <StyledBanListTableItem style={{maxWidth: "140px"}}>{banData?.message}</StyledBanListTableItem>
                                            <StyledBanListTableItem>{formatMinutes(banData?.ban_time)}</StyledBanListTableItem>
                                            <StyledBanListTableItem>{calculateRemainingTime(banData?.banned_at, banData?.ban_time)}</StyledBanListTableItem>
                                            <StyledBanListTableItem>
                                                <StyledButton onClick={() => openUnbanUserModal(banData)}>
                                                    <i className="icon icon-error">&#xe905;</i>
                                                </StyledButton>
                                            </StyledBanListTableItem>
                                        </StyledBanListTableRow>
                                    ))}
                                </StyledBanListTableBody>
                            </StyledBanListTable>
                        </StyledWrapperTable>
                }
            </Dialog>
        </>
    )
}

export default BanListModal;