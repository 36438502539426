import styled from "styled-components";
import { StyledContentWrapper } from "../../styles/styledContainer";
import chipBlue from "../../../assets/images/roll/chip-blue.svg";
import chipRed from "../../../assets/images/roll/chip-red.svg";
import chipGreen from "../../../assets/images/roll/chip-green.svg";
import chipYellow from "../../../assets/images/roll/chip-orange.svg";
import chipGray from "../../../assets/images/roll/chip-cancel.svg";
import winningBall from '../../../assets/images/roll/winning-ball.png';
import winningBallMobile from '../../../assets/images/roll/winning-ball-mobile.png';
// import rouletteBall from "../../../assets/images/roll/ball.png";
import { StyledSiteOptionsWrapper } from "../../elements/siteOptions/styledSiteOptions";

const RED = [
  1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36
];

const BLACK = [
  2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35
];

export const StyledRedOrBlackIcon = styled.div`
  background: ${({
    isBlack,
    theme
  }) => isBlack ? theme.rollTableItemInnerDivBlackBackground : theme.rollTableItemInnerDivRedBackground};
  width: 25px;
  height: 25px;
  position: relative;
  // left: 14%;
  // top: 7px;
  transform: rotate(-45deg) skew(20deg, 20deg);
  // transform-origin: 0 100%;
`
// padding: 20px 0 20px;
// height: 295px;
export const StyledRouletteWrapper = styled.section`

  ${({ isMobile }) => isMobile ? 'padding: 20px 0 20px;' : 'padding: 30px 0;'}
  height: ${({ isMobile }) => isMobile ? '335px' : '540px'};

  @media screen and (max-width: 661px) {
    height: 290px;
    background: ${({theme}) => `url(${theme.bgRouletteMobile}) no-repeat 50% -260px;`}
  }

  ${({
    theme,
    isMobile
  }) => isMobile ? `background: url(${theme.bgRouletteMobile}) no-repeat 50% -245px;` : `background: url(${theme.bgRoulette}) no-repeat 50% 0;`}
      /*
  background-image: url("${({ theme }) => theme.bgRoulette}");
  background-position-x: center;
  background-position-y: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  */
  &:hover {
    ${StyledSiteOptionsWrapper} {
      .jackpot {
        opacity: ${({ theme }) => theme.optionOpacityHoverContainer};

        &:hover {
          opacity: 1;
          background-color: ${({ theme }) => theme.optionBackgroundHover};
          img {
            opacity: 1 !important;
            transition: opacity 0.3s;
          }
        }
        img {
          transition: opacity 0.3s;
        }
      }

      .panel {
        &__item {
          opacity: ${({ theme }) => theme.optionOpacityHoverContainer};

          &:hover {
            opacity: 1;
            background-color: ${({ theme }) => theme.optionBackgroundHover};
            .icon {
              opacity: 1;
              transition: opacity 0.3s ease-out;
            }
          }
          .icon {
            transition: opacity 0.3s ease-out;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .jackpot {
      margin-left: 0 !important;
    }
  }
`;

export const StyledRouletteContentWrapper = styled.div`
  max-width: ${({ width }) => width ? width + "px" : "1000px"};
  display: ${({ isFlex }) => isFlex ? "flex" : "block"};
  flex-direction: ${({ column }) => column ? "column" : "row"};
  align-items: ${({ position }) => position ? position : "unset"};
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1100px) {
    padding: 0 20px;
  }
`;

export const StyledRouletteBetWrapper = styled.div`
  background-color: ${({ theme }) => theme.elementBg};
  @media screen and (max-width: 1100px) {
    display: flex;
    justify-content: center;
  }
`;

export const StyledRouletteBetContent = styled(StyledContentWrapper)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: 1100px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1100px) {
    max-width: unset;
    padding: 0 20px;
  }
`;

export const StyledRouletteBetOptions = styled.div`

  ${({ isMobile }) => !isMobile ? `
  width: 440px;
  padding: 20px 0 20px 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
  ` : `
  width: 100%;
  padding: 20px 0 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  `}
  & > div:first-child {
    width: 200px;
    margin-left: 0px;
    @media screen and (max-width: 1100px) {
      width: 64%;
    }
  }

  & > div {
    width: 180px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 1100px) {
      width: 34%;
      margin-right: 0;

    }
    @media screen and (max-width: 768px) {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  & > div > button.control {
    margin: 0px;
    margin-bottom: 10px;
  }

  .input-group {
    margin-bottom: 0;

    label, input {
      text-align: center;
    }

    @media screen and (max-width: 450px) {
      input {
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    padding-left: 0;
  }
`;

export const StyledRouletteTable = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  user-select: none;

  @media screen and (max-height: 330px) {
    padding-top: 10px;
  }
`;

export const StyledRouletteRollWrapper = styled.div`
  width: ${({ isMobile }) => isMobile ? '200px' : '300px'};
  height: ${({ isMobile }) => isMobile ? '200px' : '300px'};
  @media screen and (max-width: 605px) {
    width: 180px;
    height: 180px;
  }
  position: relative;

  &:${({ isMobile }) => isMobile ? 'after' : 'before'} {
    content: "";
    position: absolute;
    ${({ isMobile, theme }) => !isMobile ? `
    bottom: -10px;
    left: 80px;
    min-width: 140px;
    width: 140px;
    border-radius: 1000px;
    height: 20px;
    background: ${theme.rollShadowBackground};
    box-shadow: ${theme.rollShadow};
    ` : `
    bottom: -10px;
    left: 60px;
    width: 80px;
    height: 15px;
    background: ${theme.rollMobileShadowBackground};
    border-radius: 1000px;
    box-shadow: ${theme.rollMobileShadow};
    `}
  }
`

export const StyledRouletteRollSpan = styled.span`
  width: ${({ isMobile }) => isMobile ? '200px' : '300px'};
  height: ${({ isMobile }) => isMobile ? '200px' : '300px'};
  background: url("${({ theme }) => theme.roll}");
  background-size: ${({ isMobile }) => isMobile ? '200px 200px' : '300px 300px'};
  display: block;
  @media screen and (max-width: 605px) {
    width: 185px;
    height: 185px;
    background-size: 185px;
  }
`;
export const StyledRouletteRoll = styled.div`
  @media screen and (max-width: 605px) {
    width: 185px;
    height: 185px;
  }
  width: ${({ isMobile }) => isMobile ? '200px' : '300px'};
  height: ${({ isMobile }) => isMobile ? '200px' : '300px'};
  ${({ isMobile }) => isMobile ? 'position: absolute;' : ''}
`;

export const StyledRouletteBall = styled.div`
  position: relative;
  width: ${({ isMobile }) => isMobile ? '152px' : '236px'};
  height: ${({ isMobile }) => isMobile ? '15px' : '27px'};
  top: ${({ isMobile }) => isMobile ? '91px' : '-165px'};
  left: ${({ isMobile }) => isMobile ? '24px' : '32px'};
  text-align: right;
  opacity: 0;
  ${({ isMobile }) => isMobile ? `
  & > img {
    width: 15px;
    height: 15px;
  }
  ` : ''}
`;

export const StyledWinningBall = styled.div`


  ${({ isMobile, zero }) => !isMobile ? `
  position: absolute;
  top: ${!zero ? '0' : '65px'};
  left: 0;
  display: block;
  ` : `
  position: absolute;
  display: flex;
  width: 100%;
  `};

  & > span {
    position: absolute;
    background: url(${({ isMobile }) => isMobile ? winningBallMobile : winningBall}) no-repeat;
    ${({ isMobile }) => !isMobile ? `
      background: url(${winningBall}) no-repeat;
      height: 85px;
      font-size: 12px;
      line-height: 25px;
      top: -19px;
      left: -2px;
      width: 48px;
    ` : `
      background: url(${winningBallMobile}) 50% 0 no-repeat;
      height: 61px;
      font-size: 11px;
      line-height: 25px;
      width: 100%;
      top: -40px;
    `}

    text-align: center;

    font-weight: 400;
    color: #000000;
    z-index: 10;
    font-family: SegoeUI, Tahoma;
  }
`;

/*
export const StyledRouletteBall = styled.div`
  position: absolute;
  width: 228px;
  height: 228px;
  top: 35px;
  right: 35px;
  z-index: 3;
  transition: opacity .3s ease;
  ${({toPlay, value}) => toPlay && `
    background: url("${rouletteBall}") no-repeat right center;
    animation-duration: 8s;
    animation-name: playRoulette;
    animation-fill-mode: forwards;
    @keyframes playRoulette {
      0% {
        transform: rotate(0deg);
        opacity: 0;
      }
      15% {
        opacity: 1;
      }
      100% {
        // ${ballAnimations(value)};
        opacity: 1;
      }
    }
  `};
`;

*/


export const StyledRouletteNum = styled.div`
  width: calc(100% - 390px);
  position: relative;

  .num-wrapper {
    display: flex;
    flex-wrap: wrap;
      // border-top: 2px solid ${({ theme }) => theme.rollTableBorder};
    position: relative;
  }

  .num-wrapper-overlay {
    top: ${({ isMobile }) => !isMobile ? '-293px;' : '-201px;'} & > div {
    border-color: transparent;
    background-color: transparent !important;
    // z-index: 30 !important;

    &:before {
      opacity: 0;
    }

    & > span {
      opacity: 0;
    }
  }

    & > div > div {
      border-color: transparent;
      background-color: transparent !important;
    }
  }

  .last {
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background: ${({ theme }) => theme.rollTableBorder};
      top: 100%;
    }
  }

  .hover {
    background-color: ${({ theme }) => theme.rollTableHover};
  }

  &:after {
    content: "";
    position: absolute;
    ${({ isMobile, theme }) => !isMobile ? `
      top: 288px;
      width: 555px;
      height: 20px;
      background: ${theme.rollTableShadowBackground};
      box-shadow: ${theme.rollTableShadow};
    ` : `
      top: 194px;
      width: 91%;
      height: 15px;
      background: ${theme.rollMobileTableShadowBackground};
      box-shadow: ${theme.rollMobileTableShadow};
    `}
    z-index: 0;
    border-radius: 1000px;

  }

  @media screen and (max-width: 1100px) {
    width: 60%;
    margin-top: 0px;
  }
`;

const itemType = (type, theme, isMobile) => {
  switch (type) {
    case "main":
      return `
        color: #fff;
        &:before {
          content: "";
          ${!isMobile ? 'width: 32px;' : 'width: calc(100% - 4px);'}

          height: ${!isMobile ? '35px' : '26px'};
          border-radius: 4px;
          position: absolute;
          z-index: -1;
          /*
          @media screen and (max-width: 1000px) {
            width: 25px;
          }
          @media screen and (max-width: 715px) {
            width: 19px;
          }
          */
        }
    `;
    case "row":
      return `
        ${!isMobile ? 'width: 48px' : ''};
        background: ${theme.rouletteRowBackDown};
        & > div:first-child {
          color: ${theme.subTitleColor};
          font-family: Segoe UI Classic;
        }
        & > div {
          text-align: center;
          border: 1px solid #333333;

          ${!isMobile ? 'font-size: 13px;' : 'font-size: 12px;'}
          ${!isMobile ? 'padding: 6px;' : 'padding: 4px;'}
          position: absolute;
          ${!isMobile ? 'top: 50%' : ''};
          ${!isMobile ? 'left: 50%' : ''};
          ${!isMobile ? 'transform: rotate(-90deg) translate(32%, -84%)' : ''};
          white-space: nowrap;
          /*
          @media screen and (max-width: 1000px) {
            padding: 5px;
            font-size: 10px;
          }
          @media screen and (max-width: 715px) {
            padding: 4px;
            font-size: 9px;
          }
          */
        }
    `;
  }
};

export const StyledRouletteItem = styled.div`
  width: ${({ isMobile }) => !isMobile ? '46px' : '7.6%'};
  height: ${({ isMobile }) => !isMobile ? '67px' : '42px'};
  color: ${({ theme }) => theme.text};
  font-size: ${({ isMobile }) => !isMobile ? '18px' : '16px'};
  font-family: Segoe UI Bold;
  // font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.rollTableBorder};
  background: ${({ theme }) => theme.rollTableBackground};
  border-bottom: none;
  border-left: none;
  position: relative;
  cursor: ${({ isSnapshot }) => isSnapshot ? "default" : "pointer"};
  transition: background 0.1s linear, box-shadow 0.2s linear;
  // background-color 0.15s ease-in-out;

  z-index: ${({ isSnapshot, zero, isMobile }) => isSnapshot ? '1' : (!zero && !isMobile) ? '1' : '2'};
  ${({ zero, theme, isMobile }) => zero && `
    width: ${!isMobile ? '46px' : '7%'};
    height:  ${!isMobile ? '203px' : '128px'};
    position: absolute;
    top: 0px;
    right: 100%;
    border-top: 2px solid ${theme.rollTableBorder};
    border-bottom: 2px solid ${theme.rollTableBorder};
    border-left: 2px solid ${theme.rollTableBorder};
    ${isMobile ? 'border-radius: 25px 0 0 25px;' : 'border-radius: 15px 0 0 15px;'}
    color: ${theme.subTitleColor};
  `};
  ${({ type, theme, isMobile }) => itemType(type, theme, isMobile)};
  ${({ data, isMobile, theme }) => RED?.map((i) => (data === i.toString()) && `
    &:before {
      background: ${theme.rollTableItemInnerDivRedBackground};
      border-radius: ${!isMobile ? '6px' : '25px'};
    }`
  )};
  ${({ data, isMobile, theme }) => BLACK?.map((i) => (data === i.toString()) && `
    &:before {
      background: ${theme.rollTableItemInnerDivBlackBackground};
      border-radius: ${!isMobile ? '6px' : '25px'};
    }`
  )};

  .chip {
    color: #000;
    font-size: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    opacity: 1;

    &:hover {
      transform: translate(-50%, -50%);
    }
  }

  /*
  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
  */
`;

export const StyledRouletteConstWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${({ isMobile }) => !isMobile ? 'calc( 100% - (100% / 13) + 2px );' : 'calc(100% + 2px);'}
  border-left: 2px solid ${({ theme }) => theme.rollTableBorder};
  margin-left: -2px;

  .w-3,
  .w-6 {
    height: 46px;
    background: ${({ theme }) => theme.rouletteBackDown};
    font-family: Segoe UI Classic;
    color: ${({ theme }) => theme.subTitleColor};
    /*
    @media screen and (max-width: 1000px) {
      height: 30px;
    }
    */
  }

  .w-3.hover,
  .w-6.hover {
    background: ${({ theme }) => theme.rollTableHover} !important;
  }

  .w-3 {
    width: ${({ isMobile }) => !isMobile ? '184px' : '30.4%'};
    ${({ isMobile }) => isMobile ? 'height: 40px' : ''}
  }

  .w-3-border {
    width: ${({ isMobile }) => !isMobile ? '44px' : '7.6%'};
    background: none;
    pointer-events: none;
    border-right: none;
  }

  .w-6 {
    width: ${({ isMobile }) => !isMobile ? '92px' : '15.2%'};
    border-bottom: 2px solid ${({ theme }) => theme.rollTableBorder};
    ${({ isMobile }) => isMobile ? 'height: 35px;' : ''}
    ${({ isMobile }) => isMobile ? 'font-size: 14px;' : ''}
  }
`;

export const StyledRouletteNoOverlay = styled.div`
  width: ${({ isMobile }) => !isMobile ? 'calc(100% - 20px);' : '100%;'}
  height: 100%;
  padding-top: ${({ isMobile }) => isMobile ? '5px;' : '9px;'}
  box-sizing: content-box;
  pointer-events: none;
  position: absolute;

  ${({ isMobile }) => !isMobile ? 'left: -37px;' : 'left: -6.7%;'}
  & > div {
    float: left;
    border-radius: 3px;
    position: relative;
    pointer-events: all;
    cursor: pointer;
    z-index: 2;
  }

  .clear {
    height: 35px;
    width: ${({ isMobile }) => isMobile ? '4.6%' : '25px'};
    cursor: inherit;
    z-index: -1;
    ${({ isMobile }) => isMobile ? 'clear: both;' : ''}
  }

  /*
  @media screen and (max-width: 1100px) {
    width: 100%;
    left: -35px;
  }
  */
`;

const noOverlayItemType = (type, isMobile) => {
  switch (type) {
    case "splitH":
      return `
        height: ${!isMobile ? '27px;' : '31px;'}
        width: ${!isMobile ? '22px;' : '3.6%;'};
        ${!isMobile && `
          margin-top: 9px;
          margin-right: 24px;
        `}
        ${isMobile && `
          margin-top: 0px;
          margin-right: 4%;
        `}
        /*
        @media screen and (max-width: 1100px) {
          width: 21.2px;
          margin-right: 21px;
        }
        @media screen and (max-width: 1000px) {
          width: 16.8px;
          margin-right: 14px;
        }
        @media screen and (max-width: 715px) {
          width: 14.4px;
          margin-right: 11px;
        }
        */
      `
    case "splitV":
      return `
        margin-left: 0;
        height: 16px;

        ${!isMobile ? 'width: 24px;' : 'width: 4%;'}
        ${!isMobile ? 'margin-top: 14px;' : 'margin-top: -5px;'}
        /*
        @media screen and (max-width: 1100px) {
          width: 23.8px;
        }
        @media screen and (max-width: 1000px) {
          width: 12px;
        }
        @media screen and (max-width: 715px) {
          width: 9.4px;
        }
        */
      `
    case "corner":
      return `

        margin-left: 0;
        height: 16px;
        width: ${!isMobile ? '22px' : '3.6%'};
        ${!isMobile ? 'margin-top: 14px;' : 'margin-top: -5px;'}

        /*
        @media screen and (max-width: 1100px) {
          width: 18.5px;
        }
        @media screen and (max-width: 1000px) {
          width: 18.7px;
        }
        @media screen and (max-width: 715px) {
          width: 16px;
        }
        */
      `
  }
}

export const StyledRouletteNoOverlayItem = styled.div`
  ${({ type, isMobile }) => noOverlayItemType(type, isMobile)};
  position: relative;

  .chip {
    font-size: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    opacity: 1;

    &:hover {
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    background-color: rbga(0, 0, 0, 0);

  }

  // background-color: rgba(0,255,0,0.3);
`;

export const StyledChipsWrapper = styled.div`
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  user-select: none;
  @media screen and (max-width: 605px) {
    width: 180px;
  }
  ${({ isMobile }) => isMobile ? 'width: 200px; margin: 0 auto;' : ''}
  .title {
    width: 100%;
    padding-bottom: 5px;
    color: ${({ theme }) => theme.labelColor};
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    /*
    @media screen and (max-width: 375px) {
      font-size: 13px;
    }
    */
  }

  .chips-grid {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 0px;
  }
`;

const chipColor = (color) => {
  switch (color) {
    case "red":
      return `
        background-image: url("${chipRed}");
      `
    case "green":
      return `
        background-image: url("${chipGreen}");
      `
    case "yellow":
      return `
        background-image: url("${chipYellow}");
      `
    case "gray":
      return `
        background-image: url("${chipGray}");
      `

    case "blue":
      return `
        background-image: url("${chipBlue}");
      `
  }
}

export const StyledChip = styled.button`
  width: ${({ isMobile }) => isMobile ? '35px;' : '38px;'}
  height: ${({ isMobile }) => isMobile ? '35px;' : '38px;'}
  color: #000;
  font-family: Arial;
  font-size: 8px;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  border: none;
  box-shadow: 0 2px 5.7px 0.3px rgb(0 0 0 / 29%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  // transition: all .2s ease;
  opacity: 0.6;
  ${({ color }) => chipColor(color)};

  ${({ isMobile }) => isMobile ? 'background-size: cover;' : 'background-size: 38px 38px;'}
  ${({ current }) => current && `
    transform: scale(1.1);
    opacity: 1;
  `}
  transition: all linear 0.1s;

  ${({ isSnapshot }) => isSnapshot && `
   z-index: 20;
  `}

  span {
    font-size: 12px;
    display: block;
    line-height: 1;
    margin-top: -1px;
  }
  
  .tooltip {
    position: absolute;
    bottom: 120%;
    left: ${({isLastChip}) => isLastChip ? "-50%" : "50%"};
    transform: translateX(-50%);
    background-color: ${({theme}) => theme.scrollBackground};
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: ${({ isMobile }) => isMobile ? "12px" : "14px"};
    white-space: nowrap;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }

  .tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: ${({isLastChip}) => isLastChip ? "75%" : "50%"};
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: ${({theme}) => theme.scrollBackground} transparent transparent transparent;
  }
  
  .tooltip.active {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    transform: scale(1.2);
    opacity: 1;
    z-index: 90;
    transition: all linear 0.1s;
    ${({ isSnapshot }) => isSnapshot && `
    .tooltip {
    opacity: 1;
    visibility: visible;
    }
  `}
  }
`;

export const StyledRouletteHistoryWrapper = styled.div`
    padding: 25px 0 10px;
    background-image: url("${({theme}) => theme.bgRouletteHistory}");
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: 100% auto;

    .default-tabs-top {
        gap: 10px;
    }

    .roulette-history-table {
        padding: 0 0 10px;

        @media screen and (max-width: 450px) {
            min-width: 835px;
            width: 835px;
            font-size: 14px;
        }

        &__head, &__row {
            grid-template-columns: 120px 140px 90px minmax(240px, 1fr) 150px 150px;

            @media screen and (max-width: 450px) {
                grid-template-columns: 55px 110px 65px 230px 135px 135px;
            }
        }

        &_loading,
        &_empty {
            padding: 12px 15px;
            grid-template-columns: 1fr;
            text-align: center;
            text-transform: uppercase;
        }

        &__currency,
        &__number {
            text-align: center;
            justify-content: center;
        }
    }

    .roulette-history-table__options {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 44.789px;
            cursor: pointer;
          
            svg {
                margin-right: 5px;
                display: flex;
                fill: ${({ theme }) => theme.historyLink};
                transition: all 0.15s ease-in-out;
            }
          
            span {
              color: ${({ theme }) => theme.historyLink};
              transition: all 0.15s ease-in-out;
              font-size: 16px;
              line-height: 16px;
              font-weight: 500;
              text-transform: uppercase;
              user-select: none;
            }

            &:last-child {
                margin-left: 15px;
            }
            &:hover span, &:hover svg {
              color: ${({ theme }) => theme.historyLinkHover};
              fill: ${({ theme }) => theme.historyLinkHover};
            }
        }
    }

    @media screen and (max-width: 770px) {
      .roulette-history-table__options div {
          height: 50.789px;
      }
    }

    .custom-hint {
        position: relative;
        width: 0;
        transform: translate(20px, -31px);
        z-index: 100;
        /*
        @media screen and (max-width: 375px) {
          transform: translate(0, -31px);
        }
        */
    }

    /*
    @media screen and (max-width: 1100px) {
      padding: 30px 0;
    }
    */
`;

export const StyledAboutRoulette = styled.div`
  max-width: 900px;
  margin: 0 auto 50px;
  
  @media screen and (max-width: 1080px) {
    padding: 0 5%;
  }
  
  ${({ orientationPortrait }) => orientationPortrait && `margin-top: 20px`};
`;

export const StyledRotatePhone = styled.div`
  width: 230px;
  margin: 0 auto 0;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  border-radius: 10px;
  margin-top: 20px;

  img {
    width: 100px;
    height: 100px;
  }

  p {
    font-size: 18px;
    margin-top: 10px;
    color: #ffffff;
  }
`;

export const StyledRouletteCustomValueChipModal = styled.div`
  
`

export const StyledSnapshotContainer = styled.div`
  position: absolute;
  top: 0;
  padding-top: 56px;

  @media screen and (max-width: 1100px) {
    & {
      padding-top: 76px;
    }
  }

  ${({isLandscape}) => !isLandscape && `
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`