import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";
import { responseStatus } from "../../utils/consts";
import { closableNotification } from "../elements/notification/ClosableNotification";
import Spinner from "../elements/spinner/Spinner";

import { StyledContainer, StyledContentWrapper } from "../styles/styledContainer";
import { StyledPageTemplateWrapper } from "../styles/styledPageTemplate";
import { StyledMainTitle } from "../styles/styledTitle";
import { StyledParagraph } from "../styles/styledDocumetnElemets";
import { StyledLink } from "../../pages/faq/styledFaqPage";
import {
  StyledBlockContent,
  StyledCode,
  StyledSmall,
  StyledTitle,
  StyledTitleBlock
} from "../../pages/banners/styledBannersPage";

import banner728_90 from "../../assets/images/banners/one.gif";
import banner468_60 from "../../assets/images/banners/two.gif";
import banner300_300 from "../../assets/images/banners/three.gif";
import banner200_200 from "../../assets/images/banners/four.gif";
import banner300_200 from "../../assets/images/banners/five.gif";
import banner728_90_png from "../../assets/images/banners/six.jpg";
import banner468_60_png from "../../assets/images/banners/seven.png";
import {getCurrentGame} from "../../utils/online";

const BannersContainer = () => {
  const { t } = useTranslation("banners");
  const { t: tO } = useTranslation("siteOptions");

  const url = window.location.origin;
  const [userReferralStatistics, setUserReferralStatistics] = useState(null);

  const fetchUserReferralStatistics = () => {
    axios.get("/api/user-referral-settings", userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK && response.data) {
        setUserReferralStatistics(response.data);
      }
    }).catch(error => {
      closableNotification(error.response.data.message, "error");
    });
  };

  function copyCodeBanner (code) {
    navigator.clipboard.writeText(code).then(() => {
      closableNotification(tO("copyCodeBannerNotificationSuccess"), "success")
    }).catch((err) => {
      closableNotification(tO("copyReferralLinkNotificationError"), "error")
    });
  }

  useEffect(() => {
    fetchUserReferralStatistics();
  }, []);

  if (!userReferralStatistics) {
    return (
      <StyledContainer>
        <Spinner display="block" size="35px" />
      </StyledContainer>
    );
  }

  const referralLink = `${url}/?ref=${userReferralStatistics.referralToken}`;

  return (
    <StyledContainer>
      <Helmet>
        <title>{t("banners")} | Luckygames</title>
      </Helmet>
      <StyledPageTemplateWrapper game={getCurrentGame()}>
        <StyledContentWrapper width="1000">
          <StyledMainTitle className="page-title" position="center" mb="35">
            {t("banners")}
          </StyledMainTitle>
          <StyledParagraph style={{color: "#9f9f9f"}}>
            {t("doYouThinkThat")}
            <StyledLink href="#" className={"gray"}>{t("contactUs")}</StyledLink>
            {t("rightNowWeAreAlways")}
          </StyledParagraph>
          <div>
            <StyledTitle>
              728x90
            </StyledTitle>
            <StyledBlockContent>
              <img src={banner728_90} alt={`Luckygames - Bitcoin ${t("onlineCasino")}`} />
              <StyledCode onClick={() => copyCodeBanner(`<a href="${referralLink}"><img src="${url}/assets/one.gif" alt="Luckygames - Bitcoin ${t("onlineCasino")}"/></a>`)}>
                &lt;a href="{referralLink}"&gt;&lt;img src="{url}/assets/one.gif" alt="Luckygames -
                    Bitcoin {t("onlineCasino")}"/&gt;&lt;/a&gt;
              </StyledCode>
            </StyledBlockContent>
          </div>
          <div>
            <StyledTitle>
              468x60
            </StyledTitle>
            <StyledBlockContent>
              <img src={banner468_60} alt={`Luckygames - Bitcoin ${t("onlineCasino")}`} />
              <StyledCode onClick={() => copyCodeBanner(`<a href="${referralLink}"><img src="${url}/assets/two.gif" alt="Luckygames - Bitcoin ${t("onlineCasino")}"/></a>`)}>
                &lt;a href="{referralLink}"&gt;&lt;img src="{url}/assets/two.gif" alt="Luckygames -
                    Bitcoin {t("onlineCasino")}"/&gt;&lt;/a&gt;
              </StyledCode>
            </StyledBlockContent>
          </div>
          <div>
            <StyledTitle>
              300x300
            </StyledTitle>
            <StyledBlockContent>
              <img src={banner300_300} alt={`Luckygames - Bitcoin ${t("onlineCasino")}`} />
              <StyledCode onClick={() => copyCodeBanner(`<a href="${referralLink}"><img src="${url}/assets/three.gif" alt="Luckygames - Bitcoin ${t("onlineCasino")}"/></a>`)}>
                &lt;a href="{referralLink}"&gt;&lt;img src="{url}/assets/three.gif" alt="Luckygames -
                    Bitcoin {t("onlineCasino")}"/&gt;&lt;/a&gt;
              </StyledCode>
            </StyledBlockContent>
          </div>
          <div>
            <StyledTitle>
              200x200
            </StyledTitle>
            <StyledBlockContent>
              <img src={banner200_200} alt={`Luckygames - Bitcoin ${t("onlineCasino")}`} />
              <StyledCode onClick={() => copyCodeBanner(`<a href="${referralLink}"><img src="${url}/assets/four.gif" alt="Luckygames - Bitcoin ${t("onlineCasino")}"/></a>`)}>
                &lt;a href="{referralLink}"&gt;&lt;img src="{url}/assets/four.gif" alt="Luckygames -
                    Bitcoin {t("onlineCasino")}"/&gt;&lt;/a&gt;
              </StyledCode>
            </StyledBlockContent>
          </div>
          <div>
            <StyledTitleBlock>
              {t("sharedByUsers")}
            </StyledTitleBlock>
            <StyledTitle>
              728x90
              <StyledSmall>
                ({t("thanksTo")}
                <a href="#">
                  <span className="icon-user"></span>
                  User
                </a>)
              </StyledSmall>
            </StyledTitle>
            <StyledBlockContent>
              <img src={banner728_90_png} alt={`Luckygames - Bitcoin ${t("onlineCasino")}`} />
              <StyledCode onClick={() => copyCodeBanner(`<a href="${referralLink}"><img src="${url}/assets/six.jpg" alt="Luckygames - Bitcoin ${t("onlineCasino")}"/></a>`)}>
                &lt;a href="{referralLink}"&gt;&lt;img src="{url}/assets/six.jpg" alt="Luckygames -
                    Bitcoin {t("onlineCasino")}"/&gt;&lt;/a&gt;
              </StyledCode>
            </StyledBlockContent>
          </div>
          <div>
            <StyledTitle>
              468x60
              <StyledSmall>
                ({t("thanksTo")}
                <a href="#">
                  <span className="icon-user"></span>
                  User
                </a>)
              </StyledSmall>
            </StyledTitle>
            <StyledBlockContent>
              <img src={banner468_60_png} alt={`Luckygames - Bitcoin ${t("onlineCasino")}`} />
              <StyledCode onClick={() => copyCodeBanner(`<a href="${referralLink}"><img src="${url}/assets/seven.png" alt="Luckygames - Bitcoin ${t("onlineCasino")}"/></a>`)}>
                &lt;a href="{referralLink}"&gt;&lt;img src="{url}/assets/seven.png" alt="Luckygames -
                    Bitcoin {t("onlineCasino")}"/&gt;&lt;/a&gt;
              </StyledCode>
            </StyledBlockContent>
          </div>
          <div>
            <StyledTitle>
              300x200
              <StyledSmall>
                ({t("thanksTo")}
                <a href="#">
                  <span className="icon-user"></span>
                  User
                </a>)
              </StyledSmall>
            </StyledTitle>
            <StyledBlockContent>
              <img src={banner300_200} alt={`Luckygames - Bitcoin ${t("onlineCasino")}`} />
              <StyledCode onClick={() => copyCodeBanner(`<a href="${referralLink}"><img src="${url}/assets/five.gif" alt="Luckygames - Bitcoin ${t("onlineCasino")}"/></a>`)}>
                &lt;a href="{referralLink}"&gt;&lt;img src="{url}/assets/five.gif" alt="Luckygames -
                    Bitcoin {t("onlineCasino")}"/&gt;&lt;/a&gt;
              </StyledCode>
            </StyledBlockContent>
          </div>
        </StyledContentWrapper>
      </StyledPageTemplateWrapper>
    </StyledContainer>
  );
};

export default BannersContainer;