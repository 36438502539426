import DOMPurify from "dompurify";

export const encodeMessage = (htmlString) => {
    const parser = new DOMParser();
    const messageWrapper = parser.parseFromString(htmlString, 'text/html')?.body;

    const divsToProcess = [];
    messageWrapper.querySelectorAll('div').forEach(div => {
        if (!div.className && !div.hasAttributes()) {
            divsToProcess.push(div);
        }
    });

    divsToProcess.forEach(div => {
        const content = div.innerHTML;
        const textNode = document.createTextNode(content);
        div.parentNode.replaceChild(textNode, div);
    });

    for (const child of messageWrapper.children) {
        const dataCode = child.getAttribute('data-code');
        if (dataCode) {
            child.src = dataCode;
            child.removeAttribute('data-code');
            child.removeAttribute('width');
        }
    }

    return messageWrapper.innerHTML;
}

export const decodeMessage = (messageForDecode) => {
    const parser = new DOMParser();

    const sanitizedMessage = DOMPurify.sanitize(messageForDecode);

    const messageWrapper = parser.parseFromString(sanitizedMessage, 'text/html')?.body;

    return messageWrapper.innerHTML;
}