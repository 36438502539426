import { closableNotification } from "../../elements/notification/ClosableNotification";

const validationTag = (value, t) => {
  let error = false;

  if (!value || !value?.length) {
    closableNotification(t("memoNotEmpty"), "error");
    error = true;
  }

  return error;
};

export {
  validationTag
};