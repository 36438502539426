import Dialog from "rc-dialog";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {StyledRotatePhone, StyledRouletteNum, StyledRouletteTable, StyledSnapshotContainer} from "./styledRoulette";
import Roulette from "./Roulette";
import RouletteNums from "./RouletteNums";
import {useLayout} from "../../../utils/useLayout";
import {TEXT_RATES} from "./constants";
import {useAutoBetsStatesRoulette} from "../../elements/autoBets/AutoBetsStates";
import {useAnimationSync} from "../AnimationSyncDataStates";
import rotateImg from "../../../assets/images/rotate-device.png";

function RouletteSnapshotModal({snapshotBetData, setSnapshotBetData}) {
    const {t} = useTranslation("games");

    const containerRef = useRef();
    const rouletteRef = useRef();

    const {isMobile, orientation} = useLayout();
    const {autoModeIsStart, gameInfoRef} = useAutoBetsStatesRoulette();
    const {stopAnimationSync} = useAnimationSync();

    const [animationDisabled, setAnimationDisabled] = useState((localStorage.getItem(`animationDisabled`) && localStorage.getItem(`animationDisabled`) !== "undefined") ? localStorage.getItem(`animationDisabled`) === 'true' : false);
    const [selectedNumber, setSelectedNumber] = useState(undefined);

    useEffect(async () => {
        await rouletteRef?.current?.rotateTo(
            snapshotBetData.drawnNumber,
            animationDisabled,
            false,
            (selectNum) => {
                setSelectedNumber(selectNum);
            },
        );
    }, [animationDisabled, autoModeIsStart, stopAnimationSync, gameInfoRef]);

    useEffect(() => {
        if (!!snapshotBetData) {
            window.history.pushState({modal: true}, '');

            const handlePopState = () => {
                setSnapshotBetData(null);
            };

            window.addEventListener('popstate', handlePopState);
            return () => window.removeEventListener('popstate', handlePopState);
        }
    }, [!!snapshotBetData, setSnapshotBetData]);

    return (
        <Dialog
            visible={!!snapshotBetData}
            wrapClassName={`default-modal-wrapper roulette snapshot-modal`}
            onClose={() => setSnapshotBetData(null)}
            animation="zoom"
            maskAnimation="fade"
            title={`SNAPSHOT #${snapshotBetData.id}`}
            destroyOnClose={true}
            forceRender={false}
            className="default-modal-wider">
            <StyledSnapshotContainer isLandscape={orientation === "landscape"}>
                {orientation === "landscape" ? <StyledRouletteTable
                    ref={containerRef}
                >
                    <Roulette
                        ref={rouletteRef}
                        isMobile={isMobile}
                    />

                    <StyledRouletteNum
                        isMobile={isMobile}
                    >
                        <RouletteNums
                            submitData={snapshotBetData}
                            setSubmitData={() => {
                            }}
                            textRates={TEXT_RATES}
                            isPlay={false}
                            orientation={orientation}
                            selectedNumber={selectedNumber}
                            isMobile={isMobile}
                            autoModeIsStart={false}
                            autoModeAcceleration={3}
                            isSnapshot={true}
                        />

                        <RouletteNums
                            submitData={snapshotBetData}
                            setSubmitData={() => {
                            }}
                            textRates={TEXT_RATES}
                            isPlay={false}
                            orientation={orientation}
                            selectedNumber={selectedNumber}
                            isMobile={isMobile}
                            isOverlay={true}
                            autoModeIsStart={false}
                            autoModeAcceleration={3}
                            isSnapshot={true}
                        />
                    </StyledRouletteNum>
                </StyledRouletteTable> : <StyledRotatePhone>
                    <img src={rotateImg} alt="rotate"/>
                    <p>{t("pleaseTurnYourDevice")}</p>
                </StyledRotatePhone>}
            </StyledSnapshotContainer>
        </Dialog>
    )
}

export default RouletteSnapshotModal;