import { StyledReCaptcha } from "./styledReCaptcha";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import {AppContext} from "../../../App";
import {useContext} from "react";

const ReCaptcha = ({ recaptchaRef, onComplete }) => {
    const { t } = useTranslation("siteOptions");
    const { theme } = useContext(AppContext);

    return <StyledReCaptcha>
        <span className={'recaptcha-wrapper__title'}>{t('reCaptchaTitle')}</span>
        <div className={'recaptcha-wrapper__content'}>
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC}
                ref={recaptchaRef}
                theme={theme.themeName}
                onChange={(token) => {
                    if (token && onComplete) {
                        onComplete();
                    }
                }}
            />
        </div>
    </StyledReCaptcha>
}

export default ReCaptcha;