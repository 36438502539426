import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import axios from "axios";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import {closableNotification} from "../../elements/notification/ClosableNotification";
import {AppContext, MercureUrl} from "../../../App";
import {generateJWSToken} from "../../../utils/mercureAuth";
import {useAnimationSync, useAnimationSyncData} from '../AnimationSyncDataStates';
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import {StyledBetHistoryTable, StyledBetHistoryTBody, StyledBetHistoryTHead} from "../styledBetsHistoryTable";
import {useBetween} from "use-between";
import BalanceStates from "../BalanceStates";
import DiceBetsList from "./DiceBetsList";

const sortBets = (data) => {
    // console.log('sortBets,data:', data);
    data.sort(function (a, b) {
        return -(a.id - b.id);
    });

    return data;
};

const DiceBetsHistoryContainer = React.memo(({type, responseData, setResponseData}) => {
    const {t} = useTranslation("games");
    const {user} = useContext(AppContext);
    const [bets, setBets] = useState({data: null});
    // const [allBets, setAllBets] = useState({ data: null });
    // const [list] = useDebounce(bets, 1000);
    const bufferRef = useRef([]);
    const {isAnimationSyncDataStart} = useAnimationSync();
    const isAnimationSyncDataStartRef = useRef();
    const lastTimeBetsUpdate = useRef(0);
    const lastRenderTime = useRef(0);
    const eventSourceRef = useRef(null);

    const { balance, profit } = useBetween(BalanceStates);
    const dataBalanceInfo = useMemo(() => ({balance, profit}), [balance, profit]);
    const balanceInfo = useAnimationSyncData(dataBalanceInfo);

    useEffect(() => {
        if (!responseData.betInfo || type === 'All') return;

        setBets((prevState) => {
            const newBetsData = prevState.data ? [{...responseData.betInfo}, ...prevState.data] : [{...responseData.betInfo}];

            if (newBetsData?.length > 40) {
                newBetsData.pop();
            }

            return { data: newBetsData };
        });

        setResponseData((prevState) => ({...prevState, betInfo: null}));
    }, [balanceInfo]);

    useEffect(() => {
        setResponseData((prevState) => ({...prevState, betInfo: null}));
    }, [type]);

    isAnimationSyncDataStartRef.current = isAnimationSyncDataStart;

    const userId = useMemo(() => user?.userId, [user]);

    const fetchBets = useCallback(() => {
        let url = "/api/bets?game=Dice&itemsPerPage=40";
        if (type === "My") {
            url = url + "&user.id=" + userId;
        }
        axios.get(url, userAuthenticationConfig()).then(response => {
            if (response.status === 200) {
                if (bufferRef.current?.length > 0) {
                    bufferRef.current.splice(0, bufferRef.current?.length);
                }
                setBets((prevState) => ({...prevState, "data": response.data["hydra:member"]}));
            }
        }).catch(error => {
            closableNotification(error.response?.data?.message, "error");
        });
    }, [type, userId]);

    useEffect(() => {
        let timeoutId = 0;
        const checkBuffer = () => {
            const currentTime = (new Date()).getTime();
            // console.log('');
            const isAllUpdate = (type === 'All' && currentTime - lastTimeBetsUpdate.current > 1000);
            const isMyBetUpdate = (type === 'My' && currentTime - lastTimeBetsUpdate.current > 100);
            if (isAllUpdate || isMyBetUpdate) {
                const tmpBets = bets.data;
                // console.log('tmpBets:', tmpBets?.length);
                if (tmpBets) {
                    const buffer = bufferRef.current;
                    let befferlen = buffer?.length;
                    let needUpdate = false;
                    const tmpBuffer = [...buffer];
                    // console.log('buffer:', buffer);
                    while (befferlen > 0) {
                        const newBet = tmpBuffer.pop();
                        befferlen--;
                        if (newBet) {
                            const index = tmpBets.findIndex(({id}) => newBet.id === id);
                            if (index < 0) {
                                needUpdate = true;
                                break;
                            }
                        }
                    }
                    if (needUpdate && !isAnimationSyncDataStartRef.current) {
                        let befferlen = buffer?.length;
                        while (befferlen > 0) {
                            const newBet = buffer.pop();
                            befferlen--;
                            if (newBet) {
                                const index = tmpBets.findIndex(({id}) => newBet.id === id);
                                if (index < 0) {
                                    tmpBets.unshift(newBet);
                                    tmpBets.pop();
                                }
                            }
                        }
                        console.log('UPDATE2:', currentTime - lastTimeBetsUpdate.current);
                        setBets((prevState) => ({...prevState, data: sortBets(tmpBets)}));
                        lastTimeBetsUpdate.current = currentTime;
                    } else if (!needUpdate) {
                        // console.log(currentTime - lastTimeBetsUpdate.current);
                        // console.log('NO UPDATE,needUpdate:', needUpdate, 'isAnimationSyncDataStartRef.current:', isAnimationSyncDataStartRef.current);
                        // lastTimeBetsUpdate.current = currentTime;
                    }
                }
            }
            timeoutId = setTimeout(checkBuffer, 40);
        }
        checkBuffer();
        return () => {
            if (timeoutId > 0) {
                clearTimeout(timeoutId);
                timeoutId = 0;
            }
        }
    }, [type, bets]);

    useEffect(() => {
        if (type === "All") {
            const topic = "updateDiceBetsHistory";
            const token = generateJWSToken(topic);

            MercureUrl.searchParams.delete("topic");
            MercureUrl.searchParams.append("topic", topic);
            Cookies.set("mercureAuthorization", token, {path: ""});

            const es = new EventSource(MercureUrl, {withCredentials: true});
            es.addEventListener("message", (event) => {
                let dataMercure = JSON.parse(event.data ?? null);
                const isMyBet = userId && dataMercure.userId && userId === dataMercure.userId;
                if (type === 'My' && isMyBet) {
                    if (bufferRef.current?.length > 40) {
                        bufferRef.current.splice(0, bufferRef.current?.length);
                    }
                    bufferRef.current.push(dataMercure);
                } else {
                    bufferRef.current[0] = dataMercure;
                    bufferRef.current.length = 1;
                }
            });
            eventSourceRef.current = es;
        } else {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
                eventSourceRef.current = null;
            }
        }

        return () => {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
                eventSourceRef.current = null;
            }
        };
    }, [type, userId]);


    useEffect(() => {
        fetchBets();
    }, [fetchBets]);

    const listMemo = useMemo(() => (
        <DiceBetsList bets={bets.data}/>
    ), [bets]);

    return (
        <StyledBetHistoryTable>
            <StyledBetHistoryTHead>
                <tr>
                    <th>ID</th>
                    <th>{`${t("time")} / ${t("gamer")}`}</th>
                    <th className={'center'}>{t("currency")}</th>
                    <th className={'center'}>{t("forecast")} / {t("drawnNumber")}</th>
                    <th className={'right'}> {t("bet")}</th>
                    <th className={'right'}>{t("winning")}</th>
                </tr>
            </StyledBetHistoryTHead>
            <StyledBetHistoryTBody>
                {listMemo}
            </StyledBetHistoryTBody>
        </StyledBetHistoryTable>
    );
});

export default DiceBetsHistoryContainer;