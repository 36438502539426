import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { MercureUrl } from "../../../../App";
import { games, TOPICS_LIST } from "../../../../utils/consts";
import { generateJWSToken } from "../../../../utils/mercureAuth";
import ucFirst from "../../../../utils/ucFirst";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import { closableNotification } from "../../notification/ClosableNotification";
import {
  StyledFlexWrapper,
  StyledGameItem,
  StyledGamesItemTitle, StyledHeaderLeftSidebar,
  StyledLeftSidebarGamesWrapper
} from "./styledLeftSidebarGames";
import { generatePath } from "../../../../utils/getLanguage";
import { useBetween } from "use-between";
import BurgerStates from "../../mobileNavigation/BurgerStates";

const LeftSidebarGamesList = ({ setVisible, setMenu }) => {
  const { t } = useTranslation("leftSidebar");
  const [gameOnlineData, setGameOnlineData] = useState(null);

  const {
    handleClearAll
  } = useBetween(BurgerStates);

  const fetchOnlineData = () => {
    axios.get("/api/game-online-count").then(response => {
      if (response.status === 200) {
        setGameOnlineData(response.data);
      }
    }).catch(error => {
      closableNotification(error.response.data.detail, "error");
    });

  };

  const getGameOnlineCount = (game) => {
    if (!gameOnlineData || gameOnlineData?.length === 0) {
      return 0;
    }

    return gameOnlineData.find(item => item.game === ucFirst(game))?.count ?? 0;
  };

  useEffect(() => {
    fetchOnlineData();
  }, []);

  const topic = TOPICS_LIST.ONLINE.GAME;
  const token = generateJWSToken(topic);

  useEffect(() => {
    MercureUrl.searchParams.delete("topic");

    MercureUrl.searchParams.append("topic", topic);

    Cookies.set("mercureAuthorization", token, { path: "" });

    const es = new EventSource(MercureUrl, { withCredentials: true });
    es.addEventListener("message", (data)=>{
      const responseData = JSON.parse(data.data);
      setGameOnlineData(responseData);
    });

    return () => {
      es.close();
    };
  }, []);

  return (
    <StyledLeftSidebarGamesWrapper id="games">
      <StyledHeaderLeftSidebar>
        <h3>{t("games")}</h3>
        <span
          className="cancel" onClick={() => {
          setVisible(false);
          setMenu("");
        }}
        >✕</span>
      </StyledHeaderLeftSidebar>
      <StyledFlexWrapper>
        <StyledGameItem
          game="balls"
          as={NavLink}
          to={generatePath("/balls")}
          onClick={() => {
            setVisible(false);
            setMenu("");
            localStorage.setItem("gamePage", "/balls");
            handleClearAll();
          }}
        >
          <StyledGamesItemTitle className="game-name" top="26" left="120">
            {t("balls")}
          </StyledGamesItemTitle>
          <span>{getGameOnlineCount(games.balls)} {t("users")}</span>
        </StyledGameItem>
        <StyledGameItem
          game="dice"
          as={NavLink}
          to={generatePath("/")}
          onClick={() => {
            setVisible(false);
            setMenu("");
            localStorage.setItem("gamePage", "/");
            handleClearAll();
          }}
        >
          <StyledGamesItemTitle className="game-name" top="26" left="-3">
            {t("dice")}
          </StyledGamesItemTitle>
          <span>{getGameOnlineCount(games.dice)} {t("users")}</span>
        </StyledGameItem>
        <StyledGameItem
          game="roulette"
          as={NavLink}
          to={generatePath("/roulette")}
          onClick={() => {
            setVisible(false);
            setMenu("");
            localStorage.setItem("gamePage", "/roulette");
            handleClearAll();
          }}
        >
          <StyledGamesItemTitle className="game-name" top="26" left="55" letterSpacing="2">
            {t("roulette")}
          </StyledGamesItemTitle>
          <span>{getGameOnlineCount(games.roulette)} {t("users")}</span>
        </StyledGameItem>
      </StyledFlexWrapper>
    </StyledLeftSidebarGamesWrapper>
  );
};

export default LeftSidebarGamesList;