import axios from "axios";

import {useBetween} from "use-between";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import ProfileActionsStates from "../../ProfileActionsStates";
import Dialog from "rc-dialog";
import {closableNotification} from "../../../elements/notification/ClosableNotification";

import {StyledModalLoader} from "../../../elements/payment/styledPaymentInvoice";
import {StyledItemTable, StyledItemTitle, StyledItemValue, StyledWrapperTable} from "./styledUserInfoModal";
import {responseStatus} from "../../../../utils/consts";

import {AppContext} from "../../../../App";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";

import loadingGif from "../../../../assets/images/loading.gif";

function UserInfoModal({activeNickname}) {
    const {t} = useTranslation("siteOptions");

    const [userInfo, setUserInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const {visibleUserInfoModal, setVisibleUserInfoModal} = useBetween(ProfileActionsStates);
    const {authenticated} = useContext(AppContext);

    const getUserInfo = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_MODERATOR_LINK}/users/get-user-info/${activeNickname}`, userAuthenticationConfig()).then(response => {
            if (response?.status === responseStatus.HTTP_OK) {
                setUserInfo(response?.data);
                setIsLoading(false);
            }
        }).catch(error => {
            closableNotification(error?.response?.data?.message, "error");
            setIsLoading(false);
            setVisibleUserInfoModal(false);
        });
    };

    function formatDate(isoString) {
        const date = new Date(isoString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    useEffect(() => {
        if (!visibleUserInfoModal) return;
        getUserInfo();
    }, [visibleUserInfoModal]);

    return (
        <Dialog
            visible={authenticated && visibleUserInfoModal}
            wrapClassName="default-modal-wrapper"
            onClose={() => setVisibleUserInfoModal(false)}
            animation="zoom"
            maskAnimation="fade"
            title={t("playerInfoTitleModal", {nickname: activeNickname})}
            forceRender={false}
            className="default-modal user-info-modal"
            style={{margin: "auto"}}
        >
            <StyledWrapperTable>
                {isLoading ? <StyledModalLoader style={{height: "307.97px", minHeight: "auto"}}><img src={loadingGif}
                                                                                                     alt={"loading..."}/></StyledModalLoader> :
                    <>
                        <StyledItemTable>
                            <StyledItemTitle>{t("regUserIpTitle")}</StyledItemTitle>
                            <StyledItemValue>{userInfo?.registerIp}</StyledItemValue>
                        </StyledItemTable>
                        <StyledItemTable>
                            <StyledItemTitle>{t("countUserAccountByRegIpTitle")}</StyledItemTitle>
                            <StyledItemValue>{userInfo?.accountCountRegIp}</StyledItemValue>
                        </StyledItemTable>
                        <StyledItemTable>
                            <StyledItemTitle>{t("currentUserIpTitle")}</StyledItemTitle>
                            <StyledItemValue>{userInfo?.currentIp}</StyledItemValue>
                        </StyledItemTable>
                        <StyledItemTable>
                            <StyledItemTitle>{t("countUserAccountByCurrentIpTitle")}</StyledItemTitle>
                            <StyledItemValue>{userInfo?.accountCountCurrentIp}</StyledItemValue>
                        </StyledItemTable>
                        <StyledItemTable>
                            <StyledItemTitle>{t("dateRegAccountTitle")}</StyledItemTitle>
                            <StyledItemValue>{formatDate(userInfo?.dateOfRegistration)}</StyledItemValue>
                        </StyledItemTable>
                    </>
                }
            </StyledWrapperTable>
        </Dialog>
    )
}

export default UserInfoModal;