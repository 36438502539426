import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Moment from "react-moment";
import Spinner from "../../../elements/spinner/Spinner";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {lang} from "../../../../utils/getLanguage";
import rain from "../../../../assets/images/account/maidrain-icon.svg";
import {StyledActivityTable} from "./styledClientDetails";
import {StyledPaymentMethodsIcons} from "../../../styles/StyledPaymentMethodsIcons";
import http from "../../../../http";
import {StyledBlockContent} from "../../../../pages/banners/styledBannersPage";

const ClientDetailsActivityContainer = ({match, setNotification, notification}) => {
    const [userActivity, setUserActivity] = useState(null);
    const {t} = useTranslation("siteOptions");
    const {t: gT} = useTranslation("games");

    useEffect(() => {
        const abortController = new AbortController();
        http.get(
            "/api/activity-histories/" + match.params.nickname,
            {...userAuthenticationConfig(false), signal: abortController.signal}
        ).then(response => {
            if (response.status === 200) {
                const sortedActivity = response.data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                setUserActivity(sortedActivity.reverse());
            }
        }).catch(error => {
            setNotification({...notification, visible: true, type: "error", message: error.response?.data?.message});
        });
        return () => {
            abortController.abort();
        }
    }, []);

    if (!userActivity) {
        return <Spinner display="block" size="35px"/>
    }

    if (!userActivity || userActivity?.length === 0) {
        return <StyledBlockContent className={'uppercase'}>{gT('nothingFound')}</StyledBlockContent>;
    }

    function translateResponseTitle(title) {
        const prefixRainRu = "Пустил дождь в ";
        const regexRainRu = new RegExp(`^${prefixRainRu}(\\w+) комнате`);

        const prefixTipEu = "Sent tip to ";
        const regexTipEu = new RegExp(`^${prefixTipEu}(\\w+) in (\\w+) room`);

        const regexSendTip = new RegExp(`^${prefixTipEu}(\\w+)`);

        if (title.match(regexRainRu)) {
            return `${t('rainPartOne')} ${title.match(regexRainRu)[1]} ${t('sroom')}`
        } else if (title.match(regexTipEu)) {
            return `${t('tipPartOne')} ${title.match(regexTipEu)[1]} ${t('in')} ${title.match(regexTipEu)[2]} ${t('sroom')}`
        } else if (title.match(regexSendTip)) {
            return `${t('tipPartOne')} ${title.match(regexSendTip)[1]}`;
        }

        return title;
    }

    return (
        <StyledActivityTable>
            {userActivity && userActivity.map((value, key) => {
                const splitTitle = value.action.split('-');
                const currency = splitTitle[1].split(' ');

                return (
                    <li key={key}>
                        <div className="activity-list_icon">
                            <img src={rain} alt="rain" width={30}/>
                        </div>
                        <div className="activity-list_title">
                            <p>{translateResponseTitle(splitTitle[0])}</p>
                            <Moment locale={lang()} fromNow>{new Date(value.createdAt)}</Moment>
                        </div>
                        <div className="activity-list__sum">
                            <StyledPaymentMethodsIcons className={`payment-method-${currency[2]}`}/>
                            <p>{new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 8,
                                maximumFractionDigits: 8
                            }).format(parseFloat(currency[1]))}</p>
                        </div>
                    </li>
                );
            })}
        </StyledActivityTable>
    );
};

export default ClientDetailsActivityContainer;