import axios from "axios";

import {useBetween} from "use-between";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import Dialog from "rc-dialog";
import {closableNotification} from "../../../elements/notification/ClosableNotification";
import {NavLink} from "react-router-dom";
import {generatePath} from "../../../../utils/getLanguage";
import LoadButton from "../../../elements/spinner/ButtonSpinner";

import ProfileActionsStates from "../../ProfileActionsStates";
import {AppContext} from "../../../../App";

import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {responseStatus} from "../../../../utils/consts";

import {StyledModalLoader} from "../../../elements/payment/styledPaymentInvoice";

import {
    EmptyTable,
    StyledBanListTable, StyledBanListTableBody,
    StyledBanListTableHead,
    StyledBanListTableItem,
    StyledBanListTableRow, StyledWrapperTable
} from "./styledUserBanListModal";
import {StyledButton} from "../../../styles/styledButton";

import loadingGif from "../../../../assets/images/loading.gif";
import userIcon from "../../../../assets/images/user_icon_dark.svg";

function UserBanListModal({activeNickname}) {
    const {t} = useTranslation("siteOptions");

    const [userBanListData, setUserBanListData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const {
        visibleUserBanListModal,
        setVisibleUserBanListModal,
        setVisibleBanUserModal
    } = useBetween(ProfileActionsStates);

    const {authenticated} = useContext(AppContext);

    useEffect(() => {
        if (!visibleUserBanListModal) return;
        getUserBanListData();
    }, [visibleUserBanListModal]);

    const getUserBanListData = () => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_MODERATOR_LINK}/bans/get-user-ban-list/${activeNickname}`, userAuthenticationConfig()).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                setUserBanListData(response.data);
                setIsLoading(false);
            }
        }).catch(error => {
            closableNotification(error.response.data.message, "error");
            setIsLoading(false);
            setVisibleUserBanListModal(false);
        });
    };

    function formatDate(isoString) {
        const date = new Date(isoString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const openBanUserModal = () => {
        setVisibleBanUserModal(true);
        setVisibleUserBanListModal(false);
    }

    return (
        <>
            <Dialog
                visible={authenticated && visibleUserBanListModal}
                wrapClassName="default-modal-wrapper"
                onClose={() => setVisibleUserBanListModal(false)}
                animation="zoom"
                maskAnimation="fade"
                title={`${t("userBans")} ${activeNickname}`}
                forceRender={false}
                className="default-modal ban-list-modal"
                style={{margin: "auto"}}
                footer={isLoading ? <LoadButton
                    text={t("banAction")}
                    color="neutral"
                    type="submit"
                    style={{width: '100%'}}
                /> : <StyledButton
                    onClick={openBanUserModal}
                    color="neutral"
                    type="submit"
                    width="100"
                >
                    {t("banAction")}
                </StyledButton>}
            >
                {isLoading || !userBanListData ?
                    <StyledModalLoader style={{height: "200px", minHeight: "auto", margin: "auto"}}><img
                        src={loadingGif} alt={"loading..."}/></StyledModalLoader> :
                    userBanListData?.length === 0 ? <EmptyTable>{t("noBans")}</EmptyTable> :
                        <StyledWrapperTable>
                            <StyledBanListTable>
                                <StyledBanListTableHead>
                                    <StyledBanListTableRow>
                                        <StyledBanListTableItem>{t("ipAddress")}</StyledBanListTableItem>
                                        <StyledBanListTableItem>{t("user")}</StyledBanListTableItem>
                                        <StyledBanListTableItem>{t("cause")}</StyledBanListTableItem>
                                        <StyledBanListTableItem>{t("date")}/{t("time")}</StyledBanListTableItem>
                                    </StyledBanListTableRow>
                                </StyledBanListTableHead>
                                <StyledBanListTableBody>
                                    {userBanListData?.map((banData, key) => (
                                        <StyledBanListTableRow key={key}>
                                            <StyledBanListTableItem>{banData?.sessionIp}</StyledBanListTableItem>
                                            <StyledBanListTableItem>
                                                <NavLink
                                                    to={generatePath(`/account/${banData?.user}`)}
                                                    onClick={() => setVisibleUserBanListModal(false)}
                                                    className="wrapper-link"
                                                >
                                                    <img
                                                        src={userIcon}
                                                        alt="user"
                                                    />
                                                    {banData?.user}
                                                </NavLink>
                                            </StyledBanListTableItem>
                                            <StyledBanListTableItem style={{maxWidth: "140px"}}>{banData?.cause}</StyledBanListTableItem>
                                            <StyledBanListTableItem>{formatDate(banData?.banned_at)}</StyledBanListTableItem>
                                        </StyledBanListTableRow>
                                    ))}
                                </StyledBanListTableBody>
                            </StyledBanListTable>
                        </StyledWrapperTable>
                }
            </Dialog>
        </>
    )
}

export default UserBanListModal;