import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { StyledBlockContent } from "../../banners/styledBannersPage";
import { StyledPaymentMethodsIcons } from "../../../components/styles/StyledPaymentMethodsIcons";
import ChoosePlanModal from "../modals/ChoosePlanModal";
import { StyledChooseStakingPeriod } from "../styledStakingPage";

export const ChoosePlanTab = ({ stakingData, setStakingData, searchAsset, formatAmount, active = false }) => {
  const { t } = useTranslation('staking');
  const { t: gT } = useTranslation("games");

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleChooseItClick = (index) => {
    setSelectedIndex(index);
  }

  const fetchData = async () => {
    return [];
  }

  const handlePeriodChange = useCallback((index, period) => {
    setStakingData(old => {
      const plans = old.plans;
      if (plans[index]) {
        plans[index].defaultPeriod = period;
        return { ...old, plans };
      }
      return old;
    });
  }, [setStakingData]);

  useEffect(() => {
    if (active) {
      setStakingData(old => ({ ...old, loading: true }));
      fetchData().then(plans => {
        const newData = { plans };
        if (active) {
          newData.loading = false;
        }
        setStakingData(old => ({ ...old, ...newData }))
      }).catch(error => {
        if (active) {
          setStakingData(old => ({ ...old, loading: false }));
        }
      })
    }
  }, [active, setStakingData]);

  const getSelectedPeriod = (plan) => {
    return plan.periods[plan.defaultPeriod];
  }

  const content = useMemo(() => {
    if (stakingData.loading) {
      return <StyledBlockContent className={'uppercase'}>{gT('loading')}</StyledBlockContent>
    }

    if (!stakingData.loading && (!stakingData.plans || stakingData.plans?.length === 0)) {
      return <StyledBlockContent className={'uppercase'}>{gT('nothingFound')}</StyledBlockContent>
    }

    return <div>
      <table className={'staking-table'}>
        <thead>
        <tr>
          <th>{t('assets')}</th>
          <th>{t('period')}</th>
          <th>{t('percentage')}</th>
          <th>{t('depositRange')}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {stakingData.plans
          ?.filter((item) => (
            item.currency.asset.toLowerCase().includes(searchAsset.toLowerCase()) ||
            item.currency.name.toLowerCase().includes(searchAsset.toLowerCase())
          ))
          ?.map((plan, index) => (
            <tr key={index}>
              <td>
                <div className={'assets-col'}>
                  <StyledPaymentMethodsIcons
                    width="36"
                    height="36"
                    className={`payment-method-big-${plan.currency.asset}`}
                  />
                  <div>
                    <span>{plan.currency.asset}</span>
                    <span>{plan.currency.name}</span>
                  </div>
                </div>
              </td>
              <td>
                <StyledChooseStakingPeriod>
                  {plan.periods?.map((period, key) => (
                    <div
                      className={key === plan.defaultPeriod ? 'active' : undefined}
                      onClick={() => handlePeriodChange(index, key)}
                      key={key}
                    >
                      <span>{period.days}</span>
                      <span>{t('days')}</span>
                    </div>
                  ))}
                </StyledChooseStakingPeriod>
              </td>
              <td className={'percentage'}>{getSelectedPeriod(plan).percentage}%</td>
              <td>{`${formatAmount(getSelectedPeriod(plan).depositRange.min)} - ${formatAmount(getSelectedPeriod(plan).depositRange.max)} ${plan.currency.asset}`}</td>
              <td>
                <button
                  className={'choose-it-btn'}
                  onClick={() => handleChooseItClick(index)}
                >
                  {t('chooseIt')}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  }, [gT, handlePeriodChange, stakingData, t, searchAsset]);

  return <div className={'staking-tab-content' + (active ? ' active' : '')}>
    {content}
    {active && stakingData.plans && stakingData.plans?.length > 0 ?
      <ChoosePlanModal
        plans={stakingData.plans}
        handlePeriodChange={handlePeriodChange}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        formatAmount={formatAmount}
      /> :
      null
    }
  </div>
}

export default ChoosePlanTab;