import { StyledPaymentMethodsIcons } from "../../../components/styles/StyledPaymentMethodsIcons";
import arrowImage from "../../../assets/images/arrow-down.svg";
import React, { useEffect, useState } from "react";
import { StyledActivePlanInfoRow } from "../styledStakingPage";

export const ActivePlanRow = ({ plan, t, timers, index, formatAmount }) => {

  const [isModile, setIsMobile] = useState(false);

  const [collapsed, setCollapsed] = useState(false);
  const remainingTime = ({ days, hours, minutes }, autoWidth) => {
    return <div className={'staking-timer' + (autoWidth ? ' auto' : '')}>
      <div><span>{days}</span><span>{t('days')}</span></div>
      <span>:</span>
      <div><span>{hours}</span><span>{t('hours')}</span></div>
      <span>:</span>
      <div><span>{minutes}</span><span>{t('minutes')}</span></div>
    </div>;
  }

  useEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth <= 790);
    }

    updateSize();

    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    }
  }, []);

  return <>
    <tr onClick={() => setCollapsed(old => !old)}>
      <td>
        <div className={'assets-col'}>
          <StyledPaymentMethodsIcons
            className={`payment-method-big-${plan.currency.asset}`}
          />
          <div>
            <span>{plan.currency.asset}</span>
            <span>{plan.currency.name}</span>
          </div>
        </div>
      </td>
      <td className={'two-rows'}>
        <div>
          <span>{plan.percentage}%</span>
          <span>{t('percentage')}</span>
        </div>
      </td>
      <td className={'due-to-col non-mobile'}>
        {timers?.length > 0 && timers[index] ? remainingTime(timers[index]) : null}
      </td>
      <td className={'two-rows'}>
        <div>
          <span>{formatAmount(plan.deposit)} {plan.currency.asset}</span>
          <span>{t('sum')}</span>
        </div>
      </td>
      <td className={'non-mobile'}>
        <button className={'collapse-btn' + (collapsed ? ' collapsed' : '')}>
          <img src={arrowImage} alt={'arrow'}/>
        </button>
      </td>
    </tr>
    <StyledActivePlanInfoRow isCollapsed={collapsed}>
      <td colSpan={isModile ? 1 : 2}>
        <div>
          <div className={'two-rows'}>
            <span>30 days</span>
            <span>{t('period')}</span>
          </div>
        </div>
      </td>
      <td colSpan={1} className={'due-to-date'}>
        <div>
          <div className={'two-rows'}>
            <span>28.07.2023</span>
            <span>Due To</span>
          </div>
        </div>
      </td>
      <td colSpan={isModile ? 1 : 2}>
        <div>
          <div className={'two-rows'} style={{ marginLeft: 5 }}>
            <span style={{ color: 'rgb(46, 172, 91)' }}>7.52734332 USDC</span>
            <span>=7.555 USDT</span>
          </div>
        </div>
      </td>
    </StyledActivePlanInfoRow>
    <StyledActivePlanInfoRow isCollapsed={collapsed}>
      <td colSpan={4} className={'mobile'}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
            <span style={{ textAlign: 'start', fontSize: 11, color: 'grey' }}>Remaining time</span>
            {timers?.length > 0 && timers[index] ? remainingTime(timers[index], true) : null}
          </div>
        </div>
      </td>
    </StyledActivePlanInfoRow>
  </>
}

export default ActivePlanRow;