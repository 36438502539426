import styled, { css } from "styled-components";

export const StyledWrapperTable = styled.div`

`

export const StyledModeratorsTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`

export const StyledModeratorsTableHead = styled.thead`
    font-size: 14px;
    color: ${({theme}) => theme.moderatorsListModal.headTextColor};
    text-transform: uppercase;

    @media screen and (max-width: 415px) {
        font-size: 12px;
    }
`

export const StyledModeratorsTableBody = styled.tbody`
    & tr:nth-child(2n) {
        background-color: ${({theme}) => theme.moderatorsListModal.backgroundItemTableColor};
    }
`

export const StyledModeratorsTableRow = styled.tr`
    height: 40px;
`

export const StyledModeratorsTableItem = styled.td`
    text-align: center;
    border-spacing: 0 !important;
    padding: 0;
    font-size: 14px;
    font-weight: 400;

    &:first-child {
        padding-left: 14px;
        text-align: left;
    }

    &:last-child {
        padding-right: 14px;
        text-align: right;
    }

    .wrapper-link {
        cursor: pointer;
        border-bottom: 1px ${({ theme }) => theme.mainTextColor} dashed;
        transition: all 0.3s ease-out;
        display: inline;
        color: #2eab5b;

        &.user-tag {
            color: ${({ theme }) => theme.secondTextColor};
        }

        &:hover,  &.user-tag:hover{
            color: #ffffff;
            border-bottom: 1px #ffffff dashed;
        }

        img {
            height: 11px;
            margin-right: 5px;
        }
    }
    
    span {
        margin: auto;
    }

    @media screen and (max-width: 415px) {
        font-size: 12px;
    }

    @media screen and (max-width: 375px) {
        span {
            display: block;
            max-width: 80px;
        }
    }
`

export const StyledModeratorsOnlineText = styled.span`
    ${({ isOnline }) => !isOnline && css`
        color: ${({theme}) => theme.moderatorsListModal.offlineTextColor};
  `};
`