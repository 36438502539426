import React, {useCallback, useMemo} from "react";
import { useTranslation } from "react-i18next";
import * as SessStorage from '../../../utils/sessionStorage';
import { StyledBetButton, StyledWrapperButtons } from "./styledBetAction";
import {formatNumber} from "../../../utils/formatNumber";
import {GAMES} from "../../games/Constants";
import {useAutoBetsStatesBalls, useAutoBetsStatesDice, useAutoBetsStatesRoulette} from "../autoBets/AutoBetsStates";
import {getCurrentGame} from "../../../utils/online";

const BetAction = React.memo(({
  isPlay,
  value,
  setValue,
  handleChange,
  min,
  max,
  isRoulette = false,
  submitData = null,
  isRouletteModal = false
}) => {
  const { t } = useTranslation("games");

  const diceAutoBetsData = useAutoBetsStatesDice();
  const ballsAutoBetsData = useAutoBetsStatesBalls();
  const rouletteAutoBetsData = useAutoBetsStatesRoulette();
  const { autoModeIsStart } = useMemo(
      () => {
        const data = {
          [GAMES.DICE]: diceAutoBetsData,
          [GAMES.BALLS]: ballsAutoBetsData,
          [GAMES.ROULETTE]: rouletteAutoBetsData,
        }
        return data[getCurrentGame()];
      },
      [getCurrentGame(), diceAutoBetsData, ballsAutoBetsData, rouletteAutoBetsData],
  );

  const handleMulti = useCallback(() => {
    if (isPlay || autoModeIsStart) {
      return;
    }
    if (value * 2 <= max && value > 0 && !isRoulette) {
        const stringValue = (value * 2).toFixed(8).toString();
        setValue(stringValue);

        if (isRouletteModal) return;

        handleChange && handleChange({
          name: "bet",
          value: stringValue
        }, true);
        return;
      }
    if (value >= 0.00000001 && isRoulette && !isRouletteModal) {
      let allBet = 0;
      let newObjectBets = {};
      const suggestedNumbers = submitData.suggestedNumbers;
      Object.entries(suggestedNumbers)?.map((array) => {
        newObjectBets[array[0]] = array[1] * 2;
        allBet += array[1] * 2;
      });

      setValue(allBet.toString());
      handleChange({
        suggestedNumbers: newObjectBets,
        bet: allBet.toString()
      }, true);
    }
  }, [isRoulette, handleChange, max, setValue, submitData, value, isPlay, autoModeIsStart]);

  const handleDivision = useCallback(() => {
    if (isPlay || autoModeIsStart) {
      return;
    }
    if (value > 0.00000001 && isRoulette && !isRouletteModal) {
      let allBet = 0;
      let newObjectBets = {};
      const suggestedNumbers = submitData.suggestedNumbers;
      Object.entries(suggestedNumbers)?.map((array) => {
        newObjectBets[array[0]] = array[1] / 2;
        allBet += array[1] / 2;
      });

      setValue(allBet.toString());
      handleChange({
        suggestedNumbers: newObjectBets,
        bet: allBet.toString()
      }, true);
    }
    if (value / 2 >= min && !isRoulette) {
        const stringValue = (value / 2).toFixed(8).toString();
        setValue(stringValue);

        if (isRouletteModal) return;

        handleChange && handleChange({
          name: "bet",
          value: stringValue
        }, true);
      }
  }, [isRoulette, handleChange, min, setValue, submitData, value, isPlay, autoModeIsStart])

  const setMin = useCallback(() => {
    if (isPlay || autoModeIsStart) {
      return;
    }
    const stringValue = formatNumber(min).toString();

    setValue(stringValue);
    handleChange && handleChange({
      name: "bet",
      value: stringValue
    }, true);
  }, [min, handleChange, setValue, isPlay, autoModeIsStart]);

  const setMax = useCallback(() => {
    if (isPlay || autoModeIsStart) {
      return;
    }
    if (window.confirm(t("confirmMaxBet"))) {
      const stringValue = formatNumber(max).toString();

      setValue(stringValue);
      handleChange && handleChange({
        name: "bet",
        value: stringValue
      }, true);
    }
  }, [max, handleChange, setValue, t, isPlay, autoModeIsStart]);

  const setRepeat = useCallback(() => {
    if (isPlay || autoModeIsStart) {
      return;
    }
    // const suggestedNumbers = submitData.suggestedNumbers;
    // const doubledBet = Object.values(suggestedNumbers)[Object.values(suggestedNumbers)?.length - 1] * 2;
    // const allSuggestedNumbers = Object.keys(suggestedNumbers)[Object.keys(suggestedNumbers)?.length - 1];
    let prevBet = SessStorage.getItem("RoulettePrevBet");
    if (prevBet && prevBet !== "undefined" && isRouletteModal) return setValue(prevBet.bet.toFixed(8).toString());
    if (prevBet) {
      handleChange({
        suggestedNumbers: prevBet.suggestedNumbers, // {...suggestedNumbers, [allSuggestedNumbers]: doubledBet},
        bet: prevBet.bet.toFixed(8).toString(),
      }, true);
    }
  }, [handleChange, isPlay, autoModeIsStart])

  const setClear = useCallback(() => {
    if (isPlay || autoModeIsStart) {
      return;
    }
    if (isRouletteModal) return setValue("0.00000001");
    const {suggestedNumbers} = submitData;
    if (suggestedNumbers) {
      let sum = 0;
      for (let bet of Object.values(suggestedNumbers)) {
        sum += bet;
      }
      if (sum > 0) {
        SessStorage.setItem("RoulettePrevBet", { suggestedNumbers,  bet: sum });
      }
    }

    handleChange({
      suggestedNumbers: {},
      bet: "0"
    }, true);
  }, [handleChange, submitData, isPlay, autoModeIsStart]);

  if (isRouletteModal) {
    return (
        <StyledWrapperButtons>
          <StyledBetButton isPlay={isPlay || autoModeIsStart} onClick={handleMulti}>x2</StyledBetButton>
          <StyledBetButton isPlay={isPlay || autoModeIsStart} onClick={handleDivision}>1/2</StyledBetButton>
          <StyledBetButton isPlay={isPlay || autoModeIsStart} onClick={setRepeat}>r</StyledBetButton>
          <StyledBetButton isPlay={isPlay || autoModeIsStart} onClick={setClear}>c</StyledBetButton>
        </StyledWrapperButtons>
    );
  }

  if (isRoulette) {
    return (
      <StyledWrapperButtons>
        <StyledBetButton isPlay={isPlay || autoModeIsStart} onClick={handleMulti}>x2</StyledBetButton>
        <StyledBetButton isPlay={isPlay || autoModeIsStart} onClick={handleDivision}>1/2</StyledBetButton>
        <StyledBetButton isPlay={isPlay || autoModeIsStart} onClick={setRepeat}>r</StyledBetButton>
        <StyledBetButton isPlay={isPlay || autoModeIsStart} onClick={setClear}>c</StyledBetButton>
      </StyledWrapperButtons>
    );
  }

  return (
    <StyledWrapperButtons>
      <StyledBetButton
        onClick={handleMulti}
        isPlay={isPlay || autoModeIsStart}
      >
        x2
      </StyledBetButton>
      <StyledBetButton
        onClick={handleDivision}
        isPlay={isPlay || autoModeIsStart}
      >
        1/2
      </StyledBetButton>
      <StyledBetButton
        onClick={setMin}
        isPlay={isPlay || autoModeIsStart}
      >
        min
      </StyledBetButton>
      <StyledBetButton
        onClick={setMax}
        isPlay={isPlay || autoModeIsStart}
      >
        max
      </StyledBetButton>
    </StyledWrapperButtons>
  );
});

export default BetAction;
