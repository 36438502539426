import styled, { css, keyframes } from "styled-components";

export const StyledMessagesContainer = styled.div`
  min-height: 50px;
  height: 100%;
  padding: 15px 20px 15px 20px;
  overflow-y: auto;
  position: relative;
  transition: all 0.3s ease-in-out;
  overflow-x: hidden;

  .custom-hint {
    position: absolute;
    width: 0;
    transform: translate(30px, -18px);
    z-index: 10000;
  }
`;

export const StyledChatMessages = styled.div`
  padding-top: ${({ glued }) => (glued ? '1px' : '15px')};
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align ? `flex-${align}` : 'space-between')};
  width: 100%;

  .wrapper-relative {
    position: relative;
  }
    
  .chat-nickname {
    font-size: 16px;
    font-weight: 400;
    font-family: "Segoe UI Classic", sans-serif;
  }
`;

export const StyledMessageBody = styled.div`
  padding: 5px 10px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colorsChat.backgroundColor};
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  flex-wrap: wrap;
  font-size: 14px;
  width: calc(100% - 55px);
  position: relative;
  word-break: break-word;
  font-family: "Segoe UI Classic", sans-serif;
    
  b {
      font-family: "Segoe UI Bold", sans-serif !important;
      font-weight: 900;
  }
    
  &.bot {
    width: 100%;
    background-color: ${({ theme }) => theme.colorsChat.backgroundColorBot};
  }
  
  & .bot-system-rain, & .bot-system-Guard {
      padding: 0 20px;
      margin-top: 20px;
      margin-bottom: 14px;
  }  
    
  .social-link {
    border-bottom: ${({ theme }) => theme.colorsChat.colorMessage} dashed 1px;
    color: ${({ theme }) => theme.colorsChat.tag.color};
    transition: 0.3s ease-out;
  }
    
  .social-link:hover {
    color: ${({ theme }) => theme.colorsChat.tag.hoverColor};
    border-bottom: ${({ theme }) => theme.colorsChat.hoverColor} dashed 1px;
    transition: 0.3s ease-out;
  }
    
  .red-rules-text {
      color: #ab2d40;
  }  
    
    &:before {
    ${({ alignArrow }) => (alignArrow ? 'content: "";' : null)};
    display: block;
    position: absolute;
    top: 50%;
    ${({ alignArrow }) => alignArrow}: 100%;
    margin-top: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${({ alignArrow }) => {
      if (alignArrow === 'right') {
        return '7px 7px 7px 0';
      } else if (alignArrow === 'left') {
        return '7px 0 7px 7px';
      } else {
        return '0';
      }
    }};
    border-color: transparent ${({ theme }) => theme.colorsChat.backgroundColor};
  }
`;

export const StyledMessageHeader = styled.div`
  position: relative;
  margin-bottom: 6px;
  color: ${({ theme }) => theme.colorsChat.colorTime};

  &:first-child {
    color: ${({ theme }) => theme.colorsChat.colorUser};
    cursor: pointer;
    user-select: none;
  }

  & > .bot-title {
    background-color: ${({ theme }) => theme.colorsChat.bgColorBotTitle};
    color: #ffffff;
    width: 40px;
    text-align: center;
    padding: 2px 4px;
    font-size: 11px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }

  & > .bot-name {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 6px;
    color: ${({ theme }) => theme.colorsChat.tag.botName};
    margin-left: 7px;
  }

  & > .width-reducer {
    position: absolute;
    top: 0;
    bottom: 6px;
    right: 0;
    left: 0;
    max-width: 85px;
  }

  @media screen and (max-width: 425px) {
    max-width: 140px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media screen and (max-width: 315px) {
    max-width: 100px;
  }
`;

export const StyledMessageTitle = styled.div`
  width: 100%;
  font-weight: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colorsChat.colorMessage};

  & div {
    display: inline;
  }

  & img {
    margin-bottom: -6px;
  }
`;

const shimmer = keyframes`
  100% {-webkit-mask-position: left}
`

export const StyledMessageAvatar = styled.div`
    position: relative !important;
    width: auto !important;

    & .rating {
        width: 19px !important;
        height: 19px !important;
        color: ${({theme}) => theme.subTitleColor};
        font-size: 10px;
        font-weight: 500;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: absolute !important;
        top: -4px;
        right: -4px;
        border: ${({theme}) => theme.accountFriends.item.level.border};
        background-color: ${({theme}) => theme.accountActivityItemBg};
        border-radius: 100%;
        z-index: 10;
        cursor: pointer;
        line-height: normal;
    }

    .rating.level10, .rating.level11, .rating.level12, .rating.level13, .rating.level14, .rating.level15, .rating.level16, .rating.level17, .rating.level18, .rating.level19 {
        border-color: #e2a61f !important;
    }

    .rating.level20, .rating.level21, .rating.level22, .rating.level23, .rating.level24, .rating.level25, .rating.level26, .rating.level27, .rating.level28, .rating.level29 {
        border-color: #1f5be2 !important;
    }

    .rating.level30, .rating.level31, .rating.level32, .rating.level33, .rating.level34, .rating.level35, .rating.level36, .rating.level37, .rating.level38, .rating.level39 {
        border-color: #1fe244 !important;
    }

    .rating.level40, .rating.level41, .rating.level42, .rating.level43, .rating.level44, .rating.level45, .rating.level46, .rating.level47, .rating.level48, .rating.level49 {
        border-color: #e21fbc !important;
    }

    .rating.level50, .rating.level51, .rating.level52, .rating.level53, .rating.level54, .rating.level55, .rating.level56, .rating.level57, .rating.level58, .rating.level59 {
        border-color: #e2441f !important;
    }

    .rating.level60, .rating.level61, .rating.level62, .rating.level63, .rating.level64, .rating.level65, .rating.level66, .rating.level67, .rating.level68, .rating.level69 {
        border-color: #e21f5b !important;
    }

    .rating.level70, .rating.level71, .rating.level72, .rating.level73, .rating.level74, .rating.level75, .rating.level76, .rating.level77, .rating.level78, .rating.level79 {
        border-color: #e21f3a !important;
    }

    .rating.level80, .rating.level81, .rating.level82, .rating.level83, .rating.level84, .rating.level85, .rating.level86, .rating.level87, .rating.level88, .rating.level89 {
        border-color: #e21f3a !important;
    }

    .rating.level90, .rating.level91, .rating.level92, .rating.level93, .rating.level94, .rating.level95, .rating.level96, .rating.level97, .rating.level98, .rating.level99 {
        border-color: #e21f3a !important;
    }

    & .user-group {
        width: 34px;
        font-size: 9px;
        bottom: -3px;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        text-shadow: 1px 2px 3px rgba(0, 0, 0, 1);
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, 0px);
        font-family: "Segoe UI Classic";
        line-height: normal;
        font-weight: 600;
    }
    
    & .user-group.mod {
        background: #2eab5b;
        color: #ffffff !important;
    }

    & .user-group.admin {
        background: #ab392e;
        color: #ffffff !important;
    }

    ${({loading, theme}) => loading && css`
        & > .user-image_shimmer {
            background-color: #ffffff22;
            display: inline-block;
            -webkit-mask: -webkit-linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
            background-repeat: no-repeat;
            width: 41px;
            height: 41px;
            border-radius: 50%;
            cursor: pointer;
            animation: ${shimmer} 1.5s infinite linear;
        }
    `};

    & > img {
        display: block;
        width: 41px;
        height: 41px;
        border-radius: 50%;
        cursor: pointer;
        object-fit: cover;
        -o-object-fit: cover;
    }
`;

export const StyledNewMessagesButton = styled.button`
    position: absolute;
    bottom: 176px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0);
    color: ${({theme}) => theme.colorsChat.colorMessage};
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px 25px;
    border-radius: 6px;
`
