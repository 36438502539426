import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { generatePath } from "../../../utils/getLanguage";
import { useTranslation } from "react-i18next";

import {
  StyledBody,
  StyledHead,
  StyledHeadTitle,
  StyledItem,
  StyledJackpotDialog,
  StyledJackpotWrapper,
  StyledRow,
  StyledStaticTable,
  StyledTableSimple,
  StyledTextBlock,
  StyledTitleBlock
} from "./styledSiteOptions";
import { StyledHistoryLink } from "../../styles/styledHistory";
import { TimestampToAgoFormat } from "../../../utils/timestampToDate";
import {formatNumber} from "../../../utils/formatNumber";
import Big from "big.js";

const SiteOptionsJackpot = ({ visibleJackpot, setVisibleJackpot, jackpot, game, paymentMethod }) => {
  const { t } = useTranslation("siteOptions");

  const [scrolled, setScrolled] = useState(false);

  const onScroll = useCallback(e => {
    setScrolled(e.target.scrollTop > 30);
  }, [setScrolled]);

  useEffect(() => {
    const element = document.querySelector('.default-modal-jackpot .rc-dialog-body');
    if (element) {
      element.addEventListener('scroll', onScroll)
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', onScroll)
      }
    }
  }, [visibleJackpot, onScroll]);

  useEffect(() => {
    if (visibleJackpot) {
      window.history.pushState({modal: true}, '');

      const handlePopState = () => {
        setVisibleJackpot(false);
      };

      window.addEventListener('popstate', handlePopState);
      return () => window.removeEventListener('popstate', handlePopState);
    }
  }, [visibleJackpot, setVisibleJackpot]);

  return (
    <StyledJackpotDialog
      visible={visibleJackpot}
      wrapClassName="default-modal-wrapper"
      onClose={() => setVisibleJackpot(false)}
      animation="zoom"
      maskAnimation="fade"
      title={`${t('jackpotOfGame')} ${t(`${game}`)}`}
      forceRender={false}
      className="default-modal default-modal-jackpot"
      page={game}
    >
      <StyledJackpotWrapper>
        <StyledTitleBlock page={game}>
          <b>{jackpot?.value ? jackpot?.value : ""} USDT</b>
          <span>{t("jackpotAmount")}</span>
        </StyledTitleBlock>
        <StyledTextBlock transparent={!scrolled}>
          <h3>{t("howIsItCalculated")}</h3>
          <p>{t("weGiveAPercentage")}</p>
          <h3>{t("howToWin")}</h3>
          <p>1. {t("youNeedToCatchABet")}</p>
          <p>2. {t("minimumBetAmount")} - {new Big(jackpot?.minBetValue ? jackpot?.minBetValue : 0).toFixed(8)} {paymentMethod?.currency?.asset}</p>
          <p>3. {t("theJackpotIsPaidOut")}</p>
          <p>4. {t("winChan")}</p>
          <p>(*) {t("theMinimumStakeMayChange")}</p>
        </StyledTextBlock>
        <StyledTextBlock paddingBottom="0">
          <h3>{t('recentWinners')}</h3>
          <StyledStaticTable className="jackpot">
            <StyledTableSimple>
              <thead>
              <StyledHead>
                <StyledHeadTitle>
                  {t('time')}
                </StyledHeadTitle>
                <StyledHeadTitle>
                  {t('betOne')}
                </StyledHeadTitle>
                <StyledHeadTitle>
                  {t('player')}
                </StyledHeadTitle>
                <StyledHeadTitle>
                  {t('sum')}
                </StyledHeadTitle>
              </StyledHead>
              </thead>
              <StyledBody>
                {jackpot?.lastWins &&
                  jackpot?.lastWins?.map((item, index) =>
                    <StyledRow key={index}>
                      <StyledItem>
                        {item?.bets?.createdAt ? TimestampToAgoFormat(item?.bets.createdAt) : ""}
                      </StyledItem>
                      <StyledItem>
                        <StyledHistoryLink size="14" bordercolor="true" bold="unset">
                          <div>
                            <NavLink to="/">
                              {item?.bets?.resultNumber ? item?.bets.resultNumber : ""}
                            </NavLink>
                          </div>
                        </StyledHistoryLink>
                      </StyledItem>
                      <StyledItem>
                        <StyledHistoryLink size="14" bordercolor="true" bold="unset" as={NavLink}
                                           to={generatePath(`/account/${item?.bets?.user?.nickname ? item?.bets.user.nickname : ""}`)}>
                          <div>
                            {item?.bets?.user?.nickname ? item?.bets.user.nickname : ""}
                          </div>
                        </StyledHistoryLink>
                      </StyledItem>
                      <StyledItem>
                        {item?.value} USDT
                      </StyledItem>
                    </StyledRow>)
                }
              </StyledBody>
            </StyledTableSimple>
          </StyledStaticTable>
        </StyledTextBlock>
      </StyledJackpotWrapper>
    </StyledJackpotDialog>
  );
};

export default SiteOptionsJackpot;
