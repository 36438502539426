import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import Spinner from "../../../elements/spinner/Spinner";
import {
  StyledCol,
  StyledColHead,
  StyledScrollTable,
  StyledTable,
  StyledTableBody,
  StyledTableHead,
  StyledTableRow
} from "../../../styles/styledTable";
import { StyledPaymentMethodsIcons } from "../../../styles/StyledPaymentMethodsIcons";
import http from "../../../../http";
import { StyledBlockContent } from "../../../../pages/banners/styledBannersPage";
import Big from "big.js";

const ClientDetailsStatisticsContainer = ({ match, setNotification, notification }) => {

  const [userStatisticsByCurrency, setUserStatisticsByCurrency] = useState({ data: null, loading: false });

  const { t } = useTranslation("siteOptions");
  const { t: gT } = useTranslation("games");

  const fetchUserStatisticsByCurrency = () => {
    setUserStatisticsByCurrency({ data: null, loading: true })
    http.get("/api/user-statistics-by-currencies/" + match.params.nickname, userAuthenticationConfig(false)).then(response => {
      if (response.status === 200) {
        setUserStatisticsByCurrency({ data: response.data, loading: false })
      } else {
        setUserStatisticsByCurrency({ data: null, loading: false })
      }
    }).catch(error => {
      setNotification({ ...notification, visible: true, type: "error", message: error.response?.data?.message });
      setUserStatisticsByCurrency({ data: null, loading: false })
    });
  };

  useEffect(() => {
    fetchUserStatisticsByCurrency();
  }, []);

  if (userStatisticsByCurrency.loading) {
    return <Spinner display="block" size="35px"/>
  }

  if (!userStatisticsByCurrency.data || userStatisticsByCurrency.data?.length === 0) {
    return <StyledBlockContent className={'uppercase'}>{gT('nothingFound')}</StyledBlockContent>;
  }

  return (
    <StyledScrollTable>
      <StyledTable size="14" className="account-statistics-table">
        <StyledTableHead
          scroll="auto"
          col="7"
          className="account-statistics-table__head"
        >
          <StyledColHead position="center">
            {t('currency')}
          </StyledColHead>
          <StyledColHead position="center">
            {t('bets')}
          </StyledColHead>
          <StyledColHead position="center">
            {t('winsUpper')}
          </StyledColHead>
          <StyledColHead position="center">
            {t('loses')}
          </StyledColHead>
          <StyledColHead position="center">
            {t('luck')}
          </StyledColHead>
          <StyledColHead position="right">
            {t('sumOfBets')}
          </StyledColHead>
          <StyledColHead position="right">
            {t('profit')}
          </StyledColHead>
        </StyledTableHead>

        {userStatisticsByCurrency.data ?
          <StyledTableBody>
            {userStatisticsByCurrency.data?.map((value, key) => (
              <StyledTableRow
                scroll="auto"
                col="7"
                key={key}
                className="account-statistics-table__row"
              >
                <StyledCol
                  data-title="Валюта"
                  className="account-statistics-table__currency"
                >
                  <StyledPaymentMethodsIcons className={`payment-method-${value.paymentMethod.currency.asset}`}/>
                </StyledCol>
                <StyledCol data-title="Ставок" position="center">
                  {value.countOfBet}
                </StyledCol>
                <StyledCol
                  data-title="Побед"
                  position="center"
                  className="account-statistics-table__green"
                >
                  {value.countOfWins}
                </StyledCol>
                <StyledCol
                  data-title="Неудач"
                  position="center"
                  className="account-statistics-table__red"
                >
                  {value.countOfLose}
                </StyledCol>
                <StyledCol data-title="Везение" position="center" style={{minWidth: '70px'}}>
                  {new Big(value?.percentOfLuck ?? 0).toFixed(2)}
                </StyledCol>
                <StyledCol data-title="Сумма ставок" position="right">
                  {new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(parseFloat(value.sumOfBet).toFixed(2))}
                </StyledCol>
                <StyledCol
                  data-title="Профит"
                  position="right"
                  className={value?.profit ? value.profit < 0 ? "account-statistics-table__red" : "account-statistics-table__green" : ""}
                >
                  {value?.profit ? parseFloat(value.profit).toFixed(8) : t("private")}
                </StyledCol>
              </StyledTableRow>
            ))}
          </StyledTableBody> :
          <StyledBlockContent className={'uppercase'}>{gT('nothingFound')}</StyledBlockContent>
        }
      </StyledTable>
    </StyledScrollTable>
  );
};

export default ClientDetailsStatisticsContainer;