import React from 'react';
import { chatEmoji } from '../leftSidebar/Chat/chatEmoji';

export const tagRegex = /(?:\b|:)sticker:([A-Za-z0-9_]{1,30})(?!\w|:)/gm;

export const EmojiTag = ({ emojiCode }) => {
    const emoji = chatEmoji.find(item => item.code === emojiCode);

    if (!emoji) {
        return `sticker:${emojiCode}`;
    }

    return (
        <img
            className="emoji-sticker"
            src={emoji.icon}
            alt={`Sticker ${emoji.id}`}
            style={{marginBottom: "-6px"}}
            width="24px"
        />
    );
};

export default EmojiTag;