import Dialog from "rc-dialog";
import {StyledRouletteCustomValueChipModal} from "./styledRoulette";
import InputGroup from "../../elements/inputGroup/InputGroup";
import BetAction from "../../elements/betAction/BetAction";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {StyledButton} from "../../styles/styledButton";

function RouletteCustomValueChipModal({isOpen, setIsOpen, isPlay, autoModeIsStart, setSubmitData, setCustom, submitData}) {
    const {t} = useTranslation("siteOptions");

    const [valueNumber, setValueNumber] = useState("0.00000001");

    const handleInput = (valueNumber) => {
        const value = `${valueNumber}`.replaceAll(',', '.');
        if (value && parseFloat(value) && value.includes('.')) {
            let [intPart, decimalPart] = value.split('.');
            if (decimalPart?.length > 8) {
                decimalPart = decimalPart.substring(0, 8);
            }
            if (intPart === '') {
                intPart = 0;
            }
            setValueNumber([intPart, decimalPart].join('.'))
        } else {
            setValueNumber(value);
        }
    }

    const handleConfirmCustomValue = () => {
        setCustom(true);
        setSubmitData((prevState) => ({...prevState, "currentBet": valueNumber.toString()}))
        setIsOpen(false)
    }

    useEffect(() => {
        if (isOpen) {
            window.history.pushState({modal: true}, '');

            const handlePopState = () => {
                setIsOpen(false);
            };

            window.addEventListener('popstate', handlePopState);
            return () => window.removeEventListener('popstate', handlePopState);
        }
    }, [isOpen, setIsOpen]);

    return (
        <Dialog visible={isOpen} onClose={() => setIsOpen(false)} animation="zoom" maskAnimation="fade"
                forceRender={false} title={t("titleModalCustomChip")} wrapClassName="default-modal-wrapper"
                className="default-modal">
            <StyledRouletteCustomValueChipModal>
                <InputGroup
                    autocomplete="off"
                    id="amount"
                    type="text"
                    label={t("inputModalCustomChip")}
                    name="amount"
                    value={valueNumber}
                    betAction={
                        <BetAction
                            value={valueNumber}
                            setValue={setValueNumber}
                            min={"0.00000001"}
                            max={"9999999999.00000000"}
                            isRouletteModal
                            isPlay={isPlay || autoModeIsStart}
                            submitData={submitData}
                            handleChange={handleInput}
                        />}
                    handleInput={handleInput}
                />
                <StyledButton color="neutral" type="submit" width="100" disabled={valueNumber < 0.00000001}
                              onClick={handleConfirmCustomValue}>{t("confirm")}</StyledButton>
            </StyledRouletteCustomValueChipModal>
        </Dialog>
    )
}

export default RouletteCustomValueChipModal;