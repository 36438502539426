import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Tabs, { TabPane } from "rc-tabs";
import ClientDetailsStatisticsByCurrencyContainer from "./ClientDetailsStatisticsByCurrencyContainer";
import ClientDetailsActivityContainer from "./ClientDetailsActivityContainer";
import ClientDetailsLevel from "./ClientDetailsLevel";

const ClientDetailsActivityAndStatisticsWrapper = ({ match, setNotification }) => {
  const [defaultKey, setDefaultKey] = useState("activity");

  const { t } = useTranslation("siteOptions");

  const changeTab = (key) => {
    setDefaultKey(key);
  };

  return (
    <Tabs
      defaultActiveKey={defaultKey}
      tabPosition="top"
      className="default-tabs default-tabs-top"
      onChange={changeTab}
    >
      <TabPane tab={t('activity')} key="activity">
        <ClientDetailsActivityContainer
          match={match}
          setNotification={setNotification}
        />
      </TabPane>
      <TabPane tab={t('statistics')} key="statistics">
        <ClientDetailsStatisticsByCurrencyContainer
          match={match}
        />
      </TabPane>
      <TabPane tab={t('level')} key="level">
        <ClientDetailsLevel match={match}/>
      </TabPane>
    </Tabs>
  );
};

export default ClientDetailsActivityAndStatisticsWrapper;