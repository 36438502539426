import axios from "axios";

import {useBetween} from "use-between";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";

import {
    StyledWrapper, StyledItemTable,
    StyledItemTitle,
    StyledItemValue,
    StyledWrapperTable
} from "./styledUnbanUserModal";
import {StyledButton} from "../../../styles/styledButton";

import Dialog from "rc-dialog";
import {closableNotification} from "../../../elements/notification/ClosableNotification";
import TextareaGroup from "../../../elements/inputGroup/TextareaGroup";
import LoadButton from "../../../elements/spinner/ButtonSpinner";

import ProfileActionsStates from "../../ProfileActionsStates";
import {AppContext} from "../../../../App";

import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {responseStatus} from "../../../../utils/consts";

function UnbanUserModal({currentBanData}) {
    const {t} = useTranslation("siteOptions");

    const [isLoading, setIsLoading] = useState(false);
    const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));
    const [causeUnban, setCauseUnban] = useState("");

    const {
        setVisibleUnbanUserModal,
        visibleUnbanUserModal,
        setVisibleBanListModal
    } = useBetween(ProfileActionsStates);

    const {authenticated, user} = useContext(AppContext);

    const unbanUserReq = () => {
        setIsLoading(true);
        axios.put(`${process.env.REACT_APP_MODERATOR_LINK}/bans/unban-user`, {
            banId: currentBanData.id,
            causeUnban: causeUnban
        }, userAuthenticationConfig(false)).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                closableNotification(t("successUnbanUser"), "success");
                setVisibleBanListModal(false);
                setVisibleUnbanUserModal(false);
                setIsLoading(false);
                setCauseUnban("");
            }
        }).catch(error => {
            closableNotification(error.response.data.message, "error");
            setIsLoading(false);
            setVisibleUnbanUserModal(false);
        });
    };

    const causeUnbanHandler = (e) => {
        setCauseUnban(e.target.value);
    }

    function formatMinutes(minutes) {
        const units = [
            {label: t("botMessageWeeks"), value: 60 * 24 * 7},
            {label: t("botMessageDays"), value: 60 * 24},
            {label: t("botMessageHours"), value: 60},
            {label: t("botMessageMinutes"), value: 1}
        ];

        let result = [];

        for (const unit of units) {
            if (minutes >= unit.value) {
                const amount = Math.floor(minutes / unit.value);
                minutes %= unit.value;
                result.push(`${amount}${unit.label}`);
            }
        }

        return result.join(' ') || '0m';
    }

    function calculateRemainingTime(currentBanData) {
        const banEndTimestamp = currentBanData?.banned_at + (currentBanData?.ban_time * 60);
        const remainingSeconds = banEndTimestamp - currentTime;

        if (remainingSeconds <= 0) {
            return t("expired");
        }

        return formatMinutes(Math.ceil(remainingSeconds / 60));
    }

    return (
        <Dialog
            visible={authenticated && visibleUnbanUserModal}
            wrapClassName="default-modal-wrapper"
            onClose={() => {
                setVisibleBanListModal(true);
                setVisibleUnbanUserModal(false);
            }}
            animation="zoom"
            maskAnimation="fade"
            title={`${t("unbanUserTitle")} «${currentBanData?.user}»`}
            forceRender={false}
            className="default-modal unban-user-modal"
            style={{margin: "auto"}}
            footer={isLoading ? <LoadButton
                text={t("unban")}
                color="neutral"
                type="submit"
                style={{width: '100%'}}
                disabled={!currentBanData?.user || !causeUnban}
            /> : <StyledButton
                onClick={unbanUserReq}
                color="neutral"
                type="submit"
                width="100"
            >
                {t("unban")}
            </StyledButton>}
        >
            <StyledWrapper>
                <StyledWrapperTable>
                    <StyledItemTable>
                        <StyledItemTitle>{t("user")}</StyledItemTitle>
                        <StyledItemValue>{currentBanData?.user}</StyledItemValue>
                    </StyledItemTable>
                    <StyledItemTable>
                        <StyledItemTitle>{t("banTime")}</StyledItemTitle>
                        <StyledItemValue>{calculateRemainingTime(currentBanData)}</StyledItemValue>
                    </StyledItemTable>
                    <StyledItemTable>
                        <StyledItemTitle>{t("cause")}</StyledItemTitle>
                        <StyledItemValue>{currentBanData?.cause}</StyledItemValue>
                    </StyledItemTable>
                    <StyledItemTable>
                        <StyledItemTitle>{t("message")}</StyledItemTitle>
                        <StyledItemValue>{currentBanData?.message}</StyledItemValue>
                    </StyledItemTable>
                </StyledWrapperTable>
                <TextareaGroup
                    handleChange={causeUnbanHandler}
                    value={causeUnban}
                    label={t("unbanCause")}
                />
            </StyledWrapper>
        </Dialog>
    )
}

export default UnbanUserModal;