import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import Dialog from "rc-dialog";
import ReCaptcha from "../../../reCaptcha/ReCaptcha";
import {closableNotification} from "../../../notification/ClosableNotification";

function ChatCaptchaModal({visible, setVisible, socketRef, setCaptchaSuccess}) {
    const { t } = useTranslation("siteOptions");
    const {t: errorsT} = useTranslation("errors");

    const recaptchaRef = useRef(null);

    function sendCheckReCaptchaReq() {
        if (!recaptchaRef.current) return;
        if (!recaptchaRef.current.getValue()) return closableNotification(errorsT("Failed authentication, suspicious activity"), "error");
        socketRef.current.emit("reCaptcha", {
            response: recaptchaRef.current.getValue(),
        })
    }

    useEffect(() => {
        if (!socketRef.current || !visible) return;
        socketRef.current.on("reCaptcha", (data) => {
            if (data.status === "ok") {
                recaptchaRef.current.reset();
                setCaptchaSuccess(true);
                setVisible(false);
            } else if (data.status === "error") {
                recaptchaRef.current.reset();
                closableNotification(errorsT("sendMessage_code_3"), "error");
            }
        });
    }, [visible])

    function handleComplete() {
        setTimeout(() => sendCheckReCaptchaReq(), 300)
    }

    useEffect(() => {
        if (visible) {
            window.history.pushState({modal: true}, '');

            const handlePopState = () => {
                setVisible(false);
            };

            window.addEventListener('popstate', handlePopState);
            return () => window.removeEventListener('popstate', handlePopState);
        }
    }, [visible, setVisible]);

    return (
        <Dialog
            visible={visible}
            wrapClassName="default-modal-wrapper"
            onClose={() => setVisible(false)}
            animation="zoom"
            maskAnimation="fade"
            title={"ReCaptcha"}
            forceRender={false}
            className={`default-modal`}
        >
            <ReCaptcha recaptchaRef={recaptchaRef} onComplete={handleComplete}/>
        </Dialog>
    )
}

export default ChatCaptchaModal;