import axios from "axios";
import Dialog from "rc-dialog";
import { useEffect, useState } from "react";
import { responseStatus, waitTimer } from "../../../../utils/consts";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import { closableNotification } from "../../notification/ClosableNotification";
import { StyledModalLoader } from "../../payment/styledPaymentInvoice";
import loadingGif from "../../../../assets/images/loading.gif";
import TokenBonus from "./TokenBonus";
import { DateToTimestamp } from "../../../../utils/timestampToDate";

const LeftSideBarTokenBonusContainer = ({ visible, setVisible, t }) => {

  const [bonusData, setBonusData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timeDifference, setTimeDifference] = useState(waitTimer.MAX);

  useEffect(() => {
    if (visible) {
      window.history.pushState({modal: true}, '');

      const handlePopState = () => {
        setVisible(false);
      };

      window.addEventListener('popstate', handlePopState);
      return () => window.removeEventListener('popstate', handlePopState);
    }
  }, [visible, setVisible]);

  const fetchTokenBonusData = () => {
    setLoading(true);
    axios.get("/api/get-token-bonus-by-currency/LUCKY", userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setBonusData({
          token: response.data.token || { currentDay: 1 },
          balance: response.data.balance
        });

        const currentDate = new Date();

        if (response.data.token?.lastGettingDate) {
          setTimeDifference(waitTimer.MAX - DateToTimestamp(currentDate) + Number(response.data.token.lastGettingDate));
        } else {
          setTimeDifference(0);
        }
      }
    }).catch(error => {
      closableNotification(error.response.data.detail, "error");
    }).finally(() => setLoading(false));
  };

  useEffect(() => {
    if (visible) {
      fetchTokenBonusData();
    }
  }, [visible]);

  if (!bonusData) {
    return <></>
  }

  return (
    <Dialog
      visible={visible}
      wrapClassName="default-modal-wrapper"
      onClose={() => setVisible(false)}
      animation="zoom"
      maskAnimation="fade"
      title={t("tokenBonusModalTitle")}
      forceRender={false}
      className="default-modal"
    >
      {!loading ?
        <TokenBonus
          setVisible={setVisible}
          visible={visible}
          tokenBonusData={bonusData}
          timeDifference={timeDifference}
          setBonusData={setBonusData}
          fetchTokenBonusData={fetchTokenBonusData}
        /> :
        <StyledModalLoader>
          <img src={loadingGif} alt={"loading..."} />
        </StyledModalLoader>}
    </Dialog>
  );
};

export default LeftSideBarTokenBonusContainer;