import Dialog from "rc-dialog";
import React, {useEffect, useMemo} from "react";
import {StyledHotkeysItem} from "./styledHotkeysToggler";
import AlertMessage from "../alert/Alert";
import {StyledButton} from "../../styles/styledButton";
import {useTranslation} from "react-i18next";
import {useBetween} from "use-between";
import LanguageStates from "../listLanguage/LanguageStates";

const HotkeysTogglerModal = ({isVisible, setIsVisible, game}) => {
    const {t} = useTranslation('siteOptions');
    const {activeLang} = useBetween(LanguageStates);


    const hotkeys = useMemo(() => {
        switch (game) {
            case 'dice':
                return [
                    {
                        description: t("hotkeyDiceDescOne"),
                        keyName: "Q"
                    },
                    {
                        description: t("hotkeyDiceDescTwo"),
                        keyName: "W"
                    },
                    {
                        description: t("hotkeyDiceDescThree"),
                        keyName: "E"
                    },
                    {
                        description: t("hotkeyDiceDescFour"),
                        keyName: "R"
                    },
                    {
                        description: t("hotkeyDiceDescFive"),
                        keyName: "A"
                    },
                    {
                        description: t("hotkeyDiceDescSix"),
                        keyName: "S"
                    },
                    {
                        description: t("hotkeyDiceDescSeven"),
                        keyName: "D"
                    },
                    {
                        description: t("hotkeyDiceDescEight"),
                        keyName: "F"
                    },
                    {
                        description: t("hotkeyDiceDescNine"),
                        keyName: "Space"
                    }
                ]

            case 'balls':
                return [
                    {
                        description: t("hotkeyBallsDescOne"),
                        keyName: "Q"
                    },
                    {
                        description: t("hotkeyBallsDescTwo"),
                        keyName: "W"
                    },
                    {
                        description: t("hotkeyBallsDescThree"),
                        keyName: "E"
                    },
                    {
                        description: t("hotkeyBallsDescFour"),
                        keyName: "R"
                    },
                    {
                        description: t("hotkeyBallsDescFive"),
                        keyName: "A"
                    },
                    {
                        description: t("hotkeyBallsDescSix"),
                        keyName: "S"
                    },
                    {
                        description: t("hotkeyBallsDescSeven"),
                        keyName: "D"
                    },
                    {
                        description: t("hotkeyBallsDescEight"),
                        keyName: "F"
                    },
                    {
                        description: t("hotkeyBallsDescNine"),
                        keyName: "Space"
                    }
                ]

            case 'roulette':
                return [
                    {
                        description: t("hotkeyRouletteDescOne"),
                        keyName: "Q"
                    },
                    {
                        description: t("hotkeyRouletteDescTwo"),
                        keyName: "W"
                    },
                    {
                        description: t("hotkeyRouletteDescThree"),
                        keyName: "A"
                    },
                    {
                        description: t("hotkeyRouletteDescFour"),
                        keyName: "S"
                    },
                    {
                        description: t("hotkeyRouletteDescFive"),
                        keyName: "Space"
                    }
                ]
        }
    }, [game, activeLang]);

    useEffect(() => {
        if (isVisible) {
            window.history.pushState({modal: true}, '');

            const handlePopState = () => {
                setIsVisible(false);
            };

            window.addEventListener('popstate', handlePopState);
            return () => window.removeEventListener('popstate', handlePopState);
        }
    }, [isVisible, setIsVisible]);

    return (
        <Dialog
            visible={isVisible}
            wrapClassName={`default-modal-wrapper ${game}`}
            onClose={() => setIsVisible(false)}
            animation="zoom"
            maskAnimation="fade"
            title={t("hotkeysTitleModal")}
            forceRender={false}
            className="default-modal"
            footer={<StyledButton
                color="neutral"
                width="100"
                onClick={() => setIsVisible(false)}
            >
                {t('close')}
            </StyledButton>}
        >
            {hotkeys?.map((item, index) => (
                <StyledHotkeysItem key={`${index}-${game}`}>
                    <span className={"key"}>{item.keyName}</span>
                    <span className={"description"}>{item.description}</span>
                </StyledHotkeysItem>
            ))}
            <AlertMessage mt={25} message={`${t("hotkeysAlert")} `} messageBold={t("hotkeysBoldAlert")}/>
        </Dialog>
    );
};

export default HotkeysTogglerModal;