import Dialog from "rc-dialog";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledChoosePlanModal,
  StyledChooseStakingPeriodExtended,
  StyledNextCycleProgress,
  StyledStakingProfit
} from "./styledChoosePlanModal";
import { StyledSelect } from "../../../components/styles/styledSelect";
import Select, { Option } from "rc-select";
import { StyledPaymentMethodsIcons } from "../../../components/styles/StyledPaymentMethodsIcons";
import BetAction from "../../../components/elements/betAction/BetAction";
import InputGroup from "../../../components/elements/inputGroup/InputGroup";
import { isNumeric } from "rc-drawer/lib/utils";
import { StyledFlexAlign } from "../../../components/elements/inputGroup/styledInputGroup";
import AlertMessage from "../../../components/elements/alert/Alert";
import { StyledButton } from "../../../components/styles/styledButton";
import { miningConsts } from "../../../utils/consts";

const getSelectedPeriod = (plan) => {
  return plan?.periods[plan.defaultPeriod];
}

const endDate = new Date();
endDate.setHours(23, 59, 59, 0)

export const ChoosePlanModal = ({ plans, handlePeriodChange, selectedIndex, setSelectedIndex, formatAmount }) => {

  const { t } = useTranslation('staking');
  const { t: sT } = useTranslation('siteOptions');
  const ref = useRef();

  const [depositAmount, setDepositAmount] = useState(0);
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [progress, setProgress] = useState(0);

  const selectedPlan = useMemo(() => {
    return plans[selectedIndex];
  }, [plans, selectedIndex]);

  const selectedPeriod = getSelectedPeriod(selectedPlan);

  const handleAmountInput = (amount) => {
    setDepositAmount(amount);
    setIsValueChanged(true);
  }

  useEffect(() => {
    setIsValueChanged(false);
    setDepositAmount(0);
  }, [selectedIndex]);

  useEffect(() => {
    return () => {
      setIsValueChanged(false);
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const difference = miningConsts.secondsInDay - ((endDate.getTime() - now) / 1000);
      setProgress(Math.ceil(difference / miningConsts.secondsInDay * 100));
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [progress]);

  const min = useMemo(() => {
    return selectedPeriod?.depositRange.min;
  }, [selectedPeriod]);

  const max = useMemo(() => {
    return selectedPeriod?.depositRange.max;
  }, [selectedPeriod]);

  if (!selectedPlan) {
    return null;
  }

  const validate = (value, forceValidateBalance = false) => {
    if (!isValueChanged) {
      return false;
    }
    if (!isNumeric(value)) {
      return true;
    }
    value = parseFloat(value);

    // if ((displayBalanceError || forceValidateBalance) && selectedPaymentMethod?.balances?.amount) {
    //   if (value > selectedPaymentMethod?.balances?.amount) {
    //     return t('insufficientFunds') + `: ${selectedPaymentMethod?.balances?.amount} ${selectedPaymentMethod.currency.asset}`;
    //   }
    // }
    if (min) {
      if (value < min) {
        return sT('minimumAmount') + `: ${min} ${selectedPlan.currency.asset}`;
      }
    }
    if (max) {
      if (value > max) {
        return sT('maximumAmount') + `: ${max} ${selectedPlan.currency.asset}`;
      }
    }
    return false;
  }

  const profit = () => {
    const amount = parseFloat(depositAmount) ?? 0;
    return formatAmount(amount * selectedPeriod.percentage / 100);
  }

  return <Dialog
    visible={selectedIndex !== -1}
    wrapClassName="default-modal-wrapper"
    onClose={() => setSelectedIndex(-1)}
    animation="zoom"
    maskAnimation="fade"
    title={t('chooseIt')}
    forceRender={false}
    className="default-modal"
  >
    <StyledChoosePlanModal ref={ref}>
      <StyledSelect mb={20}>
        <Select
          getPopupContainer={() => ref.current}
          className="custom-select"
          name="category"
          value={selectedIndex}
          onChange={setSelectedIndex}
        >
          {plans?.map((plan, index) => (
            <Option
              value={index}
              key={index}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledPaymentMethodsIcons
                  width="28"
                  height="28"
                  className={`payment-method-big-${plan.currency.asset}`}
                  mr={10}
                />
                <span>{plan.currency.asset}</span>
              </div>
            </Option>
          ))}
        </Select>
      </StyledSelect>
      <InputGroup
        autocomplete="off"
        id="amount"
        type="number"
        label={sT("enterSum")}
        name="amount"
        placeholder={sT("amount")}
        value={depositAmount}
        betAction={
          <BetAction
            value={depositAmount}
            setValue={handleAmountInput}
            min={min}
            max={max}
          />}
        error={validate(depositAmount)}
        hintsWithMax={{
          currency: { asset: selectedPlan.currency.asset },
          min,
          max,
        }}
        handleInput={handleAmountInput}
      />
      <div>
        <StyledFlexAlign mb={10}>{t('plan')}:</StyledFlexAlign>
        <StyledChooseStakingPeriodExtended>
          {selectedPlan.periods?.map((period, key) => (
            <div
              className={key === selectedPlan.defaultPeriod ? 'active' : undefined}
              onClick={() => handlePeriodChange(selectedIndex, key)}
              key={key}
            >
              <span>{period.days}</span>
              <span>{t('days')}</span>
              <span>{period.percentage}%</span>
            </div>
          ))}
        </StyledChooseStakingPeriodExtended>
      </div>
      <StyledNextCycleProgress mt={15} mb={15} progress={progress}>
        <div>
          <span>{t('nextCycle')}</span>
          <span>{t('in')} {`${endDate.getHours()}:${endDate.getMinutes()}:${endDate.getSeconds()}`}</span>
        </div>
        <span>{t('modalProgressDescription')}</span>
      </StyledNextCycleProgress>
      <AlertMessage
        mb={15}
        message={t('modalAlert')}
      />
      <StyledStakingProfit>
        <span>{t('profit')}:</span>
        <span>{profit()} {selectedPlan.currency.asset}</span>
      </StyledStakingProfit>
      <StyledButton color={'neutral'} mt={15}>
        {t('chooseIt')}
      </StyledButton>
    </StyledChoosePlanModal>
  </Dialog>
}

export default ChoosePlanModal;

