import React, { useContext, useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import { StyledCloseImg, StyledContainer } from "../../components/styles/styledContainer";
import { StyledMainTitle } from "../../components/styles/styledTitle";
import { StyledPageTemplateWrapper } from "../../components/styles/styledPageTemplate";
import { generatePath } from "../../utils/getLanguage";
import {
  StyledStakingPage,
  StyledStakingPageDescription,
  StyledStakingPlansContainer,
  StyledStakingWrapper
} from "./styledStakingPage";
import { AppContext } from "../../App";
import ProfileActionsWindow from "../../components/profileActionsWindow/ProfileActionsWindow";
import SendTipWindow from "../../components/profileActionsWindow/SendTipWindow";
import ReactHintFactory from "react-hint";
import ChoosePlanTab from "./tabs/ChoosePlanTab";
import ActivePlansTab from "./tabs/ActivePlansTab";
import HistoryTab from "./tabs/HistoryTab";
import securityImage from "../../assets/images/staking/security.png";
import percentageImage from "../../assets/images/staking/percentage.png";
import timerImage from "../../assets/images/staking/timer.png";
import walletImage from "../../assets/images/staking/wallet.png";
import {getCurrentGame} from "../../utils/online";

const ReactHint = ReactHintFactory(React);

const StakingPage = (request) => {
  const history = useHistory();
  const { t } = useTranslation('staking');

  const goBack = useCallback(() => history.goBack(), [history]);
  // const goToLink = useCallback((link) => history.push(generatePath(link)), [history]);
  const tabs = useMemo(() => [
    {
      title: t('choosePlan'),
      key: 'choose',
      container: ChoosePlanTab
    },
    {
      title: t('activePlans'),
      key: 'active',
      container: ActivePlansTab
    },
    {
      title: t('history'),
      key: 'history',
      container: HistoryTab
    }
  ], [t]);

  const [activeNickname, setActiveNickname] = useState(null);
  const [room, setRoom] = useState(null);

  const [activeTab, setActiveTab] = useState(request.match.params.type ?? tabs[0].key);

  const [searchAsset, setSearchAsset] = useState('');

  const { authenticated } = useContext(AppContext);

  const [stakingData, setStakingData] = useState({ plans: [], activePlans: [], history: [], loading: false });

  // const {
  //   handleProfileActionsVisible
  // } = useBetween(ProfileActionsStates);

  const renderHint = (target) => {
    const { id, nickname, room } = target.dataset;
    setActiveNickname(nickname);
    setRoom(room);

    return <ProfileActionsWindow
      placement="contests"
      keys={+id}
      nickname={nickname}
    />;
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en', { maximumFractionDigits: 10 }).format(amount);
  }

  useEffect(() => {
    const newPath = '/earn' + (activeTab !== tabs[0].key ? `/${activeTab}` : '');
    if (window.location.pathname !== newPath) {
      window.history.replaceState(null, '', generatePath(newPath));
    }
  }, [activeTab, tabs]);

  return (
    <StyledContainer>
      <Helmet>
        <title>{t('staking')} | Luckygames</title>
        <meta
          name="description"
          content={t("meta.dice.description")}
        />
        <link
          rel="canonical"
          href={"https://" + window.location.hostname}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/"}
          hrefLang="en-US"
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ru/"}
          hrefLang="ru-RU"
        />
      </Helmet>
      <StyledPageTemplateWrapper game={getCurrentGame()}>
        <StyledStakingPage width="1000">
          <StyledMainTitle className="page-title" position="center" mb="35">
            {t('staking')}
          </StyledMainTitle>
          <StyledCloseImg onClick={goBack} />
          <StyledStakingPageDescription>
            {t('pageDescription')}
          </StyledStakingPageDescription>
          {authenticated ? <>
            <ReactHint
              persist
              attribute="data-custom"
              className="custom-hint"
              onRenderContent={renderHint}
              events={{ click: true }}
            />
            <SendTipWindow
              activeNickname={activeNickname}
              room={room}
            />
          </> : null}
          <StyledStakingWrapper>
            <StyledStakingPlansContainer>
              <div className={'staking-heading'}>
                <div className={'staking-heading-tabs'}>
                  {tabs?.map((tab, index) => {
                    const isActive = activeTab === tab.key;

                    return <button
                      key={index}
                      className={(isActive ? 'active' : undefined)}
                      onClick={() => setActiveTab(tab.key)}
                    >
                      {tab.title}
                      {isActive && tab.key === 'active' && stakingData.activePlans && stakingData.activePlans?.length > 0 ?
                        <div className={'staking-heading-counter'}>{stakingData.activePlans?.length}</div> :
                        null
                      }
                    </button>;
                  })}
                </div>
                <div className={'staking-search-wrapper'}>
                  <input
                    type={'search'}
                    placeholder={t('search')}
                    value={searchAsset}
                    onInput={e => setSearchAsset(e.target.value)}
                  />
                </div>
              </div>
              <div className={'staking-tabs-content'}>
                {tabs?.map((tab, index) => {
                  const TabContainer = tab.container;
                  return <TabContainer
                    key={index}
                    active={activeTab === tab.key}
                    stakingData={stakingData}
                    setStakingData={setStakingData}
                    searchAsset={searchAsset}
                    formatAmount={formatAmount}
                  />
                })}
              </div>
            </StyledStakingPlansContainer>
            <div className={'staking-info'}>
              <div className={'staking-info-wrapper'}>
                <div>
                  <div>
                    <img src={securityImage} alt={'securityOfAssets'}/>
                  </div>
                  <span>{t('securityOfAssets')}</span>
                  <span>{t('securityOfAssetsDescription')}</span>
                </div>
                <div>
                  <div>
                    <img src={timerImage} alt={'easeOfOpeningAndClosingAttachments'}/>
                  </div>
                  <span>{t('easeOfOpeningAndClosingAttachments')}</span>
                  <span>{t('easeOfOpeningAndClosingAttachmentsDescription')}</span>
                </div>
                <div>
                  <div>
                    <img src={walletImage} alt={'transparentAttachments'}/>
                  </div>
                  <span>{t('transparentAttachments')}</span>
                  <span>{t('transparentAttachmentsDescription')}</span>
                </div>
                <div>
                  <div>
                    <img src={percentageImage} alt={'stableFixedInterest'}/>
                  </div>
                  <span>{t('stableFixedInterest')}</span>
                  <span>{t('stableFixedInterestDescription')}</span>
                </div>
              </div>
            </div>
          </StyledStakingWrapper>
        </StyledStakingPage>
      </StyledPageTemplateWrapper>
    </StyledContainer>
  );
}

export default StakingPage;
