import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useBetween} from "use-between";

import ProfileActionsStates from "../../ProfileActionsStates";
import Dialog from "rc-dialog";
import Select, {Option} from "rc-select";
import InputGroup from "../../../elements/inputGroup/InputGroup";
import ReCaptcha from "../../../elements/reCaptcha/ReCaptcha";
import {closableNotification} from "../../../elements/notification/ClosableNotification";
import getBanTimeVariants from "../../../../constants/banTimeVariants";
import ConfirmBanModal from "../confirmBanModal/ConfirmBanModal";

import {StyledButton} from "../../../styles/styledButton";
import {StyledWrapper} from "./styledBanUserModal";
import {StyledSelect, StyledSelectLabel} from "../../../styles/styledSelect";

import {AppContext} from "../../../../App";

function BanUserModal({activeNickname}) {
    const {t} = useTranslation("siteOptions");
    const {t: tE} = useTranslation('errors');

    const variantsBanTime = getBanTimeVariants(t);

    const [selectedVariantBanTime, setSelectedVariantBanTime] = useState(1);
    const [causeBan, setCauseBan] = useState("");

    const {
        visibleBanUserModal,
        setVisibleBanUserModal,
        setVisibleConfirmBanModal,
        visibleConfirmBanModal
    } = useBetween(ProfileActionsStates);
    const {authenticated} = useContext(AppContext);

    const recaptchaRef = useRef(null);

    const openConfirmBanModal = () => {
        const captchaToken = recaptchaRef?.current?.getValue();
        if (!captchaToken) return closableNotification(tE("Failed authentication, suspicious activity"), "error");
        setVisibleConfirmBanModal(true);
        setVisibleBanUserModal(false);
    }

    useEffect(() => {
        if (visibleConfirmBanModal) return;
        if (recaptchaRef.current) recaptchaRef.current.reset();
    }, [visibleConfirmBanModal])

    return (
        <>
            <ConfirmBanModal activeNickname={activeNickname} causeBan={causeBan}
                             selectedVariantBanTime={selectedVariantBanTime}
                             recaptchaRef={recaptchaRef} setCause={setCauseBan}/>
            <Dialog
                visible={authenticated && visibleBanUserModal}
                wrapClassName="default-modal-wrapper"
                onClose={() => setVisibleBanUserModal(false)}
                animation="zoom"
                maskAnimation="fade"
                title={`${t("banUser")} «${activeNickname}»`}
                forceRender={false}
                className="default-modal ban-user-modal"
                style={{margin: "auto"}}
            >
                <StyledWrapper>
                    <StyledSelect style={{position: "relative"}} id={"select-ban-time"}>
                        <StyledSelectLabel>{t("banTime")}:</StyledSelectLabel>
                        <Select
                            getPopupContainer={() => document.getElementById("select-ban-time")}
                            className="custom-select"
                            name="category"
                            value={selectedVariantBanTime}
                            onChange={(value) => setSelectedVariantBanTime(value)}
                            virtual={false}
                            dropdownAlign={{offset: [-1, 12]}}
                            defaultValue={selectedVariantBanTime}
                        >
                            {variantsBanTime?.map((variant, key) => (
                                <Option
                                    value={variant.value}
                                    key={key}
                                >
                                    <div className="option-select-item">
                                        {variantsBanTime.find((option) => option.value === variant.value).title}
                                    </div>
                                </Option>
                            ))}
                        </Select>
                    </StyledSelect>
                    <InputGroup
                        autocomplete="off"
                        id="amount"
                        type="text"
                        label={t("causeBan")}
                        name="amount"
                        placeholder={t("cause")}
                        autoInput
                        onChange={(event) => setCauseBan(event.target.value)}
                        value={causeBan}
                        className={"input-ban-cause"}
                    />
                    <ReCaptcha recaptchaRef={recaptchaRef}/>
                    <StyledButton
                        onClick={openConfirmBanModal}
                        color="neutral"
                        type="submit"
                        style={{width: '100%', margin: '0'}}
                        disabled={!selectedVariantBanTime || !causeBan || causeBan === ""}
                    >
                        {t("banAction")}
                    </StyledButton>
                </StyledWrapper>
            </Dialog>
        </>
    )
}

export default BanUserModal;