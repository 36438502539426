import Cookies from "js-cookie";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Spinner from "../../components/elements/spinner/Spinner";
import { StyledCloseImg, StyledContainer } from "../../components/styles/styledContainer";
import { StyledMainTitle } from "../../components/styles/styledTitle";
import { StyledPageTemplateWrapper } from "../../components/styles/styledPageTemplate";
import { responseStatus, TOPICS_LIST } from "../../utils/consts";
import { fetchFilterData } from "../../utils/fetchFilterData";
import { generateJWSToken } from "../../utils/mercureAuth";
import ContestItem from "./ContestItem";
import { StyledContestsPage, StyledContestsPageDescription, StyledContestsWrapper } from "./styledContestsPage";
import { AppContext, MercureUrl } from "../../App";
import ProfileActionsWindow from "../../components/profileActionsWindow/ProfileActionsWindow";
import SendTipWindow from "../../components/profileActionsWindow/SendTipWindow";
import ReactHintFactory from "react-hint";
import { useBetween } from "use-between";
import { useHistory } from "react-router-dom";
import ProfileActionsStates from "../../components/profileActionsWindow/ProfileActionsStates";
import { StyledBlockContent } from "../banners/styledBannersPage";
import axios from "axios";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";
import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import {getCurrentGame} from "../../utils/online";

const ReactHint = ReactHintFactory(React);

const ContestsPage = () => {
  const history = useHistory();
  const { t } = useTranslation("contests");
  const { t: gT } = useTranslation("games");

  const [activeNickname, setActiveNickname] = useState(null);
  const [room, setRoom] = useState(null);
  const [contests, setContests] = useState(null);

  const { authenticated } = useContext(AppContext);

  const goBack = useCallback(() => history.goBack(), [history]);
  // const goToLink = useCallback((link) => history.push(generatePath(link)), [history]);

  const fetchContests = () => {
    let filterUrl = fetchFilterData({
      itemsPerPage: 5,
      pag: 1
    });

    axios.get("/api/contests" + filterUrl, userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        // console.log(response.data.contests);
        setContests(response.data);
      }
    }).catch(error => {
      closableNotification(error.response.data.message, "error");
    });
  };

  useEffect(() => {
    fetchContests();
  }, []);

  const topic = TOPICS_LIST.CONTEST.ALL;
  const token = generateJWSToken(topic);

  useEffect(() => {
    MercureUrl.searchParams.delete("topic");

    MercureUrl.searchParams.append("topic", topic);

    Cookies.set("mercureAuthorization", token, { path: "" });

    const es = new EventSource(MercureUrl, { withCredentials: true });

    es.addEventListener("message", fetchContests);

    return () => {
      es.close();
    };
  }, []);

  const {
    handleProfileActionsVisible
  } = useBetween(ProfileActionsStates);

  const renderHint = (target) => {
    const { id, nickname, room } = target.dataset;
    setActiveNickname(nickname);
    setRoom(room);

    return <ProfileActionsWindow
      placement="contests"
      keys={+id}
      nickname={nickname}
    />;
  };

  if (!contests) {
    return (
      <StyledContainer>
        <Spinner display="block" size="35px"/>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <Helmet>
        <title>{t("contests")} | Luckygames</title>
        <meta
          name="description"
          content={t("meta.dice.description")}
        />
        <link
          rel="canonical"
          href={"https://" + window.location.hostname}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/"}
          hrefLang="en-US"
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ru/"}
          hrefLang="ru-RU"
        />
      </Helmet>
      <StyledPageTemplateWrapper game={getCurrentGame()}>
        <StyledContestsPage width="1000">
          <StyledMainTitle className="page-title" position="center" mb="35">
            {t("contests")}
          </StyledMainTitle>
          <StyledCloseImg onClick={goBack}/>
          <StyledContestsPageDescription>
            {t("pageDescription")}
          </StyledContestsPageDescription>
          {authenticated ? <>
            <ReactHint
              persist
              attribute="data-custom"
              className="custom-hint"
              onRenderContent={renderHint}
              events={{ click: true }}
            />
            <SendTipWindow
              activeNickname={activeNickname}
              room={room}
            />
          </> : null}
          <StyledContestsWrapper>
            {contests?.length > 0 ? contests?.map((contest, key) =>
              <ContestItem
                contest={contest}
                key={key}
                handleProfileActionsVisible={handleProfileActionsVisible}
              />) : <StyledBlockContent className={"uppercase"}>{gT("nothingFound")}</StyledBlockContent>}
          </StyledContestsWrapper>
        </StyledContestsPage>
      </StyledPageTemplateWrapper>
    </StyledContainer>
  );
};

export default ContestsPage;
