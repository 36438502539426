import Dialog from "rc-dialog";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import LoadButton from "../../../elements/spinner/ButtonSpinner";
import {StyledButton} from "../../../styles/styledButton";
import {useBetween} from "use-between";
import ProfileActionsStates from "../../ProfileActionsStates";
import axios from "axios";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {closableNotification} from "../../../elements/notification/ClosableNotification";
import {
    StyledItemTable,
    StyledItemTitle,
    StyledItemValue,
    StyledWrapperTable
} from "./styledConfirmBanModal";
import getBanTimeVariants from "../../../../constants/banTimeVariants";

function ConfirmBanModal({
                             activeNickname,
                             causeBan, setCause,
                             selectedVariantBanTime,
                         }, recaptchaRef) {
    const {t} = useTranslation("siteOptions");
    const {t: tE} = useTranslation('errors');

    const variantsBanTime = getBanTimeVariants(t);

    const [isLoading, setIsLoading] = useState(false);
    const {
        messageData,
        visibleConfirmBanModal,
        setVisibleConfirmBanModal,
        setVisibleBanUserModal
    } = useBetween(ProfileActionsStates);

    const banUserReq = () => {
        const data = {
            userNickname: activeNickname,
            causeBan: causeBan,
            timeBan: selectedVariantBanTime,
            messageId: messageData ? messageData.id : null,
            captchaToken: recaptchaRef?.current?.getValue()
        }
        setIsLoading(true);
        axios.post(`${process.env.REACT_APP_MODERATOR_LINK}/bans/ban-user`, data, userAuthenticationConfig(false)).then(response => {
            closableNotification(t("userBanned"), "success");
            setIsLoading(false);
            setCause("");
            setVisibleConfirmBanModal(false);
        }).catch(error => {
            closableNotification(tE(error.response.data.message), "error");
            setIsLoading(false);
        });
    };

    return (
        <Dialog
            visible={visibleConfirmBanModal}
            wrapClassName="default-modal-wrapper"
            onClose={() => {
                setVisibleBanUserModal(true);
                setVisibleConfirmBanModal(false);
            }}
            animation="zoom"
            maskAnimation="fade"
            title={`${t("confirmBanTitle")} «${activeNickname}»`}
            forceRender={false}
            className="default-modal ban-user-modal"
            style={{margin: "auto"}}
        >
            <StyledWrapperTable>
                <StyledItemTable>
                    <StyledItemTitle>{t("nickname")}</StyledItemTitle>
                    <StyledItemValue>{activeNickname}</StyledItemValue>
                </StyledItemTable>
                <StyledItemTable>
                    <StyledItemTitle>{t("cause")}</StyledItemTitle>
                    <StyledItemValue>{causeBan}</StyledItemValue>
                </StyledItemTable>
                <StyledItemTable>
                    <StyledItemTitle>{t("timeBan")}</StyledItemTitle>
                    <StyledItemValue>{variantsBanTime?.find((variant) => variant.value === selectedVariantBanTime)?.title}</StyledItemValue>
                </StyledItemTable>
                <StyledItemTable>
                    <StyledItemTitle>{t("message")}</StyledItemTitle>
                    <StyledItemValue>{messageData?.value}</StyledItemValue>
                </StyledItemTable>
            </StyledWrapperTable>
            {isLoading ? <LoadButton
                text={t("confirm")}
                color="neutral"
                type="submit"
                style={{width: '100%', margin: '0'}}
            /> : <StyledButton
                onClick={banUserReq}
                color="neutral"
                type="submit"
                style={{width: '100%', margin: '0'}}
                disabled={!selectedVariantBanTime || !causeBan || causeBan === ""}
            >
                {t("confirm")}
            </StyledButton>}
        </Dialog>
    )
}

export default ConfirmBanModal;