import React, {useEffect, useState, useMemo} from "react";
import {StyledUserLevel, StyledUserLevelItem} from "./styledClientDetails";
import starIcon from "../../../../assets/images/level-star-icon.svg";
import {useTranslation} from "react-i18next";
import axios from "axios";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {responseStatus} from "../../../../utils/consts";
import {closableNotification} from "../../../elements/notification/ClosableNotification";
import Big from "big.js";
import Spinner from "../../../elements/spinner/Spinner";

const ClientDetails = ({match}) => {
    const {t} = useTranslation("games");
    const [levelData, setLevelData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const {nickname} = match.params;

    useEffect(() => {
        const fetchLevelData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LEVEL_LINK}/api/levels/${nickname}`,
                    userAuthenticationConfig()
                );
                if (response.status === responseStatus.HTTP_OK && response.data) {
                    setLevelData(response.data);
                }
            } catch (error) {
                closableNotification(error?.response?.data?.message, "error");
            } finally {
                setIsLoading(false);
            }
        };
        fetchLevelData();
    }, [nickname]);

    const formatNumberWithCommas = (number) =>
        new Big(number).round(0, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    const calculatePoints = (itemData) => {
        if (!itemData) return "0";
        const data = new Big(itemData.data ?? 0);
        const max = new Big(itemData.max ?? 0);
        const maxData = new Big(itemData.max_data ?? 1);
        const computed = data.times(max).div(maxData);
        const result = computed.lt(max) ? computed : max;
        return result.round(0, 0).toString();
    };

    const calculatePercent = (itemData) => {
        if (!itemData) return "0";
        const data = new Big(itemData.data ?? 0);
        const maxData = new Big(itemData.max_data ?? 1);
        const computedPercent = data.times(100).div(maxData);
        const finalPercent = computedPercent.lt(100) ? computedPercent : new Big(100);
        return finalPercent.round(2, 0).toString();
    };

    const totalPointsNumeric = useMemo(() => {
        if (!levelData) return new Big(0);
        return Object.values(levelData).reduce((total, item) => {
            const data = new Big(item?.data ?? 0);
            const max = new Big(item?.max ?? 0);
            const maxData = new Big(item?.max_data ?? 1);
            const computed = data.times(max).div(maxData);
            const result = new Big((computed.lt(max) ? computed : max).round(0, 0));
            return total.plus(result);
        }, new Big(0));
    }, [levelData]);

    const totalPointsFormatted = useMemo(
        () =>
            totalPointsNumeric
                .round(0, 0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        [totalPointsNumeric]
    );

    const level = useMemo(() => {
        const pointsPerLevel = new Big(10000);
        return totalPointsNumeric.div(pointsPerLevel).round(0, 0).toString();
    }, [totalPointsNumeric]);

    const levelProgress = useMemo(() => {
        const pointsPerLevel = new Big(10000);
        const currentLevel = totalPointsNumeric.div(pointsPerLevel).round(0, 0);
        const pointsInCurrentLevel = totalPointsNumeric.minus(currentLevel.times(pointsPerLevel));
        const formattedCurrent = pointsInCurrentLevel
            .round(0, 0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        const formattedTotal = pointsPerLevel
            .round(0, 0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${formattedCurrent} / ${formattedTotal}`;
    }, [totalPointsNumeric]);

    if (isLoading || !levelData) {
        return <Spinner display="block" size="35px"/>;
    }

    const statItems = [
        {
            key: "online_days",
            title: t("levelTab.loyaltyTitle"),
            desc: t("levelTab.loyaltyDesc", {
                max_points: formatNumberWithCommas(levelData?.online_days?.max),
                max_value: formatNumberWithCommas(levelData?.online_days?.max_data),
            }),
        },
        {
            key: "bets_sum",
            title: t("levelTab.wageringTitle"),
            desc: t("levelTab.wageringDesc", {
                max_points: formatNumberWithCommas(levelData?.bets_sum?.max),
                max_value: formatNumberWithCommas(levelData?.bets_sum?.max_data),
            }),
        },
        {
            key: "bets_count",
            title: t("levelTab.bettingTitle"),
            desc: t("levelTab.bettingDesc", {
                max_points: formatNumberWithCommas(levelData?.bets_count?.max),
                max_value: formatNumberWithCommas(levelData?.bets_count?.max_data),
            }),
        },
        {
            key: "messages_count",
            title: t("levelTab.chatActivityTitle"),
            desc: t("levelTab.chatActivityDesc", {
                max_points: formatNumberWithCommas(levelData?.messages_count?.max),
                max_value: formatNumberWithCommas(levelData?.messages_count?.max_data),
            }),
        },
        {
            key: "referrals_count",
            title: t("levelTab.referringTitle"),
            desc: t("levelTab.referringDesc", {
                max_points: formatNumberWithCommas(levelData?.referrals_count?.max),
                max_value: formatNumberWithCommas(levelData?.referrals_count?.max_data),
            }),
        },
        {
            key: "referrals_bets_count",
            title: t("levelTab.referralsTitle"),
            desc: t("levelTab.referralsDesc", {
                max_points: formatNumberWithCommas(levelData?.referrals_bets_count?.max),
                max_value: formatNumberWithCommas(levelData?.referrals_bets_count?.max_data),
            }),
        },
        {
            key: "contest_wins",
            title: t("levelTab.contestTitle"),
            desc: t("levelTab.contestDesc", {
                max_points: formatNumberWithCommas(levelData?.contest_wins?.max),
                max_value: formatNumberWithCommas(levelData?.contest_wins?.max_data),
            }),
        },
        {
            key: "jackpot_wins",
            title: t("levelTab.jackpotTitle"),
            desc: t("levelTab.jackpotDesc", {
                max_points: formatNumberWithCommas(levelData?.jackpot_wins?.max),
                max_value: formatNumberWithCommas(levelData?.jackpot_wins?.max_data),
            }),
        },
        {
            key: "rain_count",
            title: t("levelTab.rainmakerTitle"),
            desc: t("levelTab.rainmakerDesc", {
                max_points: formatNumberWithCommas(levelData?.rain_count?.max),
                max_value: formatNumberWithCommas(levelData?.rain_count?.max_data),
            }),
        },
    ];

    return (
        <>
            <StyledUserLevel>
                <div className="main-client-details">
                    <div className="left-side">
                        <div className="star-block">
                            <img src={starIcon} alt="star icon"/>
                        </div>
                        <div className="details-block">
              <span className="level-info">
                {t("levelTab.level")} {level}
              </span>
                            <span className="points-info">
                {levelProgress} {t("levelTab.pointsNextLevel")}
              </span>
                        </div>
                    </div>
                    <div className="right-side">
            <span>
              {t("levelTab.total")}
                <b>
                {" "}
                    {totalPointsFormatted} {t("levelTab.of")} 1,000,000 {t("levelTab.points")}
              </b>
            </span>
                    </div>
                </div>
            </StyledUserLevel>
            {statItems.map((item, index) => (
                <StyledUserLevelItem key={index}>
                    <div className="title-level-block">
                        <b>{item.title}</b>
                        <b>
                            {calculatePoints(levelData?.[item.key])} {t("levelTab.of")}{" "}
                            {formatNumberWithCommas(levelData?.[item.key]?.max)} {t("levelTab.points")}
                        </b>
                    </div>
                    <div className="percent-block">
                        <div className="line-border"/>
                        <span>{calculatePercent(levelData?.[item.key])}%</span>
                    </div>
                    <div className="info-block">
                        <b>{item.desc}</b>
                    </div>
                </StyledUserLevelItem>
            ))}
            <StyledUserLevelItem>
                <div className="title-level-block">
                    <b>{t("levelTab.shopaholicTitle")}</b>
                    <b>
                        0 {t("levelTab.of")} 100,000 {t("levelTab.points")}
                    </b>
                </div>
                <div className="percent-block">
                    <div className="line-border"/>
                    <span>0%</span>
                </div>
                <div className="info-block">
                    <b>
                        {t("levelTab.shopaholicDesc", {
                            max_points: formatNumberWithCommas(100000),
                            max_value: formatNumberWithCommas(100000000),
                        })}
                    </b>
                </div>
            </StyledUserLevelItem>
        </>
    );
};

export default ClientDetails;