import React, {forwardRef, useCallback, useContext, useEffect, useMemo, useState} from "react";
import moment from "moment/moment";
import {
    StyledChatMessages,
    StyledMessageAvatar,
    StyledMessageBody,
    StyledMessageHeader,
    StyledMessagesContainer,
    StyledMessageTitle, StyledNewMessagesButton
} from "./styledChatMessages";

import defaultImg from "../../../../../assets/images/no-avatar.svg";
import {decodeMessage} from "../utils";
import ProfileActionsWindow from "../../../../profileActionsWindow/ProfileActionsWindow";
import {useBetween} from "use-between";
import ProfileActionsStates from "../../../../profileActionsWindow/ProfileActionsStates";
import {AppContext} from "../../../../../App";
import {NavLink} from "react-router-dom";
import {generatePath} from "../../../../../utils/getLanguage";
import "react-hint/css/index.css";
import ReactHintFactory from "react-hint";
import {useTranslation} from "react-i18next";
import {renderToStaticMarkup} from "react-dom/server";
import UserTag, {tagRegex as userTagRegex} from "../../../tags/UserTag";
import BetTag, {tagRegex as betTagRegex} from "../../../tags/BetTag";
import CurrencyTag, {tagRegex as currencyTagRegex} from "../../../tags/CurrencyTag";
import EmojiTag, {tagRegex as emojiTagRegex} from "../../../tags/EmojiTag";
import Big from "big.js";
import {roles} from "../../../../../utils/consts";

const ReactHint = ReactHintFactory(React);

export default forwardRef(function ({messages, room}, ref) {
    const {t} = useTranslation("siteOptions");
    const [showNewMessagesButton, setShowNewMessagesButton] = useState(false);

    const {user} = useContext(AppContext);

    const {handleProfileActionsVisible} = useBetween(ProfileActionsStates);

    const renderHint = (target) => {
        const {id, nickname} = target.dataset;
        return <ProfileActionsWindow
            placement="chat"
            parentRef={ref}
            button={target}
            keys={+id}
            nickname={nickname}
        />
    };

    const avatar = (sender, isSameSender, isCurrentUser) => {
        const src = sender.user.mediaObject ? '/user/images/' + sender.user.mediaObject.data.reference_id + "/" + sender.user.mediaObject.data.name + "_chat.webp" : defaultImg;

        if (!isSameSender) {
            return <StyledMessageAvatar>
                <img src={src} alt={'avatar'}/>
                <div className={`rating  ${sender.user.level && sender.user.level > 9 ? "level" + sender.user.level : ""}`} title={t('accountRating')}>
                    {sender.user.level}
                </div>
                {(sender?.user?.role === roles.MODERATOR || sender?.user?.role === roles.ADMIN) && <div
                    className={`user-group ${sender?.user?.role === roles.MODERATOR ? "mod" : sender?.user?.role === roles.ADMIN ? "admin" : ""}`}>
                    {sender?.user?.role === roles.MODERATOR ? "MOD" : sender?.user?.role === roles.ADMIN ? "ADMIN" : ""}
                </div>}
            </StyledMessageAvatar>;
        }
        return null;
    }
    const handleMessageContentClick = (event) => {
        if (event.target.classList.contains('user-tag') && !event.target.classList.contains('current')) {
            handleProfileActionsVisible(+event.target.dataset.id, "chat");
        }
    }
    const userMessage = (sender, key, isSameSender) => {

        const isCurrentUser = user?.nickname === sender.user.nickname;

        return <StyledChatMessages
            key={key}
            className="chat-messages"
            glued={isSameSender}
            align={isSameSender ? (isCurrentUser ? 'start' : 'end') : null}
        >
            {!isCurrentUser ? avatar(sender, isSameSender, false) : null}
            <StyledMessageBody
                className="message-body"
                alignArrow={!isSameSender ? (isCurrentUser ? 'left' : 'right') : null}
            >
                {!isSameSender ? <>
                        {
                            isCurrentUser ?
                                <StyledMessageHeader
                                    as={NavLink}
                                    to={generatePath(`/account/${sender.user.nickname}`)}
                                >
                                    {sender.user.nickname}
                                </StyledMessageHeader>
                                :
                                <StyledMessageHeader
                                    className="wrapper-relative chat-nickname"
                                    onClick={() => handleProfileActionsVisible(key, "chat", sender)}
                                    data-custom
                                    data-custom-at="right"
                                    data-id={key}
                                    data-nickname={sender.user.nickname}
                                    data-room={room}
                                >
                                    {sender.user.nickname}
                                </StyledMessageHeader>
                        }
                        <StyledMessageHeader>
                            {moment(sender.createdAt * 1000).format("hh:mm")}
                        </StyledMessageHeader>
                    </>
                    : null
                }
                <StyledMessageTitle onClick={handleMessageContentClick}
                                    dangerouslySetInnerHTML={{__html: decodeMessage(sender.value)}}/>
            </StyledMessageBody>
            {isCurrentUser ? avatar(sender, isSameSender, true) : null}
        </StyledChatMessages>
    }

    const botMessage = (sender, key, isSameSender) => {

        const botName = sender.type.split([' '])[1] ?? null;

        return <StyledChatMessages
            key={key}
            className="chat-messages"
            glued={isSameSender}
        >
            <StyledMessageBody className="message-body bot">
                {
                    !isSameSender ? <>
                        {
                            <StyledMessageHeader
                                className="wrapper-relative bot chat-nickname"
                            >
                                <span className={'bot-title'}>BOT</span>
                                {botName ? <span className={'bot-name'}>{t(botName)}</span> : null}
                            </StyledMessageHeader>
                        }
                        <StyledMessageHeader>
                            {moment(sender.createdAt * 1000).format("hh:mm")}
                        </StyledMessageHeader>
                    </> : null
                }
                <StyledMessageTitle onClick={handleMessageContentClick} className={`bot-${(sender.type === "system Guard" && sender.room_id !== "all") || sender.type === "system rain" ? sender.type.replaceAll(" ", "-") : ""}`}
                                    dangerouslySetInnerHTML={{__html: decodeMessage(sender.value)}}/>
            </StyledMessageBody>
        </StyledChatMessages>
    }

    const processBeforeOutput = (sender, key) => {
        switch (sender.type) {
            case "system rules":
                sender.type = `system ${t("titleBotMessageAboutRules")}`
                sender.value = t("botMessageAboutRules");
                break;
            case "system rain":
                if (sender.value !== "") {
                    sender.value = generateRainMessage(sender);
                } else {
                    sender.value = generatePreRainMessage(sender);
                }
                break;
            case "system tip":
                sender.value = generateTipMessage(sender);
                break;
            case "system ban":
                sender.type = `system ${t("titleBotMessageAboutRules")}`
                sender.value = generateBanMessage(sender);
                break;
        }

        if (typeof sender.value === 'string') {
            sender.value = sender.value.replace(userTagRegex, (substr, username) => {
                const isCurrentUser = user?.nickname === username;
                const hintData = !isCurrentUser ? {
                    "data-custom": true,
                    "data-custom-at": "right",
                    "data-id": window.crypto.getRandomValues(new Uint8Array(10)).join(''),
                    "data-nickname": username,
                    "data-room": room
                } : {};
                return renderToStaticMarkup(<UserTag username={username} isCurrentUser={isCurrentUser}
                                                     hintData={hintData}/>);
            });
            sender.value = sender.value.replace(betTagRegex, (substr, id) => renderToStaticMarkup(<BetTag id={id}/>));
            sender.value = sender.value.replace(currencyTagRegex, (substr, coinName) => renderToStaticMarkup(
                <CurrencyTag coinSymbol={coinName}/>));
            sender.value = sender.value.replace(emojiTagRegex, (substr, emojiCode) => renderToStaticMarkup(<EmojiTag
                emojiCode={emojiCode}/>));
        }
        return sender;
    }

    function generatePreRainMessage() {
        let newMessageValue = "";
        newMessageValue += t("botMessagePreRain")
        return newMessageValue;
    }

    function generateTipMessage(sender) {
        let newMessageValue = "";

        newMessageValue += `user:${sender?.value?.from_user} ${t("botMessageTip")} user:${sender?.value?.to_user} - ${new Big(sender?.value?.amount ?? 0).toFixed(8)} ${sender?.value?.coin}`

        return newMessageValue;
    }

    function generateRainMessage(sender) {
        let newMessageValue = "";

        newMessageValue += sender?.value?.is_system ? '<div>' :
            `<div><div>user:${sender?.value?.started_user} ${t("botMessagePersonalRainTitle")}</div>`;
        newMessageValue += `<ul>${t("botMessageRainTitle")}`;
        sender?.value?.winners?.map((value) => (
            newMessageValue += `<li> user:${value?.nickname} - ${new Big(value?.amount ?? 0).toFixed(8)} coin:${value?.coin}</li>`
        ))
        newMessageValue += '</ul></div>';

        return newMessageValue;
    }

    function generateBanMessage(sender) {
        let newMessageValue = "";

        newMessageValue += `user:${sender?.value?.moderator} ${t("botMessageHasBanned")} user:${sender?.value?.user_name}. ${t("botMessageReason")}: ${sender?.value?.cause}. ${t("botMessageDuration")}: ${formatMinutes(sender?.value?.ban_time)}.`

        return newMessageValue;
    }

    function formatMinutes(minutes) {
        const units = [
            {label: t("botMessageWeeks"), value: 60 * 24 * 7},
            {label: t("botMessageDays"), value: 60 * 24},
            {label: t("botMessageHours"), value: 60},
            {label: t("botMessageMinutes"), value: 1}
        ];

        let result = [];

        for (const unit of units) {
            if (minutes >= unit.value) {
                const amount = Math.floor(minutes / unit.value);
                minutes %= unit.value;
                result.push(`${amount}${unit.label}`);
            }
        }

        return result.join(' ') || '0m';
    }

    const messagesList = useMemo(() => {
        if (messages) {
            let previousSender = null;

            return messages?.map((sender, key) => {
                let message = sender.value;

                let isSameSender = false;

                if (previousSender && previousSender.type === sender.type) {
                    isSameSender = sender.type === 'user' ? sender.user.nickname === previousSender.user.nickname : true;
                }

                const newSender = processBeforeOutput({...sender, value: message}, key);
                const result = sender.type === "user" ?
                    userMessage(newSender, key, isSameSender) :
                    botMessage(newSender, key, isSameSender);

                previousSender = sender;

                return result;
            });
        }
        return null;
    }, [messages, user]);


    const scrollToBottomChat = useCallback(() => {
        if (ref?.current) {
            ref.current.scrollTo({
                top: ref.current.scrollHeight,
                behavior: 'smooth',
            });
            setShowNewMessagesButton(false);
        }
    }, [ref]);

    const handleScroll = useCallback(() => {
        if (!ref.current) return;

        const isAtBottom = ref.current.scrollTop + ref.current.clientHeight >= ref.current.scrollHeight - 10;

        if (isAtBottom) {
            setShowNewMessagesButton(false);
        }
    }, [ref]);

    useEffect(() => {
        const container = ref.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [ref, handleScroll]);

    useEffect(() => {
        if (ref.current) {
            setTimeout(() => {
                const isAtBottom =
                    ref.current.scrollTop + ref.current.clientHeight >= ref.current.scrollHeight - 10;

                if (!isAtBottom && messages[messages?.length - 1]?.user?.nickname !== user?.nickname) {
                    setShowNewMessagesButton(true);
                }
            }, 300)
        }
    }, [messages]);

    return (
        <>
            <StyledMessagesContainer
                className="messages-container"
                ref={ref}
            >
                <ReactHint
                    attribute="data-custom"
                    className="custom-hint"
                    onRenderContent={renderHint}
                    events={{click: true}}
                />
                {messagesList}
            </StyledMessagesContainer>
            {showNewMessagesButton && (
                <StyledNewMessagesButton onClick={scrollToBottomChat}>
                    {t("newMessages")} ▼
                </StyledNewMessagesButton>
            )}
        </>
    );
});
