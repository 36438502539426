import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../App";
import PaymentMethodContainer from "../PaymentMethodContainer";
import SiteOptions from "../../elements/siteOptions/SiteOptions";
import axios from "axios";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { defaultCurrentStatistics, games, responseStatus } from "../../../utils/consts";
import { closableNotification } from "../../elements/notification/ClosableNotification";
import BallsNumberSelection from "./BallsNumberSelection";
import BallsToPlayContainer from "./BallsToPlayContainer";
import { fetchHonestyControl } from "../../../utils/fetchHonestyControl";
import { useLayout } from '../../../utils/useLayout';
import { useBetween } from "use-between";
import { CurrentHonestyControl } from "../../elements/leftSidebar/HonestyControl/CurrentHonestyControl";
import BallsStates from "./BallsStates";
import { useAutoBetsStatesBalls } from "../../elements/autoBets/AutoBetsStates";
import { GAMES } from '../Constants';

import {
  StyledBallsBetContent,
  StyledBallsBetWrapper,
  StyledBallsContentWrapper,
  StyledBallsWrapper
} from "./styledBalls";
import BalanceStates from "../BalanceStates";

const BallsContainer = () => {
  const {
    submitData,
    setSubmitData,
    settings,
    setSettings,
    responseData,
    setResponseData,
    errorData,
    setErrorData,
    winAmount,
    setWinAmount,
    animationDisabled,
    setAnimationDisabled,
  } = useBetween(BallsStates);
  const { selectedPaymentMethod } = useBetween(BalanceStates);

  const { authenticated, currentGame, setCurrentGame } = useContext(AppContext);
  const { t } = useTranslation("games");

  const { isMobile, windowWidth } = useLayout();
  const [isRunMagnet, setIsRunMagnet] = useState(false);
  const { autoModeIsStart, autoMode, gameInfoRef } = useAutoBetsStatesBalls();

  const { setCurrentHonestyControl } = useBetween(CurrentHonestyControl);

  useEffect(() => {
    console.log('MOUNT BALLS CONTAINER');
    return () => {
      console.log('UNMOUNT BALLS CONTAINER');
    }
  }, []);

  const getSettings = async () => {
    return axios.get("/api/balls-settings", userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        return response.data["hydra:member"]?.length > 0 ? response.data["hydra:member"][0] : [];
      }
    }).catch(error => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    });
  };

  useEffect(() => {
    if (gameInfoRef.current) {
      gameInfoRef.current.isPlay = isRunMagnet;
    }
  }, [gameInfoRef, isRunMagnet]);


  useEffect(() => {
    (async () => {
      fetchHonestyControl(setCurrentHonestyControl, "Balls");
      let defaultSettings = await getSettings();
      let chanceToWin = calculateChance(defaultSettings);
      let coefficient = calculateCoefficient(defaultSettings);
      let winAmount = calculateWinAmount(submitData.bet, coefficient);
      setSettings(defaultSettings);
      setResponseData((prevState) => ({
        ...prevState,
        "chanceToWin": chanceToWin,
        "coefficient": coefficient,
        "win": winAmount
      }));
      setWinAmount(winAmount);
      localStorage.setItem("currentStatistics", JSON.stringify(defaultCurrentStatistics));
    })();
  }, []);

  useEffect(() => {
    if (!settings) {
      return;
    }
    let chanceToWin = calculateChance();
    let coefficient = calculateCoefficient();
    let winAmount = calculateWinAmount(submitData.bet, coefficient);
    setResponseData((prevState) => ({
      ...prevState,
      "chanceToWin": chanceToWin,
      "coefficient": coefficient,
      "win": winAmount
    }));
    setWinAmount(winAmount);
  }, [submitData.sign, submitData.suggestedNumbers, submitData.bet]);

  const onChangeInput = (event, isMultiple = false) => {
    if (isMultiple) {
      setSubmitData((prevState) => ({ ...prevState, [event.name]: event.value }));
    } else {
      let { name, value } = event.target;
      if (name === "number") {
        value = parseInt(value);
      }
      let pattern = /^(\d{0,10})(\.\d{0,8})?$/g;
      if (name === "bet") {
        if (value?.length < 20 && value.match(pattern)) {
          setSubmitData((prevState) => ({ ...prevState, [name]: value }));
        }
      } else {
        setSubmitData((prevState) => ({ ...prevState, [name]: value }));
      }
    }
  };

  const calculateChance = (defaultBallsSettings = null) => {
    if (!defaultBallsSettings) {
      defaultBallsSettings = settings;
    }

    let chance = JSON.parse(submitData.suggestedNumbers)?.length / (defaultBallsSettings.max + 1) * 100;

    return parseInt(chance * 100) / 100;
  };

  const calculateCoefficient = (defaultBallsSettings = null) => {
    if (!defaultBallsSettings) {
      defaultBallsSettings = settings;
    }

    let coefficient = (defaultBallsSettings.max + 1) / JSON.parse(submitData.suggestedNumbers)?.length * (100 - defaultBallsSettings.profit) / 100;

    if (isNaN(parseInt(coefficient * 100) / 100)) {
      return 0;
    }

    return parseInt(coefficient * 10000) / 10000;
  };

  const calculateWinAmount = (bet, coefficient) => {
    if (coefficient === 0) {
      return 0;
    }

    return (bet * coefficient - bet).toFixed(8);
  };

  useEffect(() => {
    if (currentGame !== games.balls) {
      setCurrentGame(games.balls);
    }
  }, []);

  return (
    <>
      <StyledBallsWrapper isMobile={isMobile}>
        <StyledBallsContentWrapper>
          <SiteOptions
            animationDisabled={animationDisabled}
            setAnimationDisabled={setAnimationDisabled}
            isRunMagnet={isRunMagnet}
            paymentMethod={selectedPaymentMethod}
          />
          <BallsNumberSelection
            isMobile={isMobile}
            submitData={submitData}
            setSubmitData={setSubmitData}
            responseData={responseData}
            handleChange={onChangeInput}
            isRunMagnet={isRunMagnet}
            setIsRunMagnet={setIsRunMagnet}
            animationDisabled={animationDisabled}
            autoMode={autoModeIsStart}
            autoModeAcceleration={autoMode.acceleration}
            windowWidth={windowWidth}
          />
        </StyledBallsContentWrapper>
      </StyledBallsWrapper>
      <StyledBallsBetWrapper>
        <StyledBallsBetContent>
          <PaymentMethodContainer
            responseData={responseData}
            paymentMethod={selectedPaymentMethod}
            setSubmitData={setSubmitData}
            isEnd={!isRunMagnet}
            animationDisabled={animationDisabled}
            game={GAMES.BALLS}
            authenticated={authenticated}
          />
          <BallsToPlayContainer
            setSubmitData={setSubmitData}
            submitData={submitData}
            responseData={responseData}
            setResponseData={setResponseData}
            errorData={errorData}
            setErrorData={setErrorData}
            handleChange={onChangeInput}
            setIsRunMagnet={setIsRunMagnet}
            isRunMagnet={isRunMagnet}
            winAmount={winAmount}
            isMobile={isMobile}
            animationDisabled={animationDisabled}
          />
        </StyledBallsBetContent>
      </StyledBallsBetWrapper>
    </>
  );
};

export default BallsContainer;
