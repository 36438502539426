const getBanTimeVariants = (t) => [
    { value: 1, title: `1 ${t("wordMinute")}` },
    { value: 5, title: `5 ${t("wordMinutes")}` },
    { value: 10, title: `10 ${t("wordMinutes")}` },
    { value: 15, title: `15 ${t("wordMinutes")}` },
    { value: 30, title: `30 ${t("wordMinutes")}` },
    { value: 45, title: `45 ${t("wordMinutes")}` },
    { value: 60, title: `1 ${t("wordHour")}` },
    { value: 120, title: `2 ${t("wordHours")}` },
    { value: 180, title: `3 ${t("wordHours")}` },
    { value: 240, title: `4 ${t("wordHours")}` },
    { value: 300, title: `5 ${t("wordHoursTwo")}` },
    { value: 360, title: `6 ${t("wordHoursTwo")}` },
    { value: 720, title: `12 ${t("wordHoursTwo")}` },
    { value: 1440, title: `1 ${t("wordDay")}` },
    { value: 2880, title: `2 ${t("wordDays")}` },
    { value: 4320, title: `3 ${t("wordDays")}` },
    { value: 5760, title: `4 ${t("wordDays")}` },
    { value: 7200, title: `5 ${t("wordDaysTwo")}` },
    { value: 8640, title: `6 ${t("wordDaysTwo")}` },
    { value: 10080, title: `7 ${t("wordDaysTwo")}` },
    { value: 14400, title: `10 ${t("wordDaysTwo")}` },
    { value: 17280, title: `12 ${t("wordDaysTwo")}` },
    { value: 20160, title: `14 ${t("wordDaysTwo")}` },
    { value: 25920, title: `18 ${t("wordDaysTwo")}` },
    { value: 30240, title: `21 ${t("wordDay")}` },
    { value: 34560, title: `24 ${t("wordDays")}` },
    { value: 40320, title: `28 ${t("wordDaysTwo")}` },
    { value: 43200, title: `30 ${t("wordDaysTwo")}` }
];

export default getBanTimeVariants;