import {useCallback, useState} from "react";

const ProfileActionsStates = () => {
  const [visibleProfileAction, setVisibleProfileAction] = useState(null);
  const [visibleTipWindow, setVisibleTipWindow] = useState(false);
  const [visibleUserInfoModal, setVisibleUserInfoModal] = useState(false);
  const [visibleModeratorsInfoModal, setVisibleModeratorsInfoModal] = useState(false);
  const [visibleBanListModal, setVisibleBanListModal] = useState(false);
  const [visibleUserBanListModal, setVisibleUserBanListModal] = useState(false);
  const [visibleUnbanUserModal, setVisibleUnbanUserModal] = useState(false);
  const [visibleBanUserModal, setVisibleBanUserModal] = useState(false);
  const [visibleConfirmBanModal, setVisibleConfirmBanModal] = useState(false);
  const [place, setPlace] = useState(null);
  const [nickname, setNickname] = useState(null);
  const [messageData, setMessageData] = useState(null);
  const [currentBanData, setCurrentBanData] = useState(null);

  const handleProfileActionsVisible = useCallback((key, place, sender) => {
    if (visibleProfileAction === key) {
      setVisibleProfileAction(null);
      setPlace(null);
      setNickname(null);
    } else {
      setVisibleProfileAction(key);
      setPlace(place);
      setMessageData(sender ?? null);
    }
  }, [visibleProfileAction]);

  return {
    visibleProfileAction,
    setVisibleProfileAction,
    place,
    setPlace,
    handleProfileActionsVisible,
    visibleTipWindow,
    setVisibleTipWindow,
    setNickname,
    nickname,
    visibleUserInfoModal,
    setVisibleUserInfoModal,
    messageData,
    setMessageData,
    visibleModeratorsInfoModal,
    setVisibleModeratorsInfoModal,
    setVisibleBanListModal,
    visibleBanListModal,
    setVisibleUnbanUserModal,
    visibleUnbanUserModal,
    visibleBanUserModal,
    setVisibleBanUserModal,
    visibleUserBanListModal,
    setVisibleUserBanListModal,
    currentBanData,
    setCurrentBanData,
    visibleConfirmBanModal,
    setVisibleConfirmBanModal
  }
}

export default ProfileActionsStates;